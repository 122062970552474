import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserListView.module.scss'
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {UserList} from "../../../../graphql/types";
import {Title} from "../../../shares/title/Title";
import {A} from "hookrouter";
import {BadgeUserType} from "../../../shares/badgeUserType/BadgeUserType";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Pager} from "../../../shares/pager/Pager";
import {BadgeIsActivate} from "../../../shares/badgeIsActivate/BadgeIsActivate";

const cx = classNamesBind.bind(styles);

interface Props {
    userList: UserList
}

export const UserListView = (props: Props) => {
    return (
        <ViewSelector
            mobile={<Mobile {...props}/>}
            web={<Web {...props}/>}
        />
    )
}

const Web = (props: Props) => {

    const userList = props.userList;

    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '10%'}}>등급</div>
                <div style={{width: '10%'}}>활성화</div>
                <div style={{width: '10%'}}>분류</div>
                <div style={{width: '20%'}}>유저</div>
                <div style={{width: '20%'}}>메모</div>
                <div style={{width: '10%'}}>회선정보</div>
                <div style={{width: '20%'}}>포인트</div>
            </div>

            <Fragment>
                {userList.user.map((value, index) => {
                    return (
                        <A href={`/user/view/${value.id}/${value.userType}`}
                           key={index}
                           style={{color: 'black'}}>
                            <div key={index} className={cx('table', 'hover')}>
                                <div style={{width: '10%'}}>
                                    <BadgeUserType type={value.userType}/>
                                </div>
                                <div style={{width: '10%'}}>
                                    <BadgeIsActivate isActivate={value.isActivate}/>
                                </div>
                                <div style={{width: '10%'}}>
                                    {value.categoryNm}
                                </div>
                                <div className='text-left' style={{width: '20%'}}>{value.email}</div>
                                <div className='text-left' style={{width: '20%'}}>{value.memo || '-'}</div>
                                <div className='text-left' style={{width: '10%'}}>
                                    {value.apiPermission.map((apiKey, idx) => (
                                        <div style={{padding: 0}} className='text-left' key={idx}>{apiKey.nm}({apiKey.smsFee} 원)</div>
                                    ))}
                                </div>
                                <div className='text-right'
                                     style={{width: '20%'}}>{Pipe.toSeparatorNumber(value.point)} P
                                </div>
                            </div>
                        </A>
                    )
                })}


            </Fragment>

        </>
    )
}

const Mobile = (props: Props) => {


    return (
        <>
            {props.userList.user.map((value, index) => (
                <A key={index} href={`/user/view/${value.id}/${value.userType}`} className={cx('cont-mb')}>
                    <div className={cx('email')}>
                        {value.email}
                    </div>
                    <div className={cx('grade')}><BadgeUserType type={value.userType}/></div>

                    {/* 활성화 */}
                    <div className={cx('label')}>활성화</div>
                    <div className={cx('label-value')}>
                        <BadgeIsActivate isActivate={value.isActivate}/>
                    </div>

                    {/* 잔여포인트 */}
                    <div className={cx('label')}>잔여 포인트</div>
                    <div className={cx('label-value')}>
                        {Pipe.toSeparatorNumber(value.point)} P
                    </div>

                    {/* 메모 */}
                    <div className={cx('label')}>메모</div>
                    <div className={cx('label-value')}>
                        {value.memo || '-'}
                    </div>

                    {/* 회선 */}
                    <div className={cx('label')}>회선</div>
                    <div className={cx('label-value')}>
                        {value.apiPermission.map((api, apiIdx) => (
                            <Fragment key={apiIdx}>
                                <div>{api.nm} : {api.smsFee} 원</div>
                            </Fragment>
                        ))}

                    </div>
                </A>
            ))}
        </>
    )
}


import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserViewAgent.module.scss'
import {UserViewGeneral} from "../general/UserViewGeneral";
import {BoxCont} from "../../../shares/boxCont/BoxCont";
import {Title} from "../../../shares/title/Title";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {User} from "../../../../graphql/types";
import moment from "moment";
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

export const UserViewAgent = (props: {
    id: number
}) => {
    const [getData, resData] = useLazyQuery<UserViewAgentResp, {
        userId: number
    }>(gqlData);

    const [data, setData] = useState<UserViewAgentResp | undefined>();

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    useEffect(() => {
        onInit();
    }, [props.id]);

    useEffect(() => {
        onInit();
    }, []);

    const onInit = () => {
        getData({variables: {userId: props.id}});
    }

    const onMoveReseller = () => {
        if (!data) {
            return;
        }

        navigate(`/user/view/${data.userParent.id}/Reseller`)
    }

    return (
        <UserViewGeneral
            id={props.id}
            onReloaded={onInit}>
            {data &&
            <div className='col-12' style={{marginBottom: 30}}>
                <BoxCont>
                    <Title>총판 정보</Title>
                    <div className={cx('table', 'pointer', 'hover')} onClick={onMoveReseller}>
                        <div style={{width: '15%'}}>이메일</div>
                        <div style={{width: '35%'}}>{data.userParent.email}</div>
                        <div style={{width: '15%'}}>가입일</div>
                        <div style={{width: '35%'}}>{moment(data.userParent.createdAt).format('YY.MM.DD hh:mm a')}</div>
                    </div>
                </BoxCont>
            </div>
            }
        </UserViewGeneral>
    )
}

interface UserViewAgentResp {
    userParent: User,
}


const gqlData = gql`
    query UserAgentReseller($userId: Int!) {
        userParent(userId: $userId) {
            id
            email
            contact
            createdAt
            apiPermission {
                hashedApiKey
                smsFee
                nm
            }
        }
    }
`

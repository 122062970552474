import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './CodeIssueResellerList.module.scss'
import {JoinCodeResellerList} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {BadgeIsActivate} from "../../../shares/badgeIsActivate/BadgeIsActivate";
import {Pager} from "../../../shares/pager/Pager";
import Decimal from "decimal.js";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);

export const CodeIssueResellerList = (props: {
    list: JoinCodeResellerList,
    onChangePage: OnChangeValue<number>,
    onDelete: OnChangeValue<string>,
}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(220);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);


    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx("table", "scrollTable")}>
                    <div className={cx("funcCell")}>삭제</div>
                    <div className={cx("codeCell")}>가입코드</div>
                    <div className={cx("recyleCell")}>재사용</div>
                    <div className={cx("vatCell")}>부가세</div>
                    <div className={cx("apiCell")}>API 정보</div>
                    <div className={cx("commissionCell")}>커미션 정보</div>
                    <div className={cx("issuerCell")}>발급자</div>
                </div>

                {props.list.list.map((value, index) => (
                    <div className={cx('table', 'hover', "scrollTable")} key={index}>
                        <div className={cx("funcCell")}>
                            <IconButton
                                color='primary'
                                size='small'
                                onClick={event => {
                                    props.onDelete(value.joinCode);
                                }}>
                                <Close/>
                            </IconButton>
                        </div>
                        <div className={cx("codeCell")}>{value.joinCode}</div>
                        <div className={cx("recyleCell")}>
                            <BadgeIsActivate
                                isActivate={value.reusable}/>
                        </div>
                        <div className={cx("vatCell")}>
                            {new Decimal(value.tax).mul(100).toNumber()} %
                        </div>
                        <div className={cx("apiCell", "textLeft")}>
                            {value.apiKey.map((apiKey, apiKeyIndex) => (
                                <span key={apiKeyIndex}>{apiKey.nm} : {apiKey.smsFee} 원<br/></span>
                            ))}
                        </div>
                        <div className={cx("commissionCell", "textLeft")}>
                            전송 : {value.commission.hasSendCommission ? '활성화' : '비활성화'}<br/>
                            충전 : {new Decimal(value.commission.rechargeCommissionRate).times(100).toNumber()} %
                        </div>
                        <div className={cx("issuerCell")}>{value.adminEmail}</div>
                    </div>
                ))}
            </ScrollArea>

            <Pager
                page={props.list.page}
                size={props.list.size}
                total={props.list.total}
                onChange={props.onChangePage}/>
        </>
    )
}

import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './CodeIssueTreeList.module.scss'
import {JoinCodeTreeList} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {Decimal} from "decimal.js";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import ScrollArea from "react-scrollbar";
import {Pager} from "../../../shares/pager/Pager";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);

interface Props {
    list: JoinCodeTreeList,
    onChangePage: OnChangeValue<number>,
    onDelete: OnChangeValue<string>,
}

export const CodeIssueTreeList = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(220);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "deleteCell")}>삭제</div>
                    <div className={cx('header', "codeCell")}>가입코드</div>
                    <div className={cx('header', "taxCell")}>부가세</div>
                    <div className={cx('header', "commissionCell")}>충전커미션</div>
                    <div className={cx('header', "apiCell")}>API 정보</div>
                    <div className={cx('header', "issuerCell")}>발급자</div>
                </div>

                {props.list.total === 0 && <BizNoDataTable/>}

                {props.list.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("deleteCell")}>
                            <IconButton
                                color='primary'
                                size='small'
                                onClick={() => props.onDelete(value.code)}>
                                <Close/>
                            </IconButton>
                        </div>
                        <div className={cx("codeCell")}>
                            {value.code}
                        </div>
                        <div className={cx("taxCell")}>
                            {new Decimal(value.tax).mul(100).toNumber()} %
                        </div>
                        <div className={cx("commissionCell")}>
                            {new Decimal(value.rechargePermissionRate).mul(100).toNumber()} %
                        </div>
                        <div className={cx("apiCell", "textLeft")}>
                            {value.apiKeyList.map((apiKey, idx) => (
                                <div key={idx}>{apiKey.nm} : {apiKey.smsFee} 원</div>
                            ))}
                        </div>
                        <div className={cx("issuerCell")}>
                            {value.adminEmail}
                        </div>
                    </div>
                ))}

            </ScrollArea>

            <Pager
                page={props.list.page}
                size={props.list.size}
                total={props.list.total}
                onChange={props.onChangePage}/>
        </>
    )
}
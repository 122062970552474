import React, {useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {Pipe} from "../../../utils/pipe/pipe";
import moment from "moment";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {Title} from "../../shares/title/Title";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {UsageReportList} from "../share/usageReportList/UsageReportList";
import {BizMonthPicker} from "../../shares/bizMonthPicker/BizMonthPicker";
import {ReportMonthlyCommissionReceipt} from "./commissionReceipt/ReportMonthlyCommissionReceipt";
import {ReportPerSmsFeeList} from "../share/reportPerSmsFeeList/ReportPerSmsFeeList";

export const ReportMonthly = (props: {
    timeMonth: number,
}) => {
    const {data, refetch} = useQuery<Query, any>(gqlData, {
        variables: {
            month: moment(Pipe.cvtTimeMonthToDate(props.timeMonth)).toDate(),
            ...Pipe.toThisMonthTimeDay(Pipe.cvtTimeMonthToDate(props.timeMonth))
        }
    });

    const [month, setMonth] = useState(Pipe.cvtTimeMonthToDate(props.timeMonth));

    const handler = {
        onChangeMonth: (date: Date) => {
            const adjustDate = moment(date).hours(9).toDate();
            setMonth(adjustDate);
            refetch({
                month: adjustDate,
                ...Pipe.toThisMonthTimeDay(adjustDate)
            }).catch(ApolloCatch({}));
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%', paddingTop: 5}}>
                            <Title>월간 정산</Title>
                        </div>
                        <div className='text-right' style={{width: '50%'}}>
                            <BizMonthPicker date={month} onChange={handler.onChangeMonth}/>
                        </div>
                    </div>
                    <UsageReportList list={data.monthlyUserUsageReport}/>
                </BizCol12>

                <ReportPerSmsFeeList data={data.reportPerSmsFee}/>

                <BizCol12>
                    <Title>지급완료 커미션 목록</Title>
                    <ReportMonthlyCommissionReceipt
                        query={data}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}


const gqlData = gql`
    query DATA ($month: Time!, $stDate: Int!, $edDate: Int!) {
        monthlyUserUsageReport(month: $month) {
            userId
            userEmail
            point
            chargeAmount
            chargePoint
            sentSms
            taxAmount
            profit
            userType
        }
        companyBalances(month: $month) {
            withdraw {
                id
                isDeletable
                type
                memo
                amount
                createdAt
            }
        }
        reportPerSmsFee(stDate: $stDate, edDate: $edDate) {
            smsFee
            sentSms
        }
    }
`;

import React, {ReactNode, Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './PhotoBackground.module.scss'
import {Transition} from "react-spring/renderprops-universal";
import {useImage} from "react-image";

const cx = classNamesBind.bind(styles);

export const PhotoBackground = (props: {
    children: ReactNode
}) => {

    const {src} = useImage({
        srcList: "https://picsum.photos/1600/1200"
    });

    return (
        <Fragment>
            <Transition
                items={true}
                from={{
                    imgOpacity: 0,
                    filterOpacity: 0,
                    backOpacity: 1,
                }}
                enter={{
                    imgOpacity: 1,
                    filterOpacity: 0.5,
                    backOpacity: 1,
                }}
                leave={{
                    imgOpacity: 0,
                    filterOpacity: 0,
                    backOpacity: 0,
                }}>
                {state => state && (params =>
                        <div className={cx('cont-background')}>
                            {/*<div className={cx('back-black')}*/}
                            {/*     style={{opacity: params.backOpacity}}/>*/}
                            <img className={cx('')}
                                 style={{opacity: params.imgOpacity}}
                                 src={src} alt={'background'}/>
                            <div className={cx('filter')}
                                 style={{opacity: params.filterOpacity}}/>
                        </div>
                )}
            </Transition>
            {props.children}
        </Fragment>

    )
}

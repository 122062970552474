import React, {ReactNode} from 'react';
import {useSelector} from "react-redux";
import {LayoutState, ViewMode} from "../../../redux/Layout";

export const ViewSelector = (props: {
    web: ReactNode,
    mobile: ReactNode
}) => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    return (
        <>
            {layout.viewMode === ViewMode.Web && props.web}
            {layout.viewMode === ViewMode.Mobile && props.mobile}
        </>
    )
}

import React, {Fragment} from "react";
import {BalanceReceiptType} from "../../../graphql/types";

export const BadgeBalanceReceiptType = (props: {
    type: BalanceReceiptType
}) => {
    return (
        <Fragment>
            {props.type === BalanceReceiptType.Admin && <span className='badge badge-success'>관리자</span>}
            {props.type === BalanceReceiptType.Deposit && <span className='badge badge-warning'>입금확인</span>}
            {props.type === BalanceReceiptType.Msg && <span className='badge badge-secondary'>전송</span>}
            {props.type === BalanceReceiptType.SendBulk && <span className='badge badge-secondary'>대량전송</span>}
            {props.type === BalanceReceiptType.SendTemplate && <span className='badge badge-secondary'>탬플릿전송</span>}
            {props.type === BalanceReceiptType.SharePoint && <span className='badge badge-warning'>포인트공유</span>}
            {props.type === BalanceReceiptType.Init && <span className='badge badge-dark'>초기화</span>}
            {props.type === BalanceReceiptType.Commission && <span className='badge badge-warning'>커미션</span>}
        </Fragment>
    )
}

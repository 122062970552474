import React from "react";
import classNamesBind from "classnames/bind";
import styles from './UserViewTree.module.scss'
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {UserViewGeneral} from "../general/UserViewGeneral";
import gql from "graphql-tag";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationUpdateUserCommissionPermissionArgs,
    MutationWithdrawAllRechargeCommissionArgs, MutationWithdrawRechargeCommissionArgs,
    Query
} from "../../../../graphql/types";
import {Title} from "../../../shares/title/Title";
import {UserAgentList} from "../share/userAgentList/UserAgentList";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {UserTotalCommission} from "../share/userTotalCommission/UserTotalCommission";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {UserRechargeCommission} from "../share/userRechargeComission/UserRechargeCommission";
import {UserRechargeCommissionList as UserRechargeCommissionListView} from "../share/userRechargeCommissionList/UserRechargeCommissionList";

const cx = classNamesBind.bind(styles);

interface Props {
    id: number
}

export const UserViewTree = (props: Props) => {
    const {data, refetch} = useQuery<Query, {
        resellerId: number,
        agentPage: number,
        rechargePage: number,
    }>(gqlData, {
        variables: {
            resellerId: props.id,
            agentPage: 0,
            rechargePage: 0,
        }
    });

    const [mutUpdateUserCommissionPermission] =
        useMutation<Mutation, MutationUpdateUserCommissionPermissionArgs>(gqlUpdateUserCommissionPermission);

    const [mutWithdrawAllRechargeCommission] =
        useMutation<Mutation, MutationWithdrawAllRechargeCommissionArgs>(gqlWithdrawAllRechargeCommission);

    const [mutWithdrawRechargeCommission] =
        useMutation<Mutation, MutationWithdrawRechargeCommissionArgs>(gqlWithdrawRechargeCommission);


    const handler = {
        onInit: () => {

        },
        onChangeAgentPage: (p: number) => {

        },
        onUpdateUserCommissionPermission: (args: MutationUpdateUserCommissionPermissionArgs) => {
            SwalUtil.ok({
                msg: '추천회원은 변경 불가능 입니다.',
                icon: 'error'
            });
        },
        onChangePageRecharge: (p: number) => {

        },
        onWithdrawAllRechargeCommission: (args: MutationWithdrawAllRechargeCommissionArgs) => {
            mutWithdrawAllRechargeCommission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
        onWithdrawRechargeCommission: (args: MutationWithdrawRechargeCommissionArgs) => {
            mutWithdrawRechargeCommission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
    }

    return (
        <>
            {data &&
            <UserViewGeneral
                id={props.id}
                onReloaded={handler.onInit}>
                <BizCol12>
                    <Title>대리점 정보</Title>
                    <UserAgentList
                        query={data}
                        onChangeAgentPage={handler.onChangeAgentPage}/>
                </BizCol12>

                <BizCol12>
                    <UserTotalCommission
                        userId={props.id}
                        userCommission={data.userCommission}
                        {...handler}/>
                </BizCol12>

                <BizCol12>
                    <Title>충전 커미션 지급</Title>
                    <UserRechargeCommission
                        userId={props.id}
                        query={data}
                        {...handler}/>
                </BizCol12>
                <BizCol12>
                    <Title>충전 커미션 목록</Title>
                    <UserRechargeCommissionListView
                        query={data}
                        onChangeRechargeCommissionPage={handler.onChangePageRecharge}/>
                </BizCol12>
            </UserViewGeneral>
            }

        </>

    )
}


const gqlData = gql`
    query DATA ($resellerId: Int!, $agentPage: Int!, $rechargePage: Int!) {
        userChildren(userId: $resellerId, page: $agentPage, size: 10) {
            page
            size
            total
            user {
                id
                email
                point
                tax
                userType
                createdAt
                apiPermission {
                    hashedApiKey
                    nm
                    smsFee
                }
            }
        }
        userCommission(id: $resellerId) {
            commission
            paid
            notPaid
            rechargeCommission
            hasSendCommission
            createdAt
        }
        userRechargeCommissionList(id: $resellerId, page: $rechargePage, size: 10) {
            page
            size
            total
            list {
                id
                childrenEmail
                rate
                commission
                isWithdrawal
                withdrawalAt
                chargePoint
                chargeAmount
            }
        }
        userRechargeCommission(userId: $resellerId) {
            id
            childrenEmail
            rate
            commission
            isWithdrawal
            withdrawalAt
            chargeAmount
            chargePoint
        }
    }

`;


const gqlUpdateUserCommissionPermission = gql`
    mutation updateUserCommissionPermission($input: UpdateUserCommissionPermission!) {
        updateUserCommissionPermission(input: $input)
    }
`;

const gqlWithdrawAllSendCommission = gql`
    mutation WithdrawalAllSendCommission($id: Int!, $otp: String!) {
        withdrawAllSendCommission(id: $id, otp: $otp)
    }
`;

const gqlWithdrawAllRechargeCommission = gql`
    mutation WithdrawAllRechargeCommission($id: Int!, $otp: String!) {
        withdrawAllRechargeCommission(id: $id, otp: $otp)
    }
`;


const gqlWithdrawSendCommission = gql`
    mutation WithdrawSendCommission ($userId: Int!, $commissionIdList: [Int!]!, $otp: String!) {
        withdrawSendCommission(userId: $userId, commissionIdList: $commissionIdList, otp: $otp)
    }
`;


const gqlWithdrawRechargeCommission = gql`
    mutation WithdrawRechargeCommission($userId: Int!, $commissionIdList: [Int!]!, $otp: String!) {
        withdrawRechargeCommission(userId: $userId, commissionIdList: $commissionIdList, otp: $otp)
    }
`;

import React, {Fragment} from "react";
import {SendCommissionSettleRequestStatus} from "../../../graphql/types";

export const BadgeSendCommissionSettleRequestStatus = (props: {
    status: SendCommissionSettleRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === SendCommissionSettleRequestStatus.Requested &&
            <span className='badge badge-secondary'>요청대기</span>}

            {props.status === SendCommissionSettleRequestStatus.Cancel &&
            <span className='badge badge-secondary'>유저취소</span>}

            {props.status === SendCommissionSettleRequestStatus.Deny &&
            <span className='badge badge-dark'>반려</span>}

            {props.status === SendCommissionSettleRequestStatus.Allow &&
            <span className='badge badge-success'>정산완료</span>}
        </Fragment>
    )
}

import React, {Fragment, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizColorPicker.module.scss'
import {OnChangeValue} from "../../Interfaces";
import {SwatchesPicker, TwitterPicker} from "react-color";
import {Popover} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const BizColorPicker = (props: {
    value: string,
    onChange: OnChangeValue<string>
}) => {
    const [modal, setModal] = useState(false);
    const [anchor, setAnchor] = useState<Element | null>();
    return (
        <Fragment>
            <div className='form-control text-center'
                 style={{background: props.value}}
                 onClick={event => {
                     setModal(!modal);
                     setAnchor(event.currentTarget);
                 }}/>

            <Popover
                anchorEl={anchor}
                open={modal}>
                <SwatchesPicker onChange={(color, event) => {
                    setModal(false);
                    props.onChange(color.hex);
                }}/>
            </Popover>

        </Fragment>
    )
}


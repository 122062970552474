import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserApiWithReseller.module.scss'
import {HashedApiKey, UpdateUserApiPermission, UpdateUserApiPermissionData, User} from "../../../../../graphql/types";
import {Title} from "../../../../shares/title/Title";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";

const cx = classNamesBind.bind(styles);

export const UserApiWithReseller = (props: {
    user: User,
    apiKeyList: HashedApiKey[],
    onUpdateUserApiPermission: (input: UpdateUserApiPermission) => void,
}) => {
    const [input, setInput] = useState<InputData>([]);

    useEffect(() => {
        const nextInput: InputData = props.user.apiPermission.map(value => {
            const clientApiPermission = props.apiKeyList.find(client => {
                return client.hashedApiKey === value.hashedApiKey;
            })

            if (clientApiPermission) {
                return {
                    hashedApiKey: clientApiPermission.hashedApiKey,
                    nm: clientApiPermission.nm,
                    smsFee: clientApiPermission.smsFee,
                    userNm: value.nm,
                    userSmsFee: value.smsFee
                }
            } else {
                return {
                    hashedApiKey: value.hashedApiKey,
                    nm: value.nm,
                    smsFee: value.smsFee,
                    userNm: value.nm,
                    userSmsFee: value.smsFee
                }
            }
        })

        setInput(nextInput);
    }, [props.user]);

    const handler = {
        onClickSave: () => {
            const args: UpdateUserApiPermission = {
                userId: props.user.id,
                data: input.map(value => {
                    return {
                        hashedApiKey: value.hashedApiKey,
                        nm: value.userNm,
                        smsFee: value.userSmsFee
                    }
                })
            }
            props.onUpdateUserApiPermission(args);
        }
    }

    return (
        <Fragment>
            <Title>등록 회선</Title>
            <div className='def-text' style={{marginBottom: 10}}>* 총판, 대리점 회원은 회선추가와 삭제가 불가능합니다.</div>
            <div className={cx('table')}>
                <div style={{width: '25%'}}>회선이름</div>
                <div style={{width: '25%'}}>회선가격</div>
                <div style={{width: '25%'}}>이름</div>
                <div style={{width: '25%'}}>건당가격</div>
            </div>


            {input.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div style={{width: '25%'}}>{value.nm}</div>
                    <div style={{width: '25%'}}>{value.smsFee} 원</div>
                    <div style={{width: '25%'}}>
                        <input
                            className='form-control form-control-sm text-center'
                            value={value.userNm}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(input);
                                copy[index].userNm = ev.target.value;
                                setInput(copy);
                            }}/>
                    </div>
                    <div style={{width: '25%'}}>
                        <NumberFormat
                            className='form-control form-control-sm text-right'
                            allowNegative={false}
                            decimalScale={1}
                            suffix={' 원'}
                            value={value.userSmsFee}
                            onValueChange={values => {
                                const copy = CopyUtil.copyAll(input);
                                copy[index].userSmsFee = values.floatValue || 0;
                                setInput(copy);
                            }}/>
                    </div>
                </div>
            ))}

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onClickSave}>
                        적용
                    </button>
                </div>
            </div>

        </Fragment>
    )
}


type InputData = (UpdateUserApiPermissionData & { userNm: string, userSmsFee: number })[]

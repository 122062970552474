import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BoardList.module.scss'
import {Board, BoardList as List, BoardType} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {Title} from "../../shares/title/Title";
import {BadgeIsActivate} from "../../shares/badgeIsActivate/BadgeIsActivate";
import moment from "moment";
import {navigate} from "hookrouter";
import {IconButton} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import {BoardView} from "../share/view/BoardView";
import {Pager} from "../../shares/pager/Pager";
import {Validator} from "../../../utils/validator/Validator";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const BoardList = (props: {
    boardType: BoardType,
    page?: number,
}) => {
    const [getData, resData] = useLazyQuery<{
        boardList: List
    }, {
        page: number,
        size: number,
        boardType: BoardType
    }>(gqlData);
    const [mutDeleteBoard] = useMutation<{
        deleteBoard: boolean
    }, {
        id: number
    }>(gqlDeleteBoard);
    const [data, setData] = useState<List | undefined>();
    const [board, setBoard] = useState<Board | undefined>();
    const [view, setView] = useState(false);

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight("auto");
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data.boardList);
        }
    }, [resData.data]);

    useEffect(() => {
        getData({
            variables: {
                page: Validator.isNumber(props.page),
                size: 20,
                boardType: props.boardType
            }
        });
    }, [props.boardType, props.page]);

    const onClickCreate = () => {
        navigate(`/board/create/${props.boardType}`);
    }

    const onChangePage = (page: number) => {
        navigate(`/board/list/${props.boardType}/${page}`);
    }

    const onUpdateBoard = (id: number) => {
        navigate(`/board/update/${props.boardType}/${id}`);
    }

    const onDeleteBoard = (id: number) => {
        SwalUtil.yn({
            msg: '삭제 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutDeleteBoard({variables: {id}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '삭제 되었습니다.',
                            icon: 'success'
                        });
                        if (data) {
                            onChangePage(data.page);
                        } else {
                            onChangePage(0);
                        }
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        })

    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <ModalWeb
                    title={'미리보기'}
                    isShow={view}
                    onCancel={() => {
                        setView(false);
                    }}
                    onOk={() => {
                        setView(false);
                    }}>
                    {board && <BoardView board={board}/>}
                </ModalWeb>

                <div className='col-12'>
                    <BoxCont>
                        <Title>{props.boardType}</Title>
                        <ScrollArea
                            style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                            contentStyle={{width: scrollAreaContent}}
                            smoothScrolling={true}
                        >
                            <div className={cx('table')}>
                                <div className={cx("header", "numCell")}>번호</div>
                                <div className={cx("header", "statusCell")}>상태</div>
                                <div className={cx("header", "subjectCell")}>제목</div>
                                <div className={cx("header", "writerCell")}>관리자</div>
                                <div className={cx("header", "dateCell")}>게시일</div>
                                <div className={cx("header", "modifyCell")}>수정</div>
                                <div className={cx("header", "deleteCell")}>삭제</div>
                            </div>

                            {data.list.length === 0 &&
                            <div className={cx('table')}>
                                <div style={{width: '100%'}}>게시물이 없습니다.</div>
                            </div>
                            }

                            {data.list.map((value, index) => {
                                return (
                                    <div key={index} className={cx('table', 'hover')}>
                                        <div className={cx("numCell")}>{value.id}</div>
                                        <div className={cx("statusCell")}>
                                            <BadgeIsActivate isActivate={value.isActivate}/>
                                        </div>
                                        <div className={cx("subjectCell")} onClick={event => {
                                            setBoard(value);
                                            setView(true);
                                        }}>
                                            {value.title}
                                        </div>
                                        <div className={cx("writerCell")}>{value.adminEmail}</div>
                                        <div
                                            className={cx("dateCell")}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                                        <div className={cx("modifyCell")}>
                                            <IconButton
                                                size={'small'}
                                                onClick={event => {
                                                    onUpdateBoard(value.id);
                                                }}>
                                                <Edit/>
                                            </IconButton>
                                        </div>
                                        <div className={cx("deleteCell")}>
                                            <IconButton
                                                size={'small'}
                                                color='secondary'
                                                onClick={event => {
                                                    onDeleteBoard(value.id);
                                                }}>
                                                <Delete/>
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            })}
                        </ScrollArea>

                        <div className={cx('table')}>
                            <div className='text-right' style={{width: '100%'}}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onClickCreate}>추가
                                </button>
                            </div>
                        </div>

                        <Pager
                            page={data.page}
                            size={data.size}
                            total={data.total}
                            onChange={onChangePage}/>

                    </BoxCont>
                </div>

            </FadeInContainer>
            }
        </>
    )
}


const gqlData = gql`
    query Board($page: Int!, $size: Int!, $boardType: BoardType!) {
        boardList(page:$page, size: $size, boardType: $boardType) {
            page
            size
            total
            list {
                id
                title
                content
                isActivate
                adminEmail
                bgColor
                createdAt
            }
        }
    }
`;

const gqlDeleteBoard = gql`
    mutation DeleteBoard($id: Int!) {
        deleteBoard(id: $id)
    }
`;

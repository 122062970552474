import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./PointCommissionRechargeList.module.scss";
import {
    MutationAllowRechargeCommissionSettleRequestArgs, MutationDenyRechargeCommissionSettleRequestArgs,
    RechargeCommissionSettleRequest, SettleCommissionType
} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {BadgeSettleCommissionType} from "../../../shares/badgeSettleCommissionType/BadgeSettleCommissionType";
import {Pipe} from "../../../../utils/pipe/pipe";
import {IconButton} from "@material-ui/core";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {Check, Close, OpenInNew} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

interface Props {
    rechargeCommissionSettleRequest: RechargeCommissionSettleRequest[],
    allowRecharge: OnChangeValue<MutationAllowRechargeCommissionSettleRequestArgs>,
    denyRecharge: OnChangeValue<MutationDenyRechargeCommissionSettleRequestArgs>,
}

export const PointCommissionRechargeList = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    const handler = {
        onShowBankNumber: (numb: string) => {
            SwalUtil.ok({
                msg: numb,
                icon: 'success'
            })
        }
    }

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "userCell")}>유저정보</div>
                    <div className={cx("header", "badgeCell")}>정산 종류</div>
                    <div className={cx("header", "valueCell")}>커미션 건수</div>
                    <div className={cx("header", "pointCell")}>커미션 포인트</div>
                    <div className={cx("header", "paymentCell")}>결제</div>
                </div>

                {props.rechargeCommissionSettleRequest.length === 0 && <BizNoDataTable/>}

                {props.rechargeCommissionSettleRequest.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("userCell")}>{value.userEmail}</div>
                        <div className={cx("badgeCell")}>
                            <BadgeSettleCommissionType
                                status={value.commissionType}/>
                            {value.commissionType === SettleCommissionType.Withdraw &&
                            <IconButton size='small' onClick={() => handler.onShowBankNumber(value.bankNumber)}>
                                <OpenInNew/>
                            </IconButton>}
                        </div>
                        <div className={cx("valueCell", "textRight")}>{Pipe.toSeparatorNumber(value.commissionCount)} 건
                        </div>
                        <div className={cx("pointCell", "textRight")}>{Pipe.toSeparatorNumber(value.commissionTotal)} P
                        </div>
                        <div className={cx("paymentCell")}>
                            <IconButton
                                color='primary'
                                onClick={() => SwalUtil.otp(otp => {
                                    props.allowRecharge({rechargeCommissionSettleRequestId: value.id, otp});
                                })}>
                                <Check/>
                            </IconButton>

                            <IconButton
                                color='secondary'
                                onClick={() => props.denyRecharge({rechargeCommissionSettleRequestId: value.id})}>
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                ))}
            </ScrollArea>
        </>
    )
};
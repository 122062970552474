import React, {Fragment} from "react";
import styles from './PointRequestSearchBar.module.scss'
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";


export const PointRequestSearchBar = (props: {
    value: string,
    onChangeValue: OnChangeValue<string>,
    onSearch: OnVoidFunction,
    onClear: OnVoidFunction
}) => {
    return (
        <Fragment>
            <form
                className={styles.contSearchBar}
                onSubmit={ev => {
                    ev.preventDefault();
                    props.onSearch();
                }}>
                <div className={styles.inputEmail}>
                    <input
                        placeholder='유저 이메일 입력'
                        value={props.value}
                        onChange={ev => props.onChangeValue(ev.target.value)}
                        className='form-control form-control-sm'/>
                </div>

                <div className={styles.btn}>
                    <button
                        type='button'
                        className='btn btn-block btn-sm btn-outline-success'
                        onClick={props.onClear}>초기화</button>
                </div>

                <div className={styles.btn}>
                    <button
                        className='btn btn-block btn-sm btn-success'
                        onClick={props.onSearch}>검색</button>
                </div>
            </form>
        </Fragment>
    )
}

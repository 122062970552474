import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ReportPerSmsFeeList.module.scss'
import {ReportPerSmsFee} from "../../../../graphql/types";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {Title} from "../../../shares/title/Title";
import {Pipe} from "../../../../utils/pipe/pipe";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";

const cx = classNamesBind.bind(styles);

export const ReportPerSmsFeeList = (props: {
    data: ReportPerSmsFee[],
}) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let nextTotal = 0;

        for (let elem of props.data) {
            nextTotal += elem.sentSms;
        }

        setTotal(nextTotal);
    }, [props.data]);

    return (
        <Fragment>
            <BizCol12>
                <Title>건당 가격</Title>
                <div className={cx('table', 'table-report')}>
                    <div className={cx('header', 'col-sms-fee')}>건당 가격</div>
                    <div className={cx('header', 'col-sent-sms')}>SMS 갯수</div>
                </div>

                {props.data.length === 0 &&
                <BizNoDataTable/>
                }

                {props.data.map((value, index) => (
                    <div key={index} className={cx('table', 'hover', 'table-report')}>
                        <div className={cx('col', 'col-sms-fee')}>{value.smsFee} P</div>
                        <div
                            className={cx('col', 'col-sent-sms', 'text-right')}>{Pipe.toSeparatorNumber(value.sentSms)} 건
                        </div>
                    </div>
                ))}

                <div className={cx('table', 'table-report')}>
                    <div className={cx('col', 'col-sms-fee')}>계</div>
                    <div
                        className={cx('col', 'col-sent-sms', 'text-right')}>{Pipe.toSeparatorNumber(total)} 건
                    </div>
                </div>
            </BizCol12>

        </Fragment>
    )
}

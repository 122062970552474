import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './PointRequestDepositList.module.scss'
import {DepositRechargeList} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import moment from "moment";
import NumberFormat from "react-number-format";
import {Pager} from "../../../shares/pager/Pager";
import {BadgeDepositRechargeStatus} from "../../../shares/badgeDepositRechargeStatus/BadgeDepositRechargeStatus";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);

export const PointRequestDepositList = (props: {
    value: DepositRechargeList,
    onChangePage: OnChangeValue<number>,

}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx("table", "scrollTable")}>
                    <div className={cx("header", "dateCell")}>요청일</div>
                    <div className={cx("header", "statusCell")}>상태</div>
                    <div className={cx("header", "nameCell")}>입금자명</div>
                    <div className={cx("header", "userCell")}>유저</div>
                    <div className={cx("header", "valueCell")}>입금액</div>
                    <div className={cx("header", "funcCell")}>확인전송</div>
                </div>

                {props.value.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{flexGrow: 1}}>검색 결과가 없습니다.</div>
                </div>
                }


                {props.value.list.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover')}>
                            <div className={cx("dateCell")}>{moment(value.createdAt).format('YY.MM.DD - hh:mm a')}</div>
                            <div className={cx("statusCell")}>
                                <BadgeDepositRechargeStatus
                                    value={value.status}/>
                            </div>
                            <div className={cx("nameCell")}>{value.nm}</div>
                            <div className={cx("userCell")}>{value.email}</div>
                            <div className={cx("valueCell")}>
                                <div className={cx('box-deposit', 'text-right')}>
                                    <div className={cx('amount')}>
                                        <NumberFormat
                                            value={value.amount}
                                            displayType='text'
                                            suffix={' 원'}
                                            thousandSeparator/>
                                    </div>
                                    <div className={cx('info')}>
                                        <NumberFormat
                                            value={value.point}
                                            prefix={'포인트 : '}
                                            suffix={' P'}
                                            displayType='text'
                                            thousandSeparator/>
                                    </div>
                                </div>
                            </div>
                            <div className={cx("funcCell")}>
                                {value.tel}
                            </div>
                        </div>
                    )
                })}

            </ScrollArea>

            <Pager
                page={props.value.page}
                size={props.value.size}
                total={props.value.total}
                onChange={props.onChangePage}/>
        </>
    )
}

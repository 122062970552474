import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BoardEditor.module.scss'
import {BoardType} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {useQuill} from 'react-quilljs';
import 'react-quill/dist/quill.snow.css';
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {BizErrors} from "../../../../graphql/errors";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizColorPicker} from "../../../shares/bizColorPicker/BizColorPicker";

const cx = classNamesBind.bind(styles);

export const BoardEditor = (props: {
    value: BoardEditorData,
    onChange: OnChangeValue<BoardEditorData>
}) => {
    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: {
                container: [
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'align': []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    ["image"],
                    [{ 'color': [] }, { 'background': [] }],
                ]
            },

        }
    });
    const [contents, setContents] = useState(props.value.contents);

    useEffect(() => {
        if (quill) {
            quill.getModule('toolbar').addHandler('image', onSelectImage);
            quill.on('text-change', () => {
                if (quill) {
                    setContents(quill.root.innerHTML);
                }
            })

            if (props.value) {
                quill.clipboard.dangerouslyPasteHTML(props.value.contents);
            }
        }
    }, [quill]);

    useEffect(() => {
        if (quillRef) {
            window.scrollTo(0, 0);
        }
    }, [quillRef]);

    useEffect(() => {
        const copy = Object.assign({}, props.value);
        copy.contents = contents;
        props.onChange(copy);
    }, [contents]);


    const onSelectImage = () => {
        DataUtil.uploadBoardFile()
            .then(res => {
                for (let item of res) {
                    if (quill) {
                        const img = document.createElement('img');
                        img.setAttribute('style', 'width:100%');
                        img.setAttribute('src', item.src);
                        quill.root.appendChild(img);
                    }
                }

            })
            .catch(ApolloCatch({
                [BizErrors.default]: () => {
                    SwalUtil.ok({
                        msg: '업로드 실패',
                        icon: "error"
                    })
                },
                [BizErrors.overMaxSize]: () => {
                    SwalUtil.ok({
                        msg: '용량 초과 (5mb)',
                        icon: "error"
                    })
                }
            }));
    }

    return (
        <Fragment>
            <div>
                <div className={cx('table')}>
                    <div style={{width: 150}}>제목</div>
                    <div style={{width: 'calc(100% - 150px)'}}>
                        <input
                            className='form-control'
                            value={props.value.title}
                            onChange={event => {
                                const copy = Object.assign({}, props.value);
                                copy.title = event.target.value;
                                props.onChange(copy);
                            }}/>
                    </div>
                </div>
                {/* <div className={cx('table')}>
                    <div style={{width: 150}}>게시판</div>
                    <div style={{width: 'calc(100% - 150px)'}}>
                        <select
                            className='form-control'
                            value={props.value.boardType}
                            onChange={event => {
                                const copy = Object.assign({}, props.value);
                                copy.boardType = Pipe.toBoardType(event.target.value);
                                props.onChange(copy);
                            }}>
                            <option value={BoardType.Notice}>공지사항</option>
                            <option value={BoardType.Popup}>팝업</option>
                        </select>
                    </div>
                </div>*/}
                {props.value.boardType === BoardType.Popup &&
                <Fragment>
                    <div className={cx('table', 'border-bottom', 'margin-bottom')}>
                        <div style={{width: 150}}>배경색상</div>
                        <div style={{width: 'calc(100% - 300px)'}}>
                            <input
                                className='form-control'
                                value={props.value.bgColor}
                                onChange={event => {
                                    const copy = Object.assign({}, props.value);
                                    copy.bgColor = event.target.value;
                                    props.onChange(copy);
                                }}/>
                        </div>
                        <div style={{width: 150}}>
                            <BizColorPicker value={props.value.bgColor} onChange={value => {
                                const copy = Object.assign({}, props.value);
                                copy.bgColor = value;
                                props.onChange(copy);
                            }}/>
                        </div>
                    </div>
                </Fragment>
                }

                <div className={cx('table')} style={{height: 20}}/>
                <div ref={quillRef} style={{height: 800, marginBottom: 50, background: props.value.bgColor}}/>

            </div>
        </Fragment>
    )
}

interface BoardEditorData {
    title: string;
    boardType: BoardType;
    contents: string;
    bgColor: string;
}

import React, {Fragment} from "react";
import {AlertType} from "../../../graphql/types";

export const BadgeAlertType = (props: {
    alertType: AlertType
}) => {
    return (
        <Fragment>
            {props.alertType === AlertType.Success && <span className='badge badge-success'>녹색</span>}
            {props.alertType === AlertType.Warning && <span className='badge badge-warning'>노랑색</span>}
            {props.alertType === AlertType.Error && <span className='badge badge-danger'>빨강색</span>}
            {props.alertType === AlertType.Info && <span className='badge badge-info'>파랑색</span>}

        </Fragment>
    )
}

import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserMsgHistory.module.scss'
import {Title} from "../../../../shares/title/Title";
import {QueryUserTelBookArgs, UserMsgHistoryList} from "../../../../../graphql/types";
import moment from "moment";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Pager} from "../../../../shares/pager/Pager";
import {OnChangeValue} from "../../../../Interfaces";
import {IconButton} from "@material-ui/core";
import {CloudDownload} from "@material-ui/icons";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

interface Props {
    userMsgHistoryList: UserMsgHistoryList;
    onChangePage: OnChangeValue<number>;
    onDownloadUserTelBook: OnChangeValue<QueryUserTelBookArgs>,
    onClickMsgReceipt: OnChangeValue<string>
}

export const UserMsgHistory = (props: Props) => {
    return (
        <Fragment>
            <Title>전송기록</Title>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>

            <Pager
                page={props.userMsgHistoryList.page}
                size={props.userMsgHistoryList.size}
                total={props.userMsgHistoryList.total}
                onChange={props.onChangePage}/>

        </Fragment>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            {props.userMsgHistoryList.list.map((value, index) => (
                <div key={index} className={cx('cont-mb')}>
                    <div className={cx('date')}>{moment(value.createdAt).format('YY.MM.DD - hh:mm a')}</div>
                    <div className={cx('send-total')}>{Pipe.toSeparatorNumber(value.sendTotal)} 개</div>
                    <div className={cx('changed-point')}>{Pipe.toSeparatorNumber(value.changedPoint)} P</div>
                    <div style={{whiteSpace: 'normal'}} className={cx('content')} onClick={() => {
                        props.onClickMsgReceipt(value.requestId);
                    }}>{value.contents}</div>
                </div>
            ))}
        </>
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>번호</div>
                <div style={{width: 200}}>날짜</div>
                <div style={{width: 150}}>사용포인트</div>
                <div style={{width: 100}}>전송건수</div>
                <div style={{width: 'calc(100% - 600px)'}}>내용</div>
                <div style={{width: 50}}/>
            </div>

            {props.userMsgHistoryList.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div style={{width: 100}}>{value.id}</div>
                        <div style={{width: 200}}>{moment(value.createdAt).format('YY.MM.DD hh:mm a')}</div>
                        <div className={cx('text-right')}
                             style={{width: 150}}>{Pipe.toSeparatorNumber(value.changedPoint)}</div>
                        <div className={cx('text-right')}
                             style={{width: 100}}>{Pipe.toSeparatorNumber(value.sendTotal)} 개
                        </div>
                        <div
                            className='text-left'
                            style={{width: 'calc(100% - 600px)', whiteSpace: 'normal', cursor: 'pointer'}}
                            onClick={() => {
                                props.onClickMsgReceipt(value.requestId);
                            }}
                            ref={ref => {
                                if (ref) {
                                    ref.innerText = value.contents;
                                }
                            }}
                        />
                        <div style={{width: 50}}>
                            <IconButton
                                color={'primary'}
                                size='small'
                                onClick={() => {
                                    props.onDownloadUserTelBook({
                                        requestId: value.requestId
                                    });
                                }}>
                                <CloudDownload/>
                            </IconButton>
                        </div>
                    </div>
                )
            })}


            {props.userMsgHistoryList.list.length === 0 &&
            <div className={cx('table')}>
                <div style={{width: '100%'}}>전송기록이 없습니다.</div>
            </div>
            }

        </>
    )
}

import Excel from 'exceljs';
import {TelNumber} from "../../graphql/types";
import {saveAs} from "file-saver";
import moment from "moment";
import {SwalUtil} from "../swal/swalUtil";

export class ExcelUtil {
    static downloadTelBook(userEmail: string, list: TelNumber[]) {
        const workbook = new Excel.Workbook();
        const sheet = workbook.addWorksheet("numbers");
        sheet.columns = [
            {header: 'Number', key: 'number', width: 20},
            {header: 'Status', key: 'status', width: 30}
        ];

        for (let i = 0; i < list.length; i++) {
            const numb = list[i];
            const row = sheet.getRow(i + 1);
            row.getCell(1).value = `010${numb.number.substr(4, numb.number.length)}`;
            row.getCell(2).value = numb.status;
        }

        workbook.xlsx.writeBuffer()
            .then(buffer => {
                let saveData = new Blob([buffer]);
                saveAs(saveData, `${userEmail}-${moment().format('YYMMDD')}.xlsx`);
            })
            .catch(reason => {
                SwalUtil.ok({
                    msg: `${reason}`,
                    icon: 'error'
                });
            })
    }
}

import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    MutationRequestRechargeRequestArgs,
    Query,
    QueryClientRechargeRequestListArgs,
    Mutation,
    MutationCancelRechargeRequestArgs
} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {Title} from "../../shares/title/Title";
import {PointRechargeCouponList} from "./coupon/PointRechargeCouponList";
import {PointRechargeList} from "./list/PointRechargeList";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";

export const PointRecharge = (props: {
    page: number,
}) => {
    const {data, refetch} = useQuery<Query, QueryClientRechargeRequestListArgs>(gqlData, {
        variables: {
            page: props.page,
            size: 20,
        }
    });

    const [mutRequest] = useMutation<Mutation, MutationRequestRechargeRequestArgs>(gqlRequest);
    const [mutCancel] = useMutation<Mutation, MutationCancelRechargeRequestArgs>(gqlCancel);

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/point/recharge/${page}`);
        },
        onRequestRechargeRequest: (args: MutationRequestRechargeRequestArgs) => {
            mutRequest({variables: args})
                .then(() => {
                    return refetch({
                        page: 0,
                        size: 20,
                    });
                })
                .catch(ApolloCatch({}));
        },
        onCancelRechargeRequest: (args: MutationCancelRechargeRequestArgs) => {
            mutCancel({variables: args})
                .then(() => {
                    return refetch({
                        page: 0,
                        size: 20,
                    });
                })
                .catch(ApolloCatch({}));
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>포인트 충전 요청</Title>
                    <PointRechargeCouponList
                        {...handler}
                        query={data}/>
                </BizCol12>

                <BizCol12>
                    <Title>요청 리스트</Title>
                    <PointRechargeList
                        {...handler}
                        query={data}/>
                    <Pager
                        page={data.clientRechargeRequestList.page}
                        size={data.clientRechargeRequestList.size}
                        total={data.clientRechargeRequestList.total}
                        onChange={handler.onChangePage}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

const gqlRequest = gql`
    mutation Reqeust($clientRechargeCouponId: Int!, $otp: String!) {
        requestRechargeRequest(clientRechargeCouponId: $clientRechargeCouponId, otp: $otp)
    }
`;

const gqlCancel = gql`
    mutation Cancel($clientRechargeRequestId: Int!, $otp: String!) {
        cancelRechargeRequest(clientRechargeRequestId: $clientRechargeRequestId, otp: $otp)
    }
`;

const gqlData = gql`
    query DATA($page: Int!, $size: Int!) {
        clientRechargeCoupon {
            id
            amount
            smsCount
            smsFee
            taxAmount
            taxRate
        }
        clientRechargeRequestList(page: $page, size: $size) {
            page
            size
            total
            list {
                id
                status
                coupon {
                    id
                    amount
                    smsCount
                    smsFee
                    taxAmount
                    taxRate
                }
                createdAt
            }
        }
    }
`;

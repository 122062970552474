import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {CreateDirectNotice, DirectNoticeList, Scalars} from "../../../graphql/types";
import {DirectNoticePopupList} from "./list/DirectNoticePopupList";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {Title} from "../../shares/title/Title";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {DirectNoticePopupInput} from "./input/DirectNoticePopupInput";

export const DirectNoticePopup = (props: {
    page: number,
}) => {
    const {data, refetch} = useQuery<DirectNoticePopupResp, DirectNoticePopupReq>(gqlData, {
        variables: {
            page: props.page,
        }
    });

    const [mutChangeActivate] = useMutation<{}, {
        directNoticeId: number,
        isActivate: boolean,
    }>(gqlChangeDirectNoticeActivation);

    const [mutChangeUrgent] = useMutation<{}, {
        directNoticeId: number,
        isUrgent: boolean,
    }>(gqlChangeDirectNoticeUrgent);

    const [mutDeleteDirectNotice] = useMutation<{}, {
        directNoticeId: number,
    }>(gqlDeleteDirectNotice);

    const [mutCreateDirectNotice] = useMutation<{}, {
        input: CreateDirectNotice
    }>(gqlCreateDirectNotice);

    const [input, setInput] = useState<DirectNoticeInput>({
        createDirectNotice: {
            content: '',
            urgent: true,
            isActivate: true,
        }
    });

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/directNotice/popup/${page}`);
        },
        onChangeActivate: (value: {
            directNoticeId: number,
            isActivate: boolean,
        }) => {
            mutChangeActivate({
                variables: value
            }).then(() => {
                return refetch({page: 0})
            }).catch(ApolloCatch({}));
        },
        onChangeUrgent: (value: {
            directNoticeId: number,
            isUrgent: boolean,
        }) => {
            mutChangeUrgent({
                variables: value
            }).then(() => {
                return refetch({page: 0})
            }).catch(ApolloCatch({}));
        },
        onDeleteDirectNotice: (value: {
            directNoticeId: number,
        }) => {
            SwalUtil.yn({
                msg: '삭제 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDeleteDirectNotice({variables: value})
                        .then(() => {
                            return refetch({page: 0})
                        })
                        .catch(ApolloCatch({}));
                }
            })
        },
        onCreateDirectNotice: () => {
            if (input.createDirectNotice.content === "") {
                SwalUtil.ok({
                    msg: '내용을 입력하여 주십시오.',
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.yn({
                msg: '업로드 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutCreateDirectNotice({
                        variables: {
                            input: input.createDirectNotice
                        }
                    }).then(() => {
                        return refetch({page: 0})
                    }).catch(ApolloCatch({}));
                }
            })

        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>팝업 공지 작성</Title>
                    <DirectNoticePopupInput
                        value={input}
                        onChangeValue={setInput}
                        {...handler}/>
                </BizCol12>

                <BizCol12>
                    <Title>팝업공지 리스트</Title>
                    <DirectNoticePopupList
                        {...handler}
                        {...data}/>

                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

interface DirectNoticePopupResp {
    directNotice: DirectNoticeList
}

interface DirectNoticePopupReq {
    page: number,
}

export interface DirectNoticeInput {
    createDirectNotice: CreateDirectNotice
}

const gqlData = gql`
    query DATA($page: Int!) {
        directNotice(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                content
                adminEmail
                isActivate
                urgent
                createdAt
            }
        }
    }
`;

const gqlChangeDirectNoticeActivation = gql`
    mutation ChangeDirectNoticeActivtion($directNoticeId: Int!, $isActivate: Boolean!) {
        changeDirectNoticeActivation(directNoticeId: $directNoticeId, isActivate: $isActivate)
    }
`;

const gqlChangeDirectNoticeUrgent = gql`
    mutation ChangeDirectNoticeUrgent($directNoticeId: Int!, $isUrgent: Boolean!) {
        changeDirectNoticeUrgent(directNoticeId: $directNoticeId, isUrgent: $isUrgent)
    }
`;

const gqlDeleteDirectNotice = gql`
    mutation DeleteDirectNotice($directNoticeId: Int!) {
        deleteDirectNotice(directNoticeId: $directNoticeId)
    }
`;

const gqlCreateDirectNotice = gql`
    mutation CreateDirectNotice($input: CreateDirectNotice!) {
        createDirectNotice(input: $input)
    }
`;

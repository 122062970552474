import React, {Fragment} from "react";
import {UserType} from "../../../graphql/types";

export const BadgeUserType = (props: {
    type: UserType
}) => {
    return (
        <Fragment>
            {props.type === UserType.Agent && <span className='badge badge-secondary'>정회원</span>}
            {props.type === UserType.General && <span className='badge badge-success'>일반회원</span>}
            {props.type === UserType.Reseller && <span className='badge badge-warning'>총판회원</span>}
            {props.type === UserType.Tree && <span className='badge badge-primary'>추천회원</span>}
        </Fragment>
    )
}

import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserBalanceListView.module.scss'
import {UserBalanceHistoryList} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Interfaces";
import {Pager} from "../../../../shares/pager/Pager";
import moment from "moment";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Title} from "../../../../shares/title/Title";
import {BadgeBalanceReceiptType} from "../../../../shares/badgeBalanceReceiptType/BadgeBalanceReceiptType";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {Memory} from "@material-ui/icons";

const cx = classNamesBind.bind(styles);

interface Props {
    history: UserBalanceHistoryList,
    onChangePage: OnChangeValue<number>
}

export const UserBalanceListView = (props: Props) => {
    return (
        <Fragment>
            <Title>잔고 기록</Title>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>


            <Pager
                page={props.history.page}
                size={props.history.size}
                total={props.history.total}
                onChange={props.onChangePage}/>
        </Fragment>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            {props.history.list.map((value, index) => (
                <div key={index} className={cx('cont-mb')}>
                    <div className={cx('quarter', 'text-left')}>
                        <BadgeBalanceReceiptType
                            type={value.receiptType}/>
                    </div>
                    <div className={cx('quarter', 'text-right', 'gray')}>{Pipe.toSeparatorNumber(value.prevPoint)}</div>
                    <div
                        className={cx('quarter', 'text-right', value.changedPoint > 0 ? 'green' : 'red')}>{Pipe.toSeparatorNumber(value.changedPoint)}</div>
                    <div className={cx('quarter', 'text-right')}>{Pipe.toSeparatorNumber(value.point)}</div>

                    {value.memo &&
                    <div className={cx('memo')}>
                        {value.memo}
                    </div>
                    }
                    <div className={cx('date')}>
                        {moment(value.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                </div>
            ))}

        </>
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>번호</div>
                <div style={{width: 200}}>날짜</div>
                <div style={{width: 100}}>내용</div>
                <div style={{width: 'calc((100% - 600px)/2)'}}>변경포인트</div>
                <div style={{width: 'calc((100% - 600px)/2)'}}>잔고</div>
                <div style={{width: 200}}>메모</div>
            </div>

            {props.history.list.map((value, index) => {
                return (
                    <div className={cx('table', 'hover')} key={index}>
                        <div style={{width: 100}}>{value.id}</div>
                        <div style={{width: 200}}>{moment(value.createdAt).format('YY.MM.DD hh:mm a')}</div>
                        <div style={{width: 100}}>
                            <BadgeBalanceReceiptType
                                type={value.receiptType}/>
                        </div>
                        <div className='text-right'
                             style={{width: 'calc((100% - 600px)/2)'}}>
                            {Pipe.toSeparatorNumber(value.changedPoint)} P</div>
                        <div className='text-right'
                             style={{width: 'calc((100% - 600px)/2)'}}>
                            {Pipe.toSeparatorNumber(value.point)} P</div>
                        <div
                            style={{width: 200}}
                            ref={ref => {
                                if (ref) {
                                    ref.innerText = value.memo;
                                }
                            }}/>
                    </div>
                )
            })}
        </>
    )
}

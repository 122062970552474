import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserSendCommission.module.scss'
import {
    MutationWithdrawAllSendCommissionArgs,
    MutationWithdrawSendCommissionArgs,
    Query
} from "../../../../../graphql/types";
import {Checkbox} from '@material-ui/core';
import {UserSendCommission as SendCommission} from "../../../../../graphql/types";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../../utils/pipe/pipe";
import PerfectScrollbar from "react-perfect-scrollbar";
import {OnChangeValue} from "../../../../Interfaces";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import Decimal from "decimal.js";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import moment from "moment";
import {Pager} from "../../../../shares/pager/Pager";

const cx = classNamesBind.bind(styles);

type CompProps = { checkList: UserSendCommissionList, setCheckList: OnChangeValue<UserSendCommissionList> }

export const UserSendCommission = (props: {
    userId: number,
    query: Query,
    onWithdrawSendCommission: OnChangeValue<MutationWithdrawSendCommissionArgs>,
    onWithdrawAllSendCommission: OnChangeValue<MutationWithdrawAllSendCommissionArgs>,
}) => {
    const [checkList, setCheckList] = useState<UserSendCommissionList>([]);

    useEffect(() => {
        const nextCheckList: UserSendCommissionList = props.query.userSendCommission.map(value => ({
            ...value,
            hasChecked: false
        }))
        setCheckList(nextCheckList);
    }, [props.query.userSendCommission]);


    const handler = {
        onWithdrawSendCommission: () => {

            const args: MutationWithdrawSendCommissionArgs = {
                userId: props.userId,
                commissionIdList: checkList.filter(value => (value.hasChecked)).map(value => (value.id)),
                otp: ''
            }

            if (args.commissionIdList.length === 0) {
                SwalUtil.ok({
                    msg: '최소 1개 이상 선택하여 주십시오.',
                    icon: 'error'
                });
                return;
            }

            SwalUtil.otp(otp => {
                args.otp = otp;
                props.onWithdrawSendCommission(args);
            })
        },
        onWithdrawAllSendCommission: () => {
            const args: MutationWithdrawAllSendCommissionArgs = {
                id: props.userId,
                otp: ''
            }

            SwalUtil.otp(otp => {
                args.otp = otp;
                props.onWithdrawAllSendCommission(args);
            })

        }
    }

    return (
        <Fragment>
            <ViewSelector
                web={<Web checkList={checkList} setCheckList={setCheckList}/>}
                mobile={<Mobile checkList={checkList} setCheckList={setCheckList}/>}/>

            <SumView data={checkList}/>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-outline-success'
                        style={{marginRight: 10}}
                        onClick={handler.onWithdrawSendCommission}>선택지급
                    </button>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onWithdrawAllSendCommission}>전체지급
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

const Web = (props: CompProps) => {
    const {checkList} = props;
    const [page, setPage] = useState(0);
    const size = 20;
    const pickedList = checkList.slice(page * size, (page + 1) * size);

    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>선택</div>
                <div style={{width: 'calc(100% - 600px)'}}>대리점</div>
                <div style={{width: 150}}>건당마진</div>
                <div style={{width: 150}}>전송</div>
                <div style={{width: 200}}>커미션</div>
            </div>

            <PerfectScrollbar style={{height: 500}}>
                {props.checkList.length === 0 &&
                    <div className={cx('table')}>
                        <div style={{flexGrow: 1}}>커미션 내역이 없습니다.</div>
                    </div>
                }

                {pickedList.map((value, index) => (
                    <div key={index} className={cx('table', value.hasChecked && 'checked')} onClick={() => {
                        const copy = CopyUtil.copyAll(props.checkList);
                        copy[index].hasChecked = !copy[index].hasChecked;
                        props.setCheckList(copy);
                    }}>
                        <div style={{width: 100}}>
                            <Checkbox
                                color='primary'
                                checked={value.hasChecked}/>
                        </div>
                        <div style={{width: 'calc(100% - 600px)'}}>{value.childrenEmail}</div>
                        <div className='text-right' style={{width: 150}}>{value.margin} 원</div>
                        <div className='text-right' style={{width: 150}}>{Pipe.toSeparatorNumber(value.count)} 개</div>
                        <div className='text-right' style={{width: 200}}>{Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                ))}
                <Pager page={page} size={size} total={checkList.length} onChange={p => setPage(p)}/>
            </PerfectScrollbar>
        </>
    )
}

const Mobile = (props: CompProps) => {
    const {checkList} = props;
    const [page, setPage] = useState(0);
    const size = 20;
    const pickedList = checkList.slice(page * size, (page + 1) * size);

    return (
        <>
            {pickedList.map((value, index) => (
                <div key={index} className={cx('table-mb', value.hasChecked && 'checked')} onClick={ev => {
                    const copy = CopyUtil.copyAll(props.checkList);
                    copy[index].hasChecked = !copy[index].hasChecked;
                    props.setCheckList(copy);
                }}>
                    <div className={cx('checkbox')}>
                        <Checkbox checked={value.hasChecked} color='primary' size='small'/>
                    </div>
                    <div className={cx('email')}>{value.childrenEmail}</div>
                    <div className={cx('commission')}>
                        <span>{Pipe.toSeparatorNumber(value.count)} 개 X {value.margin} 원 = </span>{Pipe.toSeparatorNumber(value.commission)} 원
                    </div>
                </div>

            ))}
            <Pager page={page} size={size} total={checkList.length} onChange={p => setPage(p)}/>
        </>
    )
}


const SumView = (props: {
    data: UserSendCommissionList
}) => {
    let commission = new Decimal(0);
    let selected = new Decimal(0);

    for (let elem of props.data) {
        commission = commission.add(elem.commission);
        if (elem.hasChecked) {
            selected = selected.add(elem.commission)
        }
    }


    return (
        <div className={cx('table')}>
            <div style={{width: '25%'}}>커미션 합</div>
            <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(commission.toNumber())} 원</div>
            <div style={{width: '25%'}}>선택 합</div>
            <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(selected.toNumber())} 원</div>
        </div>
    )

}

type UserSendCommissionList = (SendCommission & { hasChecked: boolean })[];

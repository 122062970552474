import React, {Fragment, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserAdminRecharge.module.scss'
import {Title} from "../../../../shares/title/Title";
import NumberFormat from "react-number-format";

const cx = classNamesBind.bind(styles);

export const UserAdminRecharge = (props: {
    userId: number,
    onChargePoint: (userId: number, point: number, otp: string, memo: string) => void
}) => {
    const [point, setPoint] = useState(0);
    const [otp, setOtp] = useState('');
    const [memo, setMemo] = useState('');

    const onChargePoint = () => {
        props.onChargePoint(props.userId, point, otp, memo);
        setPoint(0);
        setOtp("");
        setMemo("");
    }

    return (
        <Fragment>
            <Title>포인트 충전</Title>
            <div className={cx('table')}>
                <div style={{width: 150}}>포인트</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <NumberFormat
                        className='form-control form-control-sm text-center'
                        value={point}
                        decimalScale={0}
                        onValueChange={values => {
                            setPoint(values.floatValue || 0)
                        }}
                    />
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>OTP</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <input
                        className='form-control form-control-sm text-center'
                        value={otp}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onChargePoint();
                            }
                        }}
                        onChange={event => {
                            setOtp(event.target.value);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 150}}>메모</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <input
                        className='form-control form-control-sm text-center'
                        value={memo}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onChargePoint();
                            }
                        }}
                        onChange={event => {
                            setMemo(event.target.value);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onChargePoint}>적용</button>
                </div>
            </div>
        </Fragment>
    )
}

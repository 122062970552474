import React, {useEffect, useState} from 'react';
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {Title} from "../../shares/title/Title";
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    DepositRechargeDepositFindOptions,
    DepositRechargeDepositFindType,
    DepositRechargeList,
    PointHistoryList,
    RequestDepositRecharge
} from "../../../graphql/types";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {navigate} from "hookrouter";
import {PointRequestList} from "./list/PointRequestList";
import {PointRequestReceipt} from "./receipt/PointRequestReceipt";
import {PointRequestSearchBar} from "./searchBar/PointRequestSearchBar";
import {PointRequestDepositList} from "./depositList/PointRequestDepositList";
import {PointRequestDepositListSearchBar} from "./depositListSearchBar/PointRequestDepositListSearchBar";


export const PointRequest = (props: {
    page: number,
    email?: string,
}) => {
    const [getData, resData] = useLazyQuery<ResData, {
        page: number,
        depositRechargePage: number,
        email?: string,
        depositRechargeOptions?: DepositRechargeDepositFindOptions
    }>(gqlGetData);
    const [data, setData] = useState<ResData | undefined>();
    const [email, setEmail] = useState('');
    const [depositRechargePage, setDepositRechargePage] = useState(0);
    const [depositRechargeOptions, setDepositRechargeOptions] = useState<DepositRechargeDepositFindOptions>({
        findType: DepositRechargeDepositFindType.Nm,
        value: ""

    });

    const [mutAllow] = useMutation<{
        allowRequestDepositRecharge: boolean
    }, {
        id: number,
        otp: string,
    }>(gqlAllow);

    const [mutDeny] = useMutation<{
        denyRequestDepositRecharge: boolean
    }, {
        id: number
    }>(gqlDeny);

    useEffect(() => {
        if (!resData.data) {
            return;
        }

        setData(resData.data);
    }, [resData.data])

    useEffect(() => {
        reload();
    }, [props.page, props.email]);

    const reload = () => {
        getData({
            variables: {
                page: props.page,
                email: props.email,
                depositRechargePage,
                depositRechargeOptions,
            }
        })
    }

    const onChangePage = (p: number) => {
        if (props.email) {
            navigate(`/point/request/${p}/:${encodeURI(props.email)}`);
        } else {
            navigate(`/point/request/${p}`);
        }
    }

    const onAllow = (id: number) => {
        SwalUtil.otp(otp => {
            mutAllow({variables: {id, otp}})
                .then(() => {
                    SwalUtil.ok({
                        msg: '승인되었습니다.',
                        icon: "success"
                    });
                    reload();
                })
                .catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.apolloError()
                }))
        })
    }

    const onDeny = (id: number) => {
        SwalUtil.yn({
            msg: '거절 하시겠습니까?',
            icon: "question",
            ok: () => {
                mutDeny({variables: {id}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '거절 되었습니다..',
                            icon: "success"
                        });
                        reload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        })
    }

    const onSearch = () => {
        if (email) {
            navigate(`/point/request/0/${encodeURI(email)}`);
        } else {
            SwalUtil.ok({
                msg: '검색할 이메일을 입력하여 주십시오.',
                icon: 'error'
            });
        }
    }

    const onClear = () => {
        setEmail('');
        navigate(`/point/request/0`);
    }

    const onChangeDepositRecharge = (p: number) => {
        setDepositRechargePage(p);
        getData({
            variables: {
                page: props.page,
                email: props.email,
                depositRechargePage: p,
                depositRechargeOptions,
            }
        });
    }


    const onClearDepositRequestList = () => {
        const option : DepositRechargeDepositFindOptions = {
            findType: DepositRechargeDepositFindType.Nm,
            value: ''
        }
        setDepositRechargeOptions(option);
        getData({
            variables: {
                page: props.page,
                email: props.email,
                depositRechargePage: 0,
                depositRechargeOptions: option,
            }
        });
    }

    const onSearchDepositRequestList = () => {
        getData({
            variables: {
                page: props.page,
                email: props.email,
                depositRechargePage: 0,
                depositRechargeOptions: depositRechargeOptions,
            }
        });
    }
    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>입금확인요청</Title>
                    <PointRequestList
                        list={data.requestDepositRecharge}
                        onAllow={onAllow}
                        onDeny={onDeny}/>
                </BizCol12>

                <BizCol12>
                    <Title>입금확인요청 리스트</Title>
                    <PointRequestDepositListSearchBar
                        value={depositRechargeOptions}
                        onChangeValue={setDepositRechargeOptions}
                        onClear={onClearDepositRequestList}
                        onSearch={onSearchDepositRequestList}/>
                    <PointRequestDepositList
                        value={data.depositRechargeList}
                        onChangePage={onChangeDepositRecharge}/>
                </BizCol12>

                <BizCol12>
                    <Title>포인트 승인 내역</Title>
                    <PointRequestSearchBar
                        value={email}
                        onChangeValue={setEmail}
                        onSearch={onSearch}
                        onClear={onClear}/>
                    <PointRequestReceipt
                        data={data.pointHistory}
                        onChangePage={onChangePage}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

interface ResData {
    requestDepositRecharge: RequestDepositRecharge[]
    pointHistory: PointHistoryList;
    depositRechargeList: DepositRechargeList,
}

const gqlGetData = gql`
    query Data($page: Int!, $depositRechargePage: Int! $email: String, $depositRechargeOptions: DepositRechargeDepositFindOptions) {
        requestDepositRecharge {
            id
            nm
            amount
            point
            tax
            sendSms
            createdAt
            tel
            userEmail
        }
        pointHistory(page: $page, size: 20, email: $email) {
            size
            page
            total
            list {
                id
                point
                adminEmail
                userEmail
                createdAt
                historyType
            }
        }
        depositRechargeList(page: $depositRechargePage, size: 10, options: $depositRechargeOptions) {
            page
            size
            total
            list {
                id
                amount
                email
                nm
                point
                status
                tel
                createdAt
            }
        }
    }
`;

const gqlAllow = gql`
    mutation Allow($id: Int!, $otp: String!) {
        allowRequestDepositRecharge(id: $id, otp: $otp)
    }
`;

const gqlDeny = gql`
    mutation Deny($id: Int!) {
        denyRequestDepositRecharge(id: $id)
    }
`;

import React, {ReactNode} from 'react';
import {Modal} from "react-bootstrap";
import {Close} from "@material-ui/icons";
import classNamesBind from "classnames/bind";
import styles from './ModalWeb.module.scss';
import PerfectScrollbar from "react-perfect-scrollbar";

const cx = classNamesBind.bind(styles);


export const ModalWeb = (props: {
    title: ReactNode,
    children: ReactNode,
    isShow: boolean,
    onCancel: () => void,
    onOk?: () => void,
    size?: 'sm' | 'lg' | 'xl';
}) => {
    return (
        <>
            <Modal
                show={props.isShow}
                onHide={props.onCancel}
                size={props.size ? props.size : 'lg'}
                centered>
                <div className={cx('header')}>
                    <div>{props.title}</div>
                    <Close onClick={event => {
                        props.onCancel();
                    }}/>
                </div>

                <PerfectScrollbar style={{maxHeight: '60vh'}}>
                    <div style={{padding: '0 0 10px 0'}}>
                        {props.children}
                    </div>
                </PerfectScrollbar>

            </Modal>
        </>
    )
}

export class CopyUtil {
    static Copy<T>(list: T[]): T[] {
        const res: T[] = [];

        for (let t of list) {
            res.push(Object.assign({}, t))
        }

        return res;
    }

    static copyAll<T>(value: T): T {
        return JSON.parse(JSON.stringify(value));
    }
}

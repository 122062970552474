import {makeVar} from "@apollo/client";
import {ChatAlert, DepositRequestAlert, SettleCommissionAlert} from "../graphql/types";
import {LocalStorage} from "../utils/localstorage/LocalStorage";

export const GlobalVars = {
    alert: {
        depositRequest: makeVar<DepositRequestAlert[]>([]),
        chatAlert: makeVar<ChatAlert[]>([]),
        settleCommissionAlert: makeVar<SettleCommissionAlert[]>([]),
        alertSound: makeVar(LocalStorage.getAlertSound()),
    },
    auth: {},
    layout: {
        loadingView: makeVar(false),
    },
    user: {}
}

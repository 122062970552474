import React from 'react';
import classNamesBind from "classnames/bind";
import style from "./Title.module.scss"

const cx = classNamesBind.bind(style);

export const Title = (props: { children: any }) => {
    return (
        <h5 className={cx('title')}>{props.children}</h5>
    )
}


export const SmTitle = (props: { children: any }) => {
    return (
        <h5 className={cx('sm-title')}>{props.children}</h5>
    )
}

import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './DashboardApiBalance.module.scss';
import {Query} from "../../../../../graphql/types";
import Decimal from "decimal.js";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Switch} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const DashboardApiBalance = (props: {
    data: Query,
}) => {
    const [showAll, setShowAll] = useState(false);
    const firstApiPermission = props.data.apiKeyList[0];

    return (
        <>
            {!showAll &&
            <div
                className={cx('table', 'hover')}>
                <div className={cx("nameCell")}>{firstApiPermission.nm}</div>
                <div className={cx("valueCell", "textRight")}>
                    {Pipe.toSeparatorNumber(new Decimal(props.data.dashboard.restBalance).divToInt(firstApiPermission.smsFee).round().toNumber())} 개 전송가능
                </div>
            </div>
            }

            {showAll && props.data.apiKeyList.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx("nameCell")}>{value.nm}</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(new Decimal(props.data.dashboard.restBalance).divToInt(value.smsFee).round().toNumber())} 개 전송가능
                    </div>
                </div>

            ))}

            <div className={cx('table')}>
                <div className={cx("textRight")} style={{width: 'calc(100% - 100px)'}}>
                    <Switch
                        color='primary'
                        checked={showAll}
                        onChange={ev => {
                            setShowAll(ev.target.checked)
                        }}/>
                </div>
                <div style={{width: 100}}>모두보기</div>
            </div>
        </>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserTelBookModal.module.scss'
import {TelBook, TelNumber} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";
import {FadeInContainer} from "../../../../shares/fadeInContainer/FadeInContainer";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Title} from "../../../../shares/title/Title";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BadgeNumberStatus} from "../../../../shares/badgeNumberStatus/BadgeNumberStatus";
import {saveAs} from "file-saver";

const cx = classNamesBind.bind(styles);

export const UserTelBookModal = (props: {
    telBook: TelBook
}) => {
    const [result, setResult] = useState<Result>({
        delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
    });

    const [searchNumbResult, setSearchNumbResult] = useState<TelNumber[]>([]);
    const [searchNumb, setSearchNumb] = useState("");

    useEffect(() => {
        const nextResult: Result = {
            delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
        }

        nextResult.requested = props.telBook.requestTotal;

        for (let telNumber of props.telBook.numberList) {
            switch (telNumber.status) {
                case "Sent":
                    nextResult.sent += 1;
                    break;
                case "Fail":
                    nextResult.fail += 1;
                    break;
                case "DlrDelivered":
                    nextResult.delivered += 1;
                    break;
                case "DlrUndelivered":
                    nextResult.undelivered += 1;
                    break;
            }
        }

        setResult(nextResult);
    }, [props.telBook]);

    const data: Data[] = [
        {label: '전송완료', value: result.delivered},
        {label: '수신결과대기', value: result.requested - (result.delivered + result.undelivered)},
        {label: '수신실패', value: result.undelivered},
        {label: '전송실패', value: result.fail},
    ];


    const chartData: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        labels: data.map(value => {
            return value.label;
        }),
        series: data.map(value => {
            return value.value
        })
    }

    const onSearchNumb = () => {
        const searchResult = props.telBook.numberList.filter(value => {
            return value.number.includes(searchNumb)
        });
        setSearchNumbResult(searchResult);
    }

    const onDownloadTelBook = () => {
        let page = "전화번호,상태\n";
        for (let telNumber of props.telBook.numberList) {
            page += `${telNumber.number},${telNumber.status}\n`;
        }

        let dataValue = new Blob([page], {type: 'application/csv'});
        saveAs(dataValue, `${props.telBook.requestId}.csv`);
    }

    return (
        <Fragment>
            <div style={{height: 30}}/>
            <FadeInContainer>
                <div className='col-12 col-md-6' style={{marginBottom: 30}}>
                    <Chart
                        type='donut'
                        options={chartData}
                        series={chartData.series}
                        width={'100%'}
                        height={'400px'}/>
                </div>
                <div className='col-12 col-md-6' style={{marginBottom: 30}}>
                    <Title>전송요약</Title>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>전송</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(props.telBook.requestTotal)} 개
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>완료</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(props.telBook.sent)} 개
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>실패</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(props.telBook.fail)} 개
                        </div>
                    </div>

                    <div style={{height: 30}}/>
                    <Title>수신 요약</Title>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>총 수신량</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(props.telBook.requestTotal)} 개
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>수신완료</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(result.delivered)} 개
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>수신대기</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(props.telBook.requestTotal - (result.delivered + result.undelivered))} 개
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div style={{width: 100}}>수신실패</div>
                        <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                            {Pipe.toSeparatorNumber(result.undelivered)} 개
                        </div>
                    </div>
                </div>

                <div className='col-12' style={{marginBottom: 30}}>
                    <Title>내용</Title>
                    <div className={cx('table')}>
                        <div style={{width: '30%'}}>요청키</div>
                        <div className='text-left' style={{width: '70%'}}>{props.telBook.requestId}</div>
                    </div>

                    <div className={cx('table')}>
                        <div style={{width: '30%'}}>내용</div>
                        <div className='text-left' style={{width: '70%'}} ref={ref => {
                            if (!ref) {
                                return;
                            }

                            ref.innerText = props.telBook.msg
                        }}/>
                    </div>

                    <div className={cx('table')}>
                        <div className='text-right' style={{width: '100%'}}>
                            <button
                                style={{width: 100}}
                                className='btn btn-sm btn-outline-success'
                                onClick={onDownloadTelBook}>다운로드</button>
                        </div>
                    </div>
                </div>

                <div className='col-12' style={{marginBottom: 30}}>
                    <Title>전화번호 검색</Title>
                    <form onSubmit={ev => {
                        ev.preventDefault();
                        onSearchNumb();
                    }}>
                        <div className={cx('table')}>
                            <div style={{width: '30%'}}>전화번호</div>
                            <div style={{width: '70%'}}>
                                <input
                                    value={searchNumb}
                                    placeholder='전화번호를 입력하여 주십시오.'
                                    className='form-control form-control-sm'
                                    onChange={ev => {
                                        setSearchNumb(ev.target.value);
                                    }}/>
                            </div>
                        </div>
                        <div className={cx('table')}>
                            <div className='text-right' style={{width: '100%'}}>
                                <button
                                    type='submit'
                                    style={{width: 100}}
                                    className='btn btn-sm btn-outline-success'>검색</button>
                            </div>
                        </div>

                    </form>

                </div>

                <div className='col-12' style={{marginBottom: 30}}>
                    <Title>검색 결과</Title>

                    <PerfectScrollbar style={{height: 300}}>
                        {searchNumbResult.length === 0 &&
                        <BizNoDataTable/>
                        }

                        {searchNumbResult.map((value, index) => (
                            <div key={index} className={cx('table')}>
                                <div style={{width: '50%'}}>{value.number}</div>
                                <div style={{width: '50%'}}>
                                    <BadgeNumberStatus numberStatus={value.status}/>
                                </div>
                            </div>
                        ))}
                    </PerfectScrollbar>


                </div>
            </FadeInContainer>

        </Fragment>
    )
}

interface Data {
    label: string;
    value: number;
}

interface Result {
    delivered: number;
    undelivered: number;
    requested: number;
    sent: number;
    fail: number;
}

import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserView.module.scss'
import {UserType} from "../../../graphql/types";
import {UserViewAgent} from "./agent/UserViewAgent";
import {UserViewGeneral} from "./general/UserViewGeneral";
import {UserViewReseller} from "./reseller/UserViewReseller";
import { UserViewTree } from "./tree/UserViewTree";

const cx = classNamesBind.bind(styles);

export const UserView = (props: {
    id: number,
    userType: UserType
}) => {
    return (
        <Fragment>
            {props.userType === UserType.Agent && <UserViewAgent id={props.id}/>}
            {props.userType === UserType.General  && <UserViewGeneral id={props.id}/>}
            {props.userType === UserType.Reseller && <UserViewReseller id={props.id}/>}
            {props.userType === UserType.Tree && <UserViewTree id={props.id}/>}
        </Fragment>
    )
}


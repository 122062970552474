import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LayoutAction, LayoutState, ViewMode} from "../redux/Layout";
import {UserState} from "../redux/User";
import {HookRouter, useRoutes} from "hookrouter";
import {NotAuthLayoutWeb} from "./layout/notAuth/NotAuthLayoutWeb";
import {DashboardV1} from './dashboard/v1/DashboardV1';
import {
    AccountCircle,
    AttachMoney, ChatOutlined,
    Dashboard,
    FlashOn,
    Keyboard,
    LocalParking,
    Publish,
    Settings, Telegram
} from "@material-ui/icons";
import {AuthJoin} from "./auth/join/AuthJoin";
import {AuthLogin} from "./auth/login/AuthLogin";
import {NotFound} from "./system/notFound/NotFound";
import {SidebarMenu} from "./layout/auth/sidebar/LayoutSidebar";
import {AuthLayout} from "./layout/auth/AuthLayout";
import {CodeIssue} from "./code/issue/CodeIssue";
import {AppStatus, SystemState} from "../redux/System";
import {NotAuth} from "./system/notAuth/NotAuth";
import {PointRequest} from "./point/request/PointRequest";
import {UserList} from "./user/list/UserList";
import {UserView} from "./user/view/UserView";
import {ReportNow} from "./report/now/ReportNow";
import {ReportBalances} from "./report/balances/ReportBalances";
import {ValuesDefault} from "./values/default/ValuesDefault";
import {BoardList} from "./board/list/BoardList";
import {BoardCreate} from "./board/create/BoardCreate";
import {BoardType} from "../graphql/types";
import {BoardUpdate} from "./board/update/BoardUpdate";
import {Validator} from "../utils/validator/Validator";
import {BizBackdrop} from "./shares/bizBackdrop/BizBackdrop";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../globalVars/globalVars";
import {DirectNoticePopup} from "./directNotice/popup/DirectNoticePopup";
import Favicon from "react-favicon";
import {ChatDefault} from "./chat/default/ChatDefault";
import {ReportMonthly} from "./report/monthly/ReportMonthly";
import {Pipe} from "../utils/pipe/pipe";
import {TelReportV1} from "./telReport/v1/TelReportV1";
import {AlertCenter} from "./system/alertCenter/AlertCenter";
import {PointRecharge} from "./point/recharge/PointRecharge";
import {PointCommission} from "./point/commission/PointCommission";

export const App = () => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const user = useSelector<any, UserState>(state => state.user);
    const system = useSelector<any, SystemState>(state => state.system);

    const dispatch = useDispatch();
    const routes = user.isLogin ? AuthRoutes : NotAuthRoutes;
    const child = useRoutes(routes);
    const hasLoadingView = useReactiveVar(GlobalVars.layout.loadingView);


    // 레이아웃 초기화
    useEffect(() => {
        if ((user.isLogin) && (layout.viewMode === ViewMode.Web)) {
            dispatch(LayoutAction.setSidebar(true))
        } else {
            dispatch(LayoutAction.setSidebar(false))
        }

    }, [user])

    return (
        <>
            <Favicon url={[`/NiceSMS.ico`]}/>

            {system.appStatus === AppStatus.Ok &&
            <>
                {user.isLogin &&
                <AuthLayout>
                    {child || <NotFound/>}
                    <AlertCenter/>
                </AuthLayout>
                }

                {!user.isLogin &&
                <NotAuthLayoutWeb>
                    {child || <NotFound/>}
                </NotAuthLayoutWeb>
                }
            </>
            }

            {system.appStatus === AppStatus.NotAuth &&
            <NotAuth/>
            }

            <BizBackdrop loading={hasLoadingView}/>
        </>
    );
}


const AuthRoutes: HookRouter.RouteObject = {
    '/': () => <DashboardV1/>,
    '/code': () => <CodeIssue generalPage={0} resellerPage={0} treePage={0}/>,
    '/code/:generalPage/:resellerPage/:treePage':
        ({generalPage, resellerPage, treePage}) =>
            <CodeIssue
                generalPage={Validator.isNumber(generalPage)}
                resellerPage={Validator.isNumber(resellerPage)}
                treePage={Validator.isNumber(treePage)}/>,
    '/point': params => <PointRequest page={0}/>,
    '/point/request': params => <PointRequest page={0}/>,
    '/point/request/:page': ({page}) =>
        <PointRequest page={Validator.isNumber(page)}/>,
    '/point/request/:page/:email': ({page, email}) =>
        <PointRequest page={Validator.isNumber(page)} email={email}/>,
    '/point/recharge': () => <PointRecharge page={0}/>,
    '/point/recharge/:page': ({page}) => <PointRecharge page={Validator.isNumber(page)}/>,
    '/point/commission': () => <PointCommission rechargePage={0} sendPage={0}/>,
    '/point/commission/:recharge/:send': ({recharge, send}) =>
        <PointCommission rechargePage={Validator.isNumber(recharge)} sendPage={Validator.isNumber(send)}/>,
    '/user': () => <UserList page={0}/>,
    '/user/:page': ({page}) => <UserList page={Validator.isNumber(page)}/>,
    '/user/view/:id/:userType': ({id, userType}) => <UserView id={Validator.isNumber(id)} userType={userType}/>,
    '/report': params => <ReportNow/>,
    '/report/monthly': params => <ReportMonthly timeMonth={Pipe.toTimeMonth(new Date())}/>,
    '/report/monthly/:monthly': ({monthly}) => <ReportMonthly timeMonth={Validator.isNumber(monthly)}/>,
    '/report/balances': params => <ReportBalances/>,
    '/values': params => <ValuesDefault/>,
    '/board': params => <BoardList boardType={BoardType.Notice}/>,
    '/board/list/:boardType': ({boardType}) => <BoardList boardType={boardType}/>,
    '/board/list/:boardType/:page': ({boardType, page}) => <BoardList boardType={boardType} page={page}/>,
    '/board/create/:boardType': ({boardType}) => <BoardCreate boardType={boardType}/>,
    '/board/update/:boardType/:id': ({boardType, id}) => <BoardUpdate boardType={boardType} id={id}/>,
    '/directNotice': () => <DirectNoticePopup page={0}/>,
    '/directNotice/popup': () => <DirectNoticePopup page={0}/>,
    '/directNotice/popup/:page': ({page}) => <DirectNoticePopup page={Validator.isNumber(page)}/>,
    '/chat': () => <ChatDefault chatTitlePage={0}/>,
    '/chat/:chatTitlePage': ({chatTitlePage}) => <ChatDefault chatTitlePage={Validator.isNumber(chatTitlePage)}/>,
    '/chat/:chatTitlePage/:userId': ({userId, chatTitlePage}) =>
        <ChatDefault userId={Validator.isNumber(userId)} chatTitlePage={Validator.isNumber(chatTitlePage)}/>,
    '/tel-report': () => <TelReportV1 page={0}/>,
    '/tel-report/:page': ({page}) => <TelReportV1 page={Validator.isNumber(page)}/>,
}

const NotAuthRoutes: HookRouter.RouteObject = {
    '/': params => <AuthLogin/>,
    '/join': params => <AuthJoin/>,
}

export const GetSidebar = (): SidebarMenu[] => {
    const sideMenu: SidebarMenu[] = [
        {
            icon: style => <Dashboard style={style}/>, title: '대쉬보드', router: '/',
            topNav: []
        },
        {
            icon: style => <AttachMoney style={style}/>, title: '정산', router: '/report',
            topNav: [
                {icon: <Dashboard/>, title: '일일정산', router: '/report'},
                {icon: <Dashboard/>, title: '월간정산', router: '/report/monthly'},
                {icon: <Dashboard/>, title: '입출금현황', router: '/report/balances'},
            ]
        },
        {
            icon: style => <AccountCircle style={style}/>, title: '회원정보', router: '/user',
            topNav: []
        },
        {
            icon: style => <Publish style={style}/>, title: '코드발급', router: '/code',
            topNav: []
        },
        {
            icon: style => <LocalParking style={style}/>, title: '포인트', router: '/point',
            topNav: [
                {icon: <Dashboard/>, title: '입금확인', router: '/point/request'},
                {icon: <Dashboard/>, title: '커미션정산', router: '/point/commission'},
                {icon: <Dashboard/>, title: '포인트충전', router: '/point/recharge'},

            ]
        },
        {
            icon: style => <Keyboard style={style}/>, title: '게시물관리', router: '/board',
            topNav: [
                {icon: <Dashboard/>, title: '공지사항', router: '/board/list/Notice'},
                {icon: <Dashboard/>, title: '팝업', router: '/board/list/Popup'},
            ]
        },
        {
            icon: style => <FlashOn style={style}/>, title: '긴급공지', router: '/directNotice',
            topNav: [
                {icon: <Dashboard/>, title: '팝업공지', router: '/directNotice/popup'},
            ]

        },
        {
            icon: style => <ChatOutlined style={style}/>, title: '문의', router: '/chat',
            topNav: [],
        },

        {
            icon: style => <Settings style={style}/>, title: '기본설정', router: '/values',
            topNav: []
        },
    ]

    if (process.env.REACT_APP_TELEGRAM_PERMISSION === 'true') {
        sideMenu.push({
            icon: style => <Telegram style={style}/>, title: '텔레그램', router: '/tel-report',
            topNav: [],
        },)
    }

    return sideMenu;
}

export class LoadingViewUtil {
    static loadingView(isShow: boolean) {
        GlobalVars.layout.loadingView(isShow);
    }

    static loading(promise: Promise<any>) {
        GlobalVars.layout.loadingView(true);
        promise.then(value => {
            GlobalVars.layout.loadingView(false);
        }).catch(e => {
            GlobalVars.layout.loadingView(false);
        })
    }
}

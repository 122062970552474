import React, {Fragment} from "react";
import {SettleCommissionType} from "../../../graphql/types";

export const BadgeSettleCommissionType = (props: {
    status: SettleCommissionType
}) => {
    return (
        <Fragment>
            {props.status === SettleCommissionType.Withdraw && <span className='badge badge-success'>출금</span>}
            {props.status === SettleCommissionType.ChargePoint && <span className='badge badge-primary'>포인트 충전</span>}
        </Fragment>
    )
}

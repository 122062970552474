import React, {useEffect, useState} from 'react';
import moment from "moment";
import Chart from "react-apexcharts";
import {ReportGraph} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import classNamesBind from "classnames/bind";
import styles from "./DashboardSendGraph.module.scss";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../../redux/Layout";

export const DashboardSendGraph = (props: {
    data: ReportGraph[]
}) => {
    const cx = classNamesBind.bind(styles);

    const [chartHeight, setChartHeight] = useState<string>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setChartHeight(styles.chartBrowserHeight);
        } else {
            setChartHeight(styles.chartMobileHeight);
        }
    }, [layout.viewMode]);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            }
        },
        xaxis: {
            categories: props.data.map(value => {
                return [moment(value.date).format('MM.DD')]
            })
        }
    };

    const series = [
        {
            name: '전송량',
            type: 'column',
            data: props.data.map(value => {
                return value.count
            })
        },
    ];




    return (
        <>
            <Chart
                className={cx("chart")}
                type="bar"
                options={options}
                series={series}
                width={styles.chartWidth}
                height={chartHeight}
            />
        </>
    )
}

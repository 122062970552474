import React, {Fragment, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserTelBookWithPeriod.module.scss'
import {OnChangeValue} from "../../../../Interfaces";
import {QueryUserTelBookWithPeriodArgs} from "../../../../../graphql/types";
import {Title} from "../../../../shares/title/Title";
import {BizPeriodPicker, Period} from "../../../../shares/bizPeriodPicker/BizPeriodPicker";
import {Pipe} from "../../../../../utils/pipe/pipe";

const cx = classNamesBind.bind(styles);

export const UserTelBookWithPeriod = (props: {
    userId: number,
    onTelBookWithPeriod: OnChangeValue<QueryUserTelBookWithPeriodArgs>
}) => {
    const [period, setPeriod] = useState<Period>({
        stDate: new Date(),
        edDate: new Date(),
    })

    const handler = {
        onClickSave: () => {
            props.onTelBookWithPeriod({
                userId: props.userId,
                stDate: Pipe.toTimeDay(period.stDate),
                edDate: Pipe.toTimeDay(period.edDate),
            })
        }
    }

    return (
        <Fragment>
            <Title>기간별 전화번호 다운로드</Title>
            <div className='def-text'>* 기간별 중복 제거된 전화번호부를 다운로드 합니다. (다소 시간이 필요합니다.)</div>
            <div className='def-text' style={{marginBottom: 20}}>* 전화번호가 많으면 로딩화면이 끝난 후 조금 더 기다리셔야 다운로드 됩니다.</div>

            <div className={cx('table')}/>
            <BizPeriodPicker period={period} onChange={setPeriod}/>
            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onClickSave}>저장</button>
                </div>
            </div>
        </Fragment>
    )
}

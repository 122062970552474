import React, {ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './DashboardV1View.module.scss'
import {Query} from "../../../../graphql/types";
import {FadeInContainer} from "../../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../../shares/boxCont/BoxCont";
import {Title} from "../../../shares/title/Title";
import NumberFormat from "react-number-format";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {DashboardApiBalance} from "./apiPermissions/DashboardApiBalance";
import {DashboardSendGraph} from "./sendGraph/DashboardSendGraph";
import {DashboardViewRecentlySend} from "./recentlySend/DashboardViewRecentlySend";

const cx = classNamesBind.bind(styles);

export const DashboardV1View = (props: {
    query: Query
}) => {
    return (
        <FadeInContainer>
            <MiniBox>
                <Title>사용</Title>
                <div className='text-right'>
                    <NumberFormat
                        suffix={' 개'}
                        thousandSeparator
                        displayType='text'
                        value={props.query.dashboard.sendSms}
                    />
                </div>
            </MiniBox>

            <MiniBox>
                <Title>입금</Title>
                <div className='text-right'>
                    <NumberFormat
                        suffix={' 원'}
                        thousandSeparator
                        displayType='text'
                        value={props.query.dashboard.chargeAmount}
                    />
                </div>
            </MiniBox>

            <MiniBox>
                <Title>입금 대기</Title>
                <div className='text-right'>
                    <NumberFormat
                        suffix={' 개'}
                        thousandSeparator
                        displayType='text'
                        value={props.query.dashboard.readyForCharge}
                    />
                </div>
            </MiniBox>
            <MiniBox>
                <Title>잔고</Title>
                <div className='text-right'>
                    <NumberFormat
                        suffix={' P'}
                        thousandSeparator
                        displayType='text'
                        value={props.query.dashboard.restBalance}
                    />
                </div>
            </MiniBox>

            <div className={cx("divideBlock")} />

            <BizCol12>
                <Title>회선별 잔고 현황</Title>
                <DashboardApiBalance
                    data={props.query}/>
            </BizCol12>

            <BizCol12>
                <Title>일간 전송량</Title>
                <DashboardSendGraph
                    data={props.query.dailyUsageGraph}/>
            </BizCol12>

            <BizCol12>
                <Title>최근 전송 목록</Title>
                <DashboardViewRecentlySend
                    query={props.query}/>
            </BizCol12>

        </FadeInContainer>
    )
}


const MiniBox = (props: {
    children: ReactNode
}) => {
    return (
        <div className={cx("miniBox")}>
            <BoxCont>
                {props.children}
            </BoxCont>
        </div>
    )
}

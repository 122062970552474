import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ReportBalances.module.scss'
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {Title} from "../../shares/title/Title";
import {BizMonthPicker} from "../../shares/bizMonthPicker/BizMonthPicker";
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {CompanyBalances, CreateCompanyBalanceDepositWithdraw, DepositWithdraw} from "../../../graphql/types";
import {Pipe} from "../../../utils/pipe/pipe";
import moment from "moment";
import {IconButton, Tooltip} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {OnChangeValue} from "../../Interfaces";
import {BadgeCompanyBalance} from "../../shares/badgeCompanyBalance/BadgeCompanyBalance";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizDatePicker} from "../../shares/bizDatePicker/BizDatePicker";
import NumberFormat from "react-number-format";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {ReportBalanceTotal} from "./total/ReportBalanceTotal";

const cx = classNamesBind.bind(styles);

export const ReportBalances = () => {
    const [month, setMonth] = useState(new Date());
    const [balances, setBalances] = useState<CompanyBalances | undefined>();
    const [createDepositModal, setCreateDepositModal] = useState(false);
    const [createWithdrawModal, setCreateWithdrawModal] = useState(false);

    const [getData, resData] = useLazyQuery<{
        companyBalances: CompanyBalances
    }, {
        month: Date
    }>(gqlData);
    const [mutDeleteDeposit] = useMutation<{
        deleteCompanyBalanceDeposit: boolean
    }, {
        id: number
    }>(gqlDeleteCompanyBalanceDeposit);
    const [mutDeleteWithdraw] = useMutation<{
        deleteCompanyBalanceWithdraw: boolean
    }, {
        id: number
    }>(gqlDeleteCompanyBalanceWithdraw);
    const [mutCreateDeposit] = useMutation<{
        createCompanyBalanceDeposit: boolean,
    }, {
        input: CreateCompanyBalanceDepositWithdraw
    }>(gqlCreateCompanyBalanceDeposit)
    const [mutCreateWithdraw] = useMutation<{
        createCompanyBalanceWithdraw: boolean
    }, {
        input: CreateCompanyBalanceDepositWithdraw
    }>(gqlcreateCompanyBalanceWithdraw);

    useEffect(() => {
        getData({variables: {month}});
    }, []);

    useEffect(() => {
        if (resData.data) {
            setBalances(resData.data.companyBalances);
        }
    }, [resData.data]);

    const onChangeMonth = (pickedMonth: Date) => {
        getData({variables: {month: pickedMonth}});
        setMonth(pickedMonth);
    }

    const onDeleteDeposit = (id: number) => {
        SwalUtil.yn({
            msg: '삭제 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutDeleteDeposit({variables: {id}})
                    .then(res => {
                        return getData({variables: {month}})
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError(),
                        [BizErrors.notFoundData]: SwalUtil.apolloErrorOk({
                            msg: '삭제할수 없는 데이터 입니다.',
                            icon: "error"
                        })
                    }))

            }
        })
    }

    const onDeleteWithdraw = (id: number) => {
        SwalUtil.yn({
            msg: '삭제 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutDeleteWithdraw({variables: {id}})
                    .then(res => {
                        return getData({variables: {month}})
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError(),
                        [BizErrors.notFoundData]: SwalUtil.apolloErrorOk({
                            msg: '삭제할수 없는 데이터 입니다.',
                            icon: "error"
                        })
                    }))

            }
        })
    }

    const onCreateDeposit = (input: CreateCompanyBalanceDepositWithdraw) => {
        setCreateDepositModal(false);
        mutCreateDeposit({variables: {input}})
            .then(res => {
                SwalUtil.ok({
                    msg: '추가 되었습니다.',
                    icon: 'success'
                })
                return getData({variables: {month}});
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError()
            }));
    }

    const onCreateWithdraw = (input: CreateCompanyBalanceDepositWithdraw) => {
        setCreateWithdrawModal(false);
        mutCreateWithdraw({variables: {input}})
            .then(res => {
                SwalUtil.ok({
                    msg: '추가 되었습니다.',
                    icon: 'success'
                })
                return getData({variables: {month}});
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError()
            }));
    }

    const onClickCreateDeposit = () => {
        setCreateDepositModal(true);
    }

    const onClickCreateWithdraw = () => {
        setCreateWithdrawModal(true);
    }


    return (
        <>
            {balances &&
            <FadeInContainer>
                <ModalWeb
                    title={'입금 추가'}
                    isShow={createDepositModal}
                    onCancel={() => {
                        setCreateDepositModal(false);
                    }}
                    onOk={() => {
                        setCreateDepositModal(false);
                    }}>
                    <CreateModal
                        onCreate={onCreateDeposit}/>
                </ModalWeb>

                <ModalWeb
                    title={'출금 추가'}
                    isShow={createWithdrawModal}
                    onCancel={() => {
                        setCreateWithdrawModal(false);
                    }}
                    onOk={() => {
                        setCreateWithdrawModal(false);
                    }}>
                    <CreateModal
                        onCreate={onCreateWithdraw}/>
                </ModalWeb>

                <BizCol12>
                    <div className={cx('flex')}>
                        <div style={{width: '50%', paddingTop: 5}}>
                            <Title>입출금현황</Title>
                        </div>
                        <div className={'text-right'} style={{flexGrow: 1}}>
                            <BizMonthPicker date={month} onChange={onChangeMonth}/>
                        </div>
                    </div>

                    <ReportBalanceTotal companyBalance={balances}/>
                </BizCol12>

                <div className='col-12 col-xl-6' style={{marginBottom: 30}}>
                    <BoxCont>
                        <div className={cx('cont-title')}>
                            <div style={{width: '50%', paddingTop: 5}}>
                                <Title>입금 목록</Title>
                            </div>
                            <div className={cx('half', 'text-right')}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onClickCreateDeposit}>추가
                                </button>
                            </div>
                        </div>

                        <DepositWithdrawList
                            list={balances.deposit}
                            onDelete={onDeleteDeposit}
                            emptyListMsg="입금 내역이 없습니다."/>
                    </BoxCont>
                </div>


                <div className='col-12 col-xl-6' style={{marginBottom: 30}}>
                    <BoxCont>
                        <div className={cx('cont-title')}>
                            <div style={{width: '50%', paddingTop: 5}}>
                                <Title>출금 목록</Title>
                            </div>
                            <div className={cx('half', 'text-right')}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onClickCreateWithdraw}>추가
                                </button>
                            </div>
                        </div>

                        <DepositWithdrawList
                            list={balances.withdraw}
                            onDelete={onDeleteWithdraw}
                            emptyListMsg="출금 내역이 없습니다."/>
                    </BoxCont>
                </div>
            </FadeInContainer>
            }
        </>
    )
}


const DepositWithdrawList = (props: {
    list: DepositWithdraw[],
    onDelete: OnChangeValue<number>,
    emptyListMsg: string,
}) => {
    const [modal, setModal] = useState(false);
    const [selectedItm, setSelectedItem] = useState<DepositWithdraw | undefined>();


    const onSelect = (item: DepositWithdraw) => {
        setSelectedItem(item);
        setModal(true);
    }

    return (
        <>
            <ModalWeb
                title={'상세내역'}
                size={'lg'}
                isShow={modal}
                onCancel={() => {
                    setModal(false)
                }}
                onOk={() => {
                    setModal(false);
                }}>
                {selectedItm &&
                <DetailModal item={selectedItm}/>
                }
            </ModalWeb>

            <div className={cx('table')}>
                <div style={{width: '5%'}}/>
                <div className={cx('header')} style={{width: `20%`}}>일시</div>
                <div className={cx('header')} style={{width: `15%`}}>내용</div>
                <div className={cx('header')} style={{width: `35%`}}>메모</div>
                <div className={cx('header')} style={{width: `25%`}}>금액</div>
            </div>

            {props.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div style={{width: '5%'}}>
                            {value.isDeletable &&
                            <Tooltip title={'삭제'}>
                                <IconButton
                                    size='small'
                                    color='secondary'
                                    onClick={event => {
                                        props.onDelete(value.id);
                                    }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                            }
                        </div>
                        <div
                            style={{width: '20%'}}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                        <div style={{width: '15%'}} onClick={event => {
                            onSelect(value);
                        }}>
                            <BadgeCompanyBalance value={value.type}/>
                        </div>
                        <div className={'text-left'} style={{width: '35%'}}
                             onClick={event => {
                                 onSelect(value);
                             }}>{value.memo}</div>
                        <div className='text-right' style={{width: '25%'}}
                             onClick={event => {
                                 onSelect(value);
                             }}>
                            {Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                    </div>
                )
            })}

            {props.list.length === 0 &&
            <div className={cx('table')}>
                <div style={{width: '100%'}}>{props.emptyListMsg}</div>
            </div>
            }
        </>
    )
}

const DetailModal = (props: {
    item: DepositWithdraw
}) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 150}}>번호</div>
                <div style={{flexGrow: 1}}>{props.item.id}</div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>내용</div>
                <div style={{flexGrow: 1}}>
                    <BadgeCompanyBalance value={props.item.type}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>금액</div>
                <div style={{flexGrow: 1}}>
                    {Pipe.toSeparatorNumber(props.item.amount)} 원
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>메모</div>
                <div style={{width: 'calc(100% - 150px)', whiteSpace: 'normal'}}
                     ref={ref => {
                         if (ref) {
                             ref.innerHTML = props.item.memo
                         }
                     }}
                />
            </div>
        </>
    )
}

const CreateModal = (props: {
    onCreate: (input: CreateCompanyBalanceDepositWithdraw) => void,
}) => {
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState(0);
    const [memo, setMemo] = useState("");

    const onAppend = () => {
        if (amount <= 0) {
            SwalUtil.ok({
                msg: '금액은 0 이상의 값을 입력하여 주십시오.',
                icon: 'error'
            });
            return;
        }

        props.onCreate({
            date, amount, memo
        });

        setDate(new Date());
        setAmount(0);
        setMemo("");
    }


    return (
        <>
            <div className={'text-center'} style={{display: 'flex', height: 60}}>
                <div style={{width: 150, paddingTop: 15}}>날짜</div>
                <div className='text-left' style={{flexGrow: 1, paddingTop: 15, paddingLeft: 10}}>
                    <BizDatePicker date={date} onChange={setDate}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>금액</div>
                <div style={{flexGrow: 1}}>
                    <NumberFormat
                        className='form-control'
                        thousandSeparator
                        allowNegative={false}
                        decimalScale={0}
                        value={amount}
                        onValueChange={values => {
                            setAmount(values.floatValue || 0)
                        }}
                    />
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 150}}>메모</div>
                <div style={{flexGrow: 1}}>
                    <textarea
                        value={memo}
                        className='form-control'
                        style={{resize: 'none', height: 200}}
                        onChange={event => {
                            setMemo(event.target.value);
                        }}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onAppend}>추가
                    </button>
                </div>
            </div>
        </>
    )
}


const gqlData = gql`
    query CompanyBalances($month: Time!) {
        companyBalances(month: $month) {
            totalDeposit
            totalWithdraw
            totalMargin
            deposit {
                id
                isDeletable
                type
                memo
                amount
                createdAt
            }
            withdraw {
                id
                isDeletable
                type
                memo
                amount
                createdAt
            }
        }
    }
`

const gqlDeleteCompanyBalanceDeposit = gql`
    mutation DeleteCompanyBalanceDeposit($id: Int!) {
        deleteCompanyBalanceDeposit(id: $id)
    }
`;

const gqlDeleteCompanyBalanceWithdraw = gql`
    mutation DeleteCompanyBalanceWithdraw($id: Int!) {
        deleteCompanyBalanceWithdraw(id: $id)
    }
`

const gqlCreateCompanyBalanceDeposit = gql`
    mutation CreateCompanyBalanceDeposit($input: CreateCompanyBalanceDepositWithdraw!) {
        createCompanyBalanceDeposit(input: $input)
    }
`;

const gqlcreateCompanyBalanceWithdraw = gql`
    mutation CreateCompanyBalanceWithdraw($input: CreateCompanyBalanceDepositWithdraw!) {
        createCompanyBalanceWithdraw(input: $input)
    }
`;
import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {DashboardV1View} from "./view/DashboardV1View";

export const DashboardV1 = () => {
    const {data} = useQuery<Query>(gqlData, {
        pollInterval: 5000,
        context: {
            loadingView: false,
        }
    });

    return (
        <>
            {data &&
            <DashboardV1View
                query={data}/>
            }
        </>
    )
}

const gqlData = gql`
    query DATA {
        dashboard {
            sendSms
            chargeAmount
            readyForCharge
            restBalance
        }
        recentlySms(size: 20) {
            id
            userEmail
            contents
            count
            sendAt
        }
        dailyUsageGraph(beforeDay: 7) {
            date
            count
        }
        apiKeyList {
            hashedApiKey
            nm
            smsFee
        }
    }
`;

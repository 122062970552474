import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './DirectNoticePopupInput.module.scss';
import {DirectNoticeInput} from "../DirectNoticePopup";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {Switch} from "@material-ui/core";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const DirectNoticePopupInput = (props: {
    value: DirectNoticeInput,
    onChangeValue: OnChangeValue<DirectNoticeInput>,
    onCreateDirectNotice:  OnVoidFunction,
}) => {
    return (
        <Fragment>
            <div className={cx('table')}>
                <div style={{width: 200}}>활성화</div>
                <div className='text-left' style={{flexGrow: 1}}>
                    <Switch
                        color='primary'
                        checked={props.value.createDirectNotice.isActivate}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.createDirectNotice.isActivate = ev.target.checked;
                            props.onChangeValue(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 200}}>긴급</div>
                <div className='text-left' style={{flexGrow: 1}}>
                    <Switch
                        color='primary'
                        checked={props.value.createDirectNotice.urgent}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.createDirectNotice.urgent = ev.target.checked;
                            props.onChangeValue(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 200}}>내용</div>
                <div className='text-left' style={{flexGrow: 1}}>
                    <textarea
                        value={props.value.createDirectNotice.content}
                        className={cx('form-control', 'textarea')}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.createDirectNotice.content = ev.target.value;
                            props.onChangeValue(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '100%'}} className='text-right'>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={props.onCreateDirectNotice}>업로드</button>
                </div>
            </div>
        </Fragment>
    )
}

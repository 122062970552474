import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Query, UserUsageReport} from "../../../graphql/types";
import moment from "moment";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {ReportNowView} from "./view/ReportNowView";
import {Pipe} from "../../../utils/pipe/pipe";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {ReportPerSmsFeeList} from "../share/reportPerSmsFeeList/ReportPerSmsFeeList";


export const ReportNow = () => {
    const {data, refetch} = useQuery<Query, any>(gqlData, {
        variables: {
            date: moment().hours(9).minutes(0).seconds(0).millisecond(0).toDate(),
            stDate: Pipe.toTimeDay(new Date()),
            edDate: Pipe.toTimeDay(new Date()),
        },
        pollInterval: 5000,
        context: {
            loadingView: false,
        }
    });

    const [date, setDate] = useState(moment().hours(9).minutes(0).seconds(0).millisecond(0).toDate());

    const onChangeDate = (pickedDate: Date) => {
        const nextDate = moment(pickedDate).hours(9).minutes(0).seconds(0).millisecond(0).toDate();
        setDate(pickedDate);
        refetch({
            date: nextDate,
            stDate: Pipe.toTimeDay(nextDate),
            edDate: Pipe.toTimeDay(nextDate),
        }).catch(ApolloCatch({}));
    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <ReportNowView
                    query={data}
                    date={date}
                    onChangeDate={onChangeDate}/>

                <ReportPerSmsFeeList
                    data={data.reportPerSmsFee}/>
            </FadeInContainer>
            }
        </>
    )
}

export interface ReportNowInput {
    date: Date;
    total: UserUsageReport;
}

const gqlData = gql`
    query DailyUserUsageReport($date: Time!, $stDate: Int!, $edDate: Int!) {
        dailyUserUsageReport(date: $date) {
            userId
            userEmail
            userType
            point
            chargeAmount
            chargePoint
            taxAmount
            sentSms
            profit
        }
        reportPerSmsFee(stDate: $stDate, edDate: $edDate) {
            smsFee
            sentSms
        }
    }
`;

import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserSendCommissionList.module.scss'
import {Query} from "../../../../../graphql/types";
import moment from "moment";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Pager} from "../../../../shares/pager/Pager";
import {OnChangeValue} from "../../../../Interfaces";

const cx = classNamesBind.bind(styles);

export const UserSendCommissionList = (props: {
    query: Query,
    onChangeSendCommissionPage: OnChangeValue<number>
}) => {
    return (
        <Fragment>

            <div className={cx('table')}>
                <div style={{width: 100}}>번호</div>
                <div style={{width: 100}}>지급</div>
                <div style={{width: 200}}>지급일</div>
                <div style={{width: 'calc(100% - 700px)'}}>대리점이메일</div>
                <div style={{width: 100}}>마진</div>
                <div style={{width: 200}}>커미션</div>
            </div>

            {props.query.userSendCommissionList.total === 0 &&
            <div className={cx('table')}>
                <div style={{flexGrow: 1}}>커미션 내역이 없습니다.</div>
            </div>
            }


            {props.query.userSendCommissionList.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table')}>
                        <div style={{width: 100}}>{value.id}</div>
                        <div style={{width: 100}}>
                            {value.isWithdrawal && <span className='badge badge-success'>지급</span>}
                            {!value.isWithdrawal && <span className='badge badge-secondary'>미지급</span>}
                        </div>
                        <div style={{width: 200}}>
                            {value.withdrawalAt && moment(value.withdrawalAt).format('YY.MM.DD')}
                        </div>
                        <div style={{width: 'calc(100% - 700px)'}}>{value.childrenEmail}</div>
                        <div className='text-right' style={{width: 100}}>{value.margin}원
                            X {Pipe.toSeparatorNumber(value.count)}개
                        </div>
                        <div className='text-right' style={{width: 200}}>
                            {Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                )
            })}

            <Pager
                page={props.query.userSendCommissionList.page}
                size={props.query.userSendCommissionList.size}
                total={props.query.userSendCommissionList.total}
                onChange={props.onChangeSendCommissionPage}/>
        </Fragment>
    )
}

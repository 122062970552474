import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UsageReportList.module.scss'
import {Query, QueryUserTelBookWithPeriodArgs, UserUsageReport} from "../../../../graphql/types";
import {Pipe} from "../../../../utils/pipe/pipe";
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {SumUtil} from "../../../../utils/sumUtil/SumUtil";
import {IconButton, Popover, Tooltip} from "@material-ui/core";
import {A, navigate} from 'hookrouter';
import {CloudDownloadOutlined} from "@material-ui/icons";
import gql from "graphql-tag";
import {BizPeriodPicker, Period} from "../../../shares/bizPeriodPicker/BizPeriodPicker";
import moment from "moment";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {OnVoidFunction} from "../../../Interfaces";
import {useLazyQuery} from "@apollo/client";
import {ExcelUtil} from "../../../../utils/excel/ExcelUtil";

const cx = classNamesBind.bind(styles);
type OnDownloadTelBook = (userEmail: string, userId: number, period: Period) => void;

export const UsageReportList = (props: {
    list: UserUsageReport[]
}) => {
    const total = SumUtil.userUsageReport(props.list);
    const [getTelBook] = useLazyQuery<Query, QueryUserTelBookWithPeriodArgs>(gqlUserTelBook, {
        onCompleted: res => {
            ExcelUtil.downloadTelBook(res.userWithId.email, res.userTelBookWithPeriod);
        }
    });

    const handler = {
        onDownloadTelBook: (userEmail: string, userId: number, period: Period) => {
            getTelBook({
                variables: {
                    userId: userId,
                    stDate: Pipe.toTimeDay(period.stDate),
                    edDate: Pipe.toTimeDay(period.edDate),
                }
            });
        }
    }

    return (
        <ViewSelector
            web={<WebList
                list={props.list}
                total={total}
                {...handler}/>}
            mobile={<MobileList list={props.list} total={total}/>}/>
    )
}


const MobileList = (props: {
    list: UserUsageReport[],
    total: UserUsageReport,
}) => {
    return (
        <>
            {props.list.map((value, index) => (
                <A href={`/user/view/${value.userId}/${value.userType}`} key={index} className={cx('cont-mb')}>
                    <div className={cx('box-email')}>{value.userEmail}</div>
                    <div className={cx('box-sm')}>충전금액</div>
                    <div
                        className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(value.chargeAmount)} 원
                    </div>
                    <div className={cx('box-sm')}>부가세</div>
                    <div
                        className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(value.taxAmount)} 원
                    </div>
                    <div className={cx('box-sm')}>충전포인트</div>
                    <div
                        className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(value.chargePoint)} P
                    </div>
                    <div className={cx('box-sm')}>전송량</div>
                    <div
                        className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(value.sentSms)} 개
                    </div>
                    <div className={cx('box-sm')}>수익</div>
                    <div className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(value.profit)} 원
                    </div>
                </A>
            ))}

            {props.list.length === 0 &&
            <div className={cx('table')}>
                <div style={{width: '100%'}}>정산 내역이 없습니다.</div>
            </div>
            }

            <div className={cx('cont-mb')}>
                <div className={cx('box-email')}>합계</div>
                <div className={cx('box-sm')}>충전금액</div>
                <div
                    className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(props.total.chargeAmount)} 원
                </div>
                <div className={cx('box-sm')}>부가세</div>
                <div
                    className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(props.total.taxAmount)} 원
                </div>
                <div className={cx('box-sm')}>충전포인트</div>
                <div
                    className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(props.total.chargePoint)} P
                </div>
                <div className={cx('box-sm')}>전송량</div>
                <div
                    className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(props.total.sentSms)} 개
                </div>
                <div className={cx('box-sm')}>수익</div>
                <div
                    className={cx('box-sm', 'color-gray', 'text-right')}>{Pipe.toSeparatorNumber(props.total.profit)} 원
                </div>
            </div>

        </>
    )
}

const WebList = (props: {
    list: UserUsageReport[],
    total: UserUsageReport,
    onDownloadTelBook: OnDownloadTelBook
}) => {
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | undefined>();
    const [selUserInfo, setSelUserInfo] = useState<{ userId: number, userEmail: string }>({
        userId: 0,
        userEmail: '',
    })

    return (
        <>
            <Popover
                open={!!popoverAnchor}
                anchorEl={popoverAnchor}
                onClose={() => {
                    setPopoverAnchor(undefined)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}>
                <PopoverDownloadTelBook
                    {...selUserInfo}
                    {...props}
                    onCancel={() => {
                        setPopoverAnchor(undefined);
                    }}/>
            </Popover>


            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '16%'}}>이메일</div>
                <div className={cx('header')} style={{width: '12%'}}>다운로드</div>
                <div className={cx('header')} style={{width: '12%'}}>잔여포인트</div>
                <div className={cx('header')} style={{width: '12%'}}>충전금액</div>
                <div className={cx('header')} style={{width: '12%'}}>부가세</div>
                <div className={cx('header')} style={{width: '12%'}}>충전포인트</div>
                <div className={cx('header')} style={{width: '12%'}}>전송량</div>
                <div className={cx('header')} style={{width: '12%'}}>수익</div>
            </div>

            {props.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx('header', 'text-left')}
                             style={{
                                 width: '16%',
                                 textOverflow: 'ellipsis',
                                 overflow: 'hidden',
                                 whiteSpace: 'nowrap',
                                 cursor: 'pointer'
                             }}
                             onClick={() => {
                                 navigate(`/user/view/${value.userId}/${value.userType}`);
                             }}>
                            <Tooltip title={value.userEmail}>
                                <span>{value.userEmail}</span>
                            </Tooltip>
                        </div>
                        <div style={{width: '12%'}}>
                            <IconButton size='small' onClick={ev => {
                                setSelUserInfo({userId: value.userId, userEmail: value.userEmail});
                                setPopoverAnchor(ev.currentTarget);
                            }}>
                                <CloudDownloadOutlined/>
                            </IconButton>
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.point)} P
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.chargeAmount)} 원
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.taxAmount)} 원
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.chargePoint)} P
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.sentSms)} 개
                        </div>
                        <div className='text-right'
                             style={{width: '12%'}}>{Pipe.toSeparatorNumber(value.profit)} 원
                        </div>
                    </div>
                )
            })}


            {props.list.length === 0 &&
            <div className={cx('table')}>
                <div style={{width: '100%'}}>정산 내역이 없습니다.</div>
            </div>
            }

            <div className={cx('table')}>
                <div style={{width: '40%'}}>통계</div>
                <div className='text-right'
                     style={{width: '12%'}}>{Pipe.toSeparatorNumber(props.total.chargeAmount)} 원
                </div>
                <div className='text-right'
                     style={{width: '12%'}}>{Pipe.toSeparatorNumber(props.total.taxAmount)} 원
                </div>
                <div className='text-right'
                     style={{width: '12%'}}>{Pipe.toSeparatorNumber(props.total.chargePoint)} P
                </div>
                <div className='text-right'
                     style={{width: '12%'}}>{Pipe.toSeparatorNumber(props.total.sentSms)} 개
                </div>
                <div className='text-right'
                     style={{width: '12%'}}>{Pipe.toSeparatorNumber(props.total.profit)} 원
                </div>
            </div>
        </>
    )
}

const PopoverDownloadTelBook = (props: {
    userId: number,
    userEmail: string,
    onCancel: OnVoidFunction,
    onDownloadTelBook: OnDownloadTelBook,
}) => {
    const [period, setPeriod] = useState<Period>({
        stDate: moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate(),
        edDate: moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate(),
    });

    const handler = {
        onClickPeriod: (day: number) => {
            const stDate = moment().days(-day).toDate();
            setPeriod({
                stDate, edDate: new Date(),
            })
        },
        onClickDownload: () => {
            if (30 < moment(period.edDate).diff(period.stDate, 'd')) {
                SwalUtil.ok({
                    msg: '30일 보다 더 많은 날자를 다운로드 할 수 없습니다.',
                    icon: 'error'
                });
                props.onCancel();
                return;
            }

            props.onDownloadTelBook(props.userEmail, props.userId, period);
            props.onCancel();
        }
    }


    return (
        <div className={cx('cont-popover')}>
            <div className={cx('email')}>전화번호 다운로드 ({props.userEmail})</div>
            <BizPeriodPicker
                period={period}
                onChange={setPeriod}
                onError={props.onCancel}/>
            <div className={cx('box-period')}>
                <div className={cx('label')}>오늘로부터</div>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={() => {
                        handler.onClickPeriod(0);
                    }}>1 일
                </button>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={() => {
                        handler.onClickPeriod(6);
                    }}>7 일
                </button>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={() => {
                        handler.onClickPeriod(14);
                    }}>15 일
                </button>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={() => {
                        handler.onClickPeriod(29);
                    }}>30 일
                </button>
            </div>


            <div className={cx('box-btn')}>
                <div className={cx('warning')}>
                    * 전화번호가 많을시 로딩화면 이후로 잠시 대기 후 다운로드 됩니다.
                </div>
                <div className={cx('btns')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onClickDownload}>저장
                    </button>
                </div>

            </div>
        </div>
    )
}

const gqlUserTelBook = gql`
    query UserTelBookWithPeriod($userId: Int!, $stDate: Int!, $edDate: Int!) {
        userWithId(id: $userId) {
            email
        }
        userTelBookWithPeriod(userId: $userId, stDate: $stDate, edDate: $edDate) {
            number
            status
        }
    }
`

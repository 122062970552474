import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DashboardViewRecentlySend.module.scss'
import moment from "moment";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Query} from "../../../../../graphql/types";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

export const DashboardViewRecentlySend = (props: {
    query: Query
}) => {
    return (
        <ViewSelector
            web={<WebList {...props}/>}
            mobile={<MobileList {...props}/>}/>

    )
}

const MobileList = (props: {
    query: Query
}) => {
    return (
        <>
            {props.query.recentlySms.map((value, index) => (
                <div key={index} className={cx('table')}>
                    <div style={{width: '100%'}}>
                        <div className={cx('cont-mb')}>
                            <div className={cx('box-email')}>{value.userEmail}</div>
                            <div className={cx('box-sent-count')}>{Pipe.toSeparatorNumber(value.count)} 개</div>
                            <div className={cx('box-content')} style={{whiteSpace: 'normal'}}>{value.contents}</div>
                            <div className={cx('box-date')}>{moment(value.sendAt).format('YY.MM.DD - hh:mm a')}</div>

                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

const WebList = (props: {
    query: Query
}) => {
    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '10%'}}>번호</div>
                <div className={cx('header')} style={{width: '20%'}}>전송시각</div>
                <div className={cx('header')} style={{width: '20%'}}>이메일</div>
                <div className={cx('header')} style={{width: '10%'}}>갯수</div>
                <div className={cx('header')} style={{width: '40%'}}>내용</div>
            </div>

            {props.query.recentlySms.map((value, index) => {
                return (
                    <div className={cx('table', 'hover')} key={index}>
                        <div style={{width: '10%'}}>{value.id}</div>
                        <div style={{width: '20%'}}>{moment(value.sendAt).format('YY.MM.DD - hh:mm a')}</div>
                        <div style={{width: '20%'}}>{value.userEmail}</div>
                        <div className='text-right'
                             style={{width: '10%'}}>{Pipe.toSeparatorNumber(value.count)} 개
                        </div>
                        <div className='text-left'
                             style={{width: '40%', whiteSpace: 'normal'}}
                             ref={ref => {
                                 if (ref) {
                                     ref.innerText = value.contents
                                 }
                             }}/>
                    </div>
                )
            })}
        </>
    )
}

import React, {useState} from 'react';
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {FB} from "../../../services/fb/FB";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {LoadingViewUtil} from "../../App";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {AuthJoinView} from "./view/AuthJoinView";
import {Mutation, MutationJoinArgs} from "../../../graphql/types";

export const AuthJoin = () => {
    const [value, setValue] = useState<AuthJoinInput>({
        email: "",
        pw: "",
        confirm: "",
    });
    const [mutJoin] = useMutation<Mutation, MutationJoinArgs>(gqlJoin);

    const handler = {
        onChangeValue: setValue,
        onJoin: () => {
            if (value.confirm != value.pw) {
                SwalUtil.ok({
                    msg: "두 비밀번호가 다릅니다.",
                    icon: "error"
                });
                return;
            }

            LoadingViewUtil.loading(
                FB.join(value)
                    .then(uid => {
                        return mutJoin({variables: {uid}})
                    })
                    .then(res => {
                        SwalUtil.ok({
                            msg: "회원가입 되었습니다.",
                            icon: "success"
                        });
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            SwalUtil.error();
                        }
                    }))
            );


        }
    }

    return (
        <AuthJoinView
            value={value}
            {...handler}/>
    )
}

const gqlJoin = gql`
    mutation Join($uid: String!) {
        join(uid: $uid)
    }
`

export interface AuthJoinInput {
    email: string;
    pw: string;
    confirm: string;
}

export interface AuthJoinFunc {
    setState: (state: AuthJoinInput) => void;
    join: () => void;
}

import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./PointRechargeCouponList.module.scss";
import {MutationRequestRechargeRequestArgs, Query} from "../../../../graphql/types";
import {Pipe} from "../../../../utils/pipe/pipe";
import Decimal from "decimal.js";
import {OnChangeValue} from "../../../Interfaces";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

interface Props {
    query: Query,
    onRequestRechargeRequest: OnChangeValue<MutationRequestRechargeRequestArgs>,
}

export const PointRechargeCouponList = (props: Props) => {
    const list = props.query.clientRechargeCoupon;

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "valueCell")}>SMS 건수</div>
                    <div className={cx("header", "priceCell")}>건당 가격</div>
                    <div className={cx("header", "pointCell")}>충전 포인트</div>
                    <div className={cx("header", "commissionCell")}>수수료</div>
                    <div className={cx("header", "depositCell")}>입금금액</div>
                    <div className={cx("header", "funcCell")}>요청</div>
                </div>

                {list.length !== 0 &&
                list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("valueCell", "textRight")}>{Pipe.toSeparatorNumber(value.smsCount)} 건
                        </div>
                        <div className={cx("priceCell", "textRight")}>{Pipe.toSeparatorNumber(value.smsFee)} 원
                        </div>
                        <div
                            className={cx("pointCell", "textRight")}>{Pipe.toSeparatorNumber(new Decimal(value.smsCount).mul(value.smsFee).toNumber())} P
                        </div>
                        <div
                            className={cx("commissionCell", "textRight")}>{new Decimal(value.taxRate).mul(100).toNumber()} %
                        </div>
                        <div className={cx("depositCell", "textRight")}>{Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                        <div className={cx("funcCell")}>
                            <button
                                className='btn btn-sm btn-outline-success'
                                onClick={() => {
                                    SwalUtil.otp(otp => {
                                        props.onRequestRechargeRequest({
                                            clientRechargeCouponId: value.id,
                                            otp,
                                        })
                                    })
                                }}>충전요청
                            </button>
                        </div>
                    </div>
                ))}

                {list.length === 0 && <BizNoDataTable/>}
            </ScrollArea>
        </>
    )
}

import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationDeleteTestMsgReportArgs, Query, QueryTestMsgReportArgs} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {Title} from "../../shares/title/Title";
import {TelReportV1List} from "./list/TelReportV1List";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";

export const TelReportV1 = (props: {
    page: number,
}) => {
    const {data, refetch} = useQuery<Query, QueryTestMsgReportArgs>(gqlData, {
        variables: {
            page: props.page,
            size: 20
        },
        pollInterval: 5000,
        context: {
            loadingView: false,
        }
    });

    const [mutDeleteAll] = useMutation<Mutation>(gqlDeleteAll);
    const [mutDeleteTestMsgReport] = useMutation<Mutation, MutationDeleteTestMsgReportArgs>(gqlDeleteTestMsgReport);

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/tel-report/${page}`);
        },
        onDeleteAll: () => {
            SwalUtil.yn({
                msg: '모두 삭제하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDeleteAll()
                        .then(() => {
                            return refetch({
                                page: props.page,
                                size: 20
                            })
                        })
                        .catch(ApolloCatch({}));
                }
            })
        },
        onDeleteTestMsgReport: (args: MutationDeleteTestMsgReportArgs) => {
            SwalUtil.yn({
                msg: '삭제 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDeleteTestMsgReport({
                        variables: args
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '삭제 되었습니다.',
                            icon: 'success'
                        });
                        return refetch({
                            page: props.page,
                            size: 20
                        })
                    }).catch(ApolloCatch({}));
                }
            })

        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>텔레그램 메시지 리스트</Title>
                    <div className='text-right' style={{marginBottom: 10}}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={handler.onDeleteAll}>모두삭제
                        </button>
                    </div>
                    <TelReportV1List
                        list={data.testMsgReport.list}
                        {...handler}/>
                    <Pager
                        page={data.testMsgReport.page}
                        size={data.testMsgReport.size}
                        total={data.testMsgReport.total}
                        onChange={handler.onChangePage}/>
                </BizCol12>


            </FadeInContainer>}

        </>
    )
}


const gqlData = gql`
    query DATA($page: Int!, $size: Int!) {
        testMsgReport(page: $page, size: $size) {
            page
            size
            total
            list {
                id
                senderNumber
                receiverNumber
                msg
                vendor
                createdAt
            }
        }
    }
`;

const gqlDeleteAll = gql`
    mutation DeleteAll {
        deleteAllTestMsgReport
    }
`;

const gqlDeleteTestMsgReport = gql`
    mutation DeleteTestMsgReport($idList: [Int!]!) {
        deleteTestMsgReport(idList: $idList)
    }
`;

import React, {ReactNode} from "react";
import {BoxCont} from "../boxCont/BoxCont";

export const BizCol12 = (props: {
    children?: ReactNode
}) => {
    return (
        <div className='col-12' style={{marginBottom: 30}}>
            <BoxCont>
                {props.children}
            </BoxCont>
        </div>
    )
}


export const BizCol = (props: {
    colClass: string
    children?: ReactNode
}) => {
    return (
        <div className={props.colClass} style={{marginBottom: 30}}>
            <BoxCont>
                {props.children}
            </BoxCont>
        </div>
    )
}

import React, {useEffect} from 'react';
import {gql, useQuery, useReactiveVar} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {GlobalVars} from "../../../globalVars/globalVars";
import msgAlert from '../../../assets/mp3/msgAlert.mp3';
import depositAlert from '../../../assets/mp3/depositAlert.mp3';
import settle from '../../../assets/mp3/settle.mp3';
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {CopyUtil} from "../../../utils/copy/CopyUtil";

export const AlertCenter = () => {
    const {data} = useQuery<Query>(gqlData, {
        context: {
            loadingView: false,
        },
        pollInterval: 5000
    });

    const chatAlert = useReactiveVar(GlobalVars.alert.chatAlert);
    const depositRequestAlert = useReactiveVar(GlobalVars.alert.depositRequest);
    const settleCommissionAlert =  useReactiveVar(GlobalVars.alert.settleCommissionAlert);

    useEffect(() => {
        if (!data) {
            return;
        }


        // 채팅알림
        let hasChatAlert = false;
        for (let alert of data.chatAlert) {
            const existAlert = chatAlert.find(value => {
                return alert.userId === value.userId
            });

            if (existAlert) {
                continue;
            }

            hasChatAlert = true;
            break;
        }

        if (hasChatAlert && LocalStorage.getAlertSound()) {
            const beep = new Audio(msgAlert);
            beep.play().then();
        }

        // 입금확인요청 알림
        let hasDepositAlert = false;
        for (let alert of data.depositRequestAlert) {
            const existAlert = depositRequestAlert.find(value => {
                return alert.id === value.id
            });

            if (existAlert) {
                continue;
            }

            hasDepositAlert = true;
            break;
        }

        if (hasDepositAlert && LocalStorage.getAlertSound()) {
            const beep = new Audio(depositAlert);
            beep.play().then();
        }

        // 커미션 정산요청 알림
        let hasSettleAlert = false ;
        for (let alert of data.settleCommissionAlert) {
            const existAlert = settleCommissionAlert.find(value => {
                return alert.userId === value.userId
            });

            if (existAlert) {
                continue;
            }

            hasSettleAlert = true;
            break;
        }

        if (hasSettleAlert && LocalStorage.getAlertSound()) {
            const beep = new Audio(settle);
            beep.play().then();
        }


        // 알림 업데이트
        GlobalVars.alert.chatAlert(CopyUtil.copyAll(data.chatAlert));
        GlobalVars.alert.depositRequest(CopyUtil.copyAll(data.depositRequestAlert));
        GlobalVars.alert.settleCommissionAlert(CopyUtil.copyAll(data.settleCommissionAlert))
    }, [data])

    return (
        <></>
    )
}


const gqlData = gql`
    query DATA {
        chatAlert {
            userId
            userEmail
            chatCount
            createdAt
        }
        depositRequestAlert {
            id
            userEmail
            nm
            tel
            amount
            createdAt
        }
        settleCommissionAlert {
            userId
            userEmail
            settleAlertType
            settlePoint
            createdAt
        }
    }
`;

import * as firebase from 'firebase';
import {SwalUtil} from "../../utils/swal/swalUtil";

export class FB {
    static initFB(options: string) {
        const obj = JSON.parse(options);
        firebase.initializeApp(obj);
    }

    static join(params: {
        email: string,
        pw: string,
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(params.email, params.pw)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        reject();
                        return;
                    }
                    resolve(user.uid)
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 이메일 입니다.',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }

    static login(params: {
        email: string,
        pw: string,
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(params.email, params.pw)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        resolve();
                        return;
                    }
                    resolve(user.uid)
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 이메일 입니다.',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }
}

import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserDefView.module.scss'
import {
    IUpdateUserPassword,
    MsgPermission,
    UpdateUser,
    User,
    UserCategory,
    UserHistory
} from "../../../../../graphql/types";
import {Title} from "../../../../shares/title/Title";
import {Pipe} from "../../../../../utils/pipe/pipe";
import moment from "moment";
import {BadgeUserType} from "../../../../shares/badgeUserType/BadgeUserType";
import NumberFormat from "react-number-format";
import {ModalWeb} from "../../../../shares/modal/ModalWeb";
import {Switch, TextField} from "@material-ui/core";
import {BadgeIsActivate} from "../../../../shares/badgeIsActivate/BadgeIsActivate";
import {OnChangeValue} from "../../../../Interfaces";
import {BadgeMsgPermission} from "../../../../shares/badgeMsgPermission/BadgeMsgPermission";
import {Decimal} from "decimal.js";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

interface Props {
    user: User,
    userCategory: UserCategory[],
    userHistory: UserHistory,
    onUpdateUser: OnChangeValue<UpdateUser>
    onUpdatePassword: OnChangeValue<IUpdateUserPassword>
}

export const UserDefView = (props: Props) => {
    const [userUpdateModal, setUserUpdateModal] = useState(false);
    const [userUpdatePasswordModal, setUserUpdatePasswordModal] = useState<boolean>(false);

    const onUpdateUser = (input: UpdateUser) => {
        setUserUpdateModal(false);
        props.onUpdateUser(input);
    }

    const onUpdatePassword = (input: IUpdateUserPassword) => {
        setUserUpdatePasswordModal(false);
        props.onUpdatePassword(input);
    }

    return (
        <Fragment>
            <ModalWeb
                title={'유저 정보'}
                isShow={userUpdateModal}
                onCancel={() => {
                    setUserUpdateModal(false);
                }}
                onOk={() => {
                    setUserUpdateModal(false);
                }}>
                <UserUpdateModal
                    {...props}
                    onUpdateUser={onUpdateUser}/>
            </ModalWeb>

            <ModalWeb
                title={'비밀번호 변경'}
                isShow={userUpdatePasswordModal}
                onCancel={() => {
                    setUserUpdatePasswordModal(false);
                }}
                onOk={() => {
                    setUserUpdatePasswordModal(false);
                }}>
                <UserChangePasswordModal {...props} onUpdatePassword={onUpdatePassword}/>
            </ModalWeb>


            <Title>유저정보</Title>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}
            />

            <div className={cx('table')}>
                <div className='d-flex justify-content-end w-100' style={{gap: "1rem"}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={event => {
                            setUserUpdatePasswordModal(true);
                        }}>비밀번호변경
                    </button>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={event => {
                            setUserUpdateModal(true);
                        }}>수정
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '15%'}}>유저</div>
                <div style={{width: '35%'}}>{props.user.email}</div>
                <div style={{width: '15%'}}>등급</div>
                <div style={{width: '35%'}}>
                    <BadgeUserType type={props.user.userType}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: '15%'}}>상태</div>
                <div style={{width: '35%'}}>
                    {props.user.isActivate && <span className='badge badge-success'>정상</span>}
                    {!props.user.isActivate && <span className='badge badge-danger'>차단</span>}
                </div>
                <div style={{width: '15%'}}>잔고</div>
                <div style={{width: '35%'}}>{Pipe.toSeparatorNumber(props.user.point)} P</div>
            </div>
            <div className={cx('table')}>
                <div style={{width: '15%'}}>가입일</div>
                <div style={{width: '35%'}}>{moment(props.user.createdAt).format('YY.MM.DD hh:mm a')}</div>
                <div style={{width: '15%'}}>부가세</div>
                <div style={{width: '35%'}}>{new Decimal(props.user.tax).times(100).toNumber()} %</div>
            </div>
            <div className={cx('table')}>
                <div style={{width: '15%'}}>문자</div>
                <div style={{width: '35%'}}>
                    {props.user.msgPermission.map((value, index) => {
                        return <BadgeMsgPermission msgPermission={value} key={index}/>
                    })}
                </div>
                <div style={{width: '15%'}}>분류</div>
                <div style={{width: '35%'}}>
                    {props.user.categoryNm}
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '15%'}}>연락처</div>
                <div
                    className='text-left'
                    style={{width: '35%'}}>{props.user.contact}</div>
                <div style={{width: '15%'}}>최근로그인</div>
                <div
                    style={{width: '35%'}}>{props.userHistory.id !== 0 ? moment(props.userHistory.createdAt).format('YY.MM.DD hh:mm a') : "최근접속없음"}</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '15%'}}>메모</div>
                <div
                    className='text-left'
                    style={{width: '85%'}}
                    ref={ref => {
                        if (ref) {
                            ref.innerText = props.user.memo
                        }
                    }}/>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '20%'}}>유저</div>
                <div style={{width: '80%'}}>{props.user.email}</div>

            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>등급</div>
                <div style={{width: '80%'}}>
                    <BadgeUserType type={props.user.userType}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>상태</div>
                <div style={{width: '80%'}}>
                    {props.user.isActivate && <span className='badge badge-success'>정상</span>}
                    {!props.user.isActivate && <span className='badge badge-danger'>차단</span>}
                </div>

            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>잔고</div>
                <div style={{width: '80%'}}>{Pipe.toSeparatorNumber(props.user.point)} P</div>
            </div>


            <div className={cx('table')}>
                <div style={{width: '20%'}}>가입일</div>
                <div style={{width: '80%'}}>{moment(props.user.createdAt).format('YY.MM.DD hh:mm a')}</div>

            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>부가세</div>
                <div style={{width: '80%'}}>{new Decimal(props.user.tax).times(100).toNumber()} %</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>회원 분류</div>
                <div style={{width: '80%'}}>{props.user.categoryNm}</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>문자</div>
                <div style={{width: '80%'}}>
                    {props.user.msgPermission.map((value, index) => {
                        return <BadgeMsgPermission msgPermission={value} key={index}/>
                    })}
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>연락처</div>
                <div style={{width: '80%'}}>{props.user.contact}</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>메모</div>
                <div
                    style={{width: '80%'}}
                    ref={ref => {
                        if (ref) {
                            ref.innerText = props.user.memo
                        }
                    }}/>
            </div>
        </>
    )
}

const UserUpdateModal = (props: {
    user: User,
    userCategory: UserCategory[],
    onUpdateUser: OnChangeValue<UpdateUser>
}) => {
    const [memo, setMemo] = useState("");
    const [tax, setTax] = useState(0);
    const [isActivate, setIsActivate] = useState(false);
    const [bulk, setBulk] = useState(false);
    const [template, setTemplate] = useState(false);
    const [categoryId, setCategoryId] = useState(1);

    useEffect(() => {
        setMemo(props.user.memo);
        setTax(props.user.tax);
        setIsActivate(props.user.isActivate);
        setBulk(props.user.msgPermission.includes(MsgPermission.Bulk));
        setTemplate(props.user.msgPermission.includes(MsgPermission.Template));
        setCategoryId(props.user.categoryId);
    }, [props.user]);

    const onUpdateUser = () => {
        const msgPermission: MsgPermission[] = [];
        if (bulk) {
            msgPermission.push(MsgPermission.Bulk);

        }
        if (template) {
            msgPermission.push(MsgPermission.Template);
        }

        props.onUpdateUser({
            memo, tax, isActivate, userId: props.user.id, msgPermission, categoryId,
        })
    }

    return (
        <Fragment>
            <div className={cx('table')}>
                <div style={{width: 150}}>활성화</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <BadgeIsActivate isActivate={isActivate}/>
                    <Switch
                        color='primary'
                        checked={isActivate}
                        onChange={(event, checked) => {
                            setIsActivate(checked)
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 150}}>대량전송</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <BadgeIsActivate isActivate={bulk}/>
                    <Switch
                        color='primary'
                        checked={bulk}
                        onChange={(event, checked) => {
                            setBulk(checked);
                        }}/>
                </div>
            </div>

            {/* <div className={cx('table')}>
                <div style={{width: 150}}>템플릿전송</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <BadgeIsActivate isActivate={template}/>
                    <Switch
                        color='primary'
                        checked={template}
                        onChange={(event, checked) => {
                            setTemplate(checked);
                        }}/>
                </div>
            </div>*/}

            <div className={cx('table')}>
                <div style={{width: 150}}>부가세</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <NumberFormat
                        className='form-control text-center'
                        thousandSeparator
                        allowNegative={false}
                        decimalScale={0}
                        value={new Decimal(tax).times(100).toNumber()}
                        suffix={' %'}
                        onValueChange={values => {
                            setTax(new Decimal(values.floatValue || 0).times(0.01).toNumber());
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 150}}>분류</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <select
                        value={String(categoryId)}
                        onChange={ev => {
                            setCategoryId(Number(ev.target.value));
                        }}
                        className='form-control form-control-sm'>
                        {props.userCategory.map((value, index) => (
                            <option key={index} value={value.id}>{value.nm}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 150}}>메모</div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <textarea
                        style={{resize: 'none', height: 150}}
                        className='form-control text-left'
                        value={memo}
                        onChange={event => {
                            setMemo(event.target.value)
                        }}/>
                </div>
            </div>


            <div className={cx('table')}>
                <div className='text-left' style={{flexGrow: 1}}>
                </div>
                <div className='text-right' style={{width: 100}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onUpdateUser}>저장
                    </button>
                </div>
            </div>

        </Fragment>

    )
}

const UserChangePasswordModal = (props: {
    user: User,
    onUpdatePassword: OnChangeValue<IUpdateUserPassword>
}) => {
    const [password, setPassword] = useState<string>("");

    const handler = {
        onUpdatePassword: () => {
            props.onUpdatePassword({
                userId: props.user.id,
                password: password
            });
        }
    }

    return (
        <>
            <div className={cx('table')}>
                <div style={{
                    width: 150,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>변경할 비밀번호
                </div>
                <div style={{width: 'calc(100% - 150px)'}}>
                    <TextField
                        fullWidth
                        size={"small"}
                        type={"password"}
                        margin={"none"}
                        variant={"outlined"}
                        value={password}
                        onChange={value => {
                            console.log(value.currentTarget.value);
                            setPassword(value.currentTarget.value);
                        }}
                        InputProps={{
                            style: {margin: 0, padding: 0}
                        }}
                    />
                </div>
            </div>

            <div style={{color: "red", margin: "5px 30px 20px", fontSize: "0.8rem"}}>
                * 비밀번호는 최소 6자 이상이어야 합니다.<br/>
                * 변경 후에는 되돌릴 수 없습니다. 만약 비밀번호를 잘못 설정했다면 다시 변경하셔야 합니다.
            </div>

            <div className={cx('table')}>
                <div className='text-left' style={{flexGrow: 1}}>
                </div>
                <div className='text-right' style={{width: 100}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onUpdatePassword}>저장
                    </button>
                </div>
            </div>
        </>

    )
}
import {Apollo} from "../apollo/apollo";
import gql from "graphql-tag";
import {ReduxStore} from "../../index";
import {UserAction} from "../../redux/User";
import {Admin, Init, UploadedFile} from "../../graphql/types";
import {AppStatus, SystemAction} from "../../redux/System";
import {FB} from "../../services/fb/FB";
import {ApolloCatch} from "../apollo/apolloCatch";
import {BizErrors} from "../../graphql/errors";
import {LocalStorage} from "../localstorage/LocalStorage";
import {ApolloError} from "@apollo/client";
import {GraphQLError} from "graphql";

export class DataUtil {
    static refreshSession(): Promise<Admin> {
        return Apollo.client.mutate<{ session: Admin }>({
            mutation: gql`
                mutation Session {
                    session {
                        id
                        email
                    }
                }
            `
        }).then(res => {
            ReduxStore.dispatch(UserAction.login(res.data!.session))
            return res.data!.session;
        });
    }

    static init() {
        Apollo.client.query<{ init: Init }>({
            query: gql`
                query Init {
                    init {
                        fb
                        appNm
                    }
                }
            `
        })
        .then(res => {
            ReduxStore.dispatch(SystemAction.setInit(res.data.init));
            FB.initFB(res.data.init.fb);
            ReduxStore.dispatch(SystemAction.setAppStatus(AppStatus.Ok));
            document.title = res.data.init.appNm + ' Admin';
            return this.refreshSession()
        })
        .catch(ApolloCatch({
            [BizErrors.default]: () => {
                ReduxStore.dispatch(SystemAction.setAppStatus(AppStatus.NotAuth));
            },
            [BizErrors.needLogin]: () => {
                LocalStorage.clearSession();
            }
        }))
    }

    static uploadBoardFile(): Promise<UploadedFile[]> {
        return new Promise<UploadedFile[]>((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('hidden', 'true');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.setAttribute('multiple', 'false');

            input.click();
            input.onchange = () => {
                if (!input.files) {
                    return
                }

                const list: File[] = [];
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files[i];
                    if (!file) {
                        continue;
                    }

                    if (5000000 < file.size) {
                        reject(new ApolloError({
                            graphQLErrors: [
                                new GraphQLError(BizErrors.overMaxSize)
                            ]
                        }))
                        return;
                    }

                    list.push(file)
                }


                Apollo.client.mutate<{ uploadBoardFile: UploadedFile[] }, { files: File[] }>({
                    mutation: gql`
                        mutation UploadBoardFile($files: [Upload!]!) {
                            uploadBoardFile(files: $files) {
                                id
                                nm
                                src
                            }
                        }
                    `,
                    variables: {files: list}
                }).then(res => {
                    resolve(res.data!.uploadBoardFile);
                }).catch(reason => {
                    reject(reason);
                })
            }
        })
    }
}

import React from "react";
import classNamesBind from "classnames/bind";
import styles from './ReportNowView.module.scss'
import {Query} from "../../../../graphql/types";
import {Title} from "../../../shares/title/Title";
import {BizDatePicker} from "../../../shares/bizDatePicker/BizDatePicker";
import {OnChangeValue} from "../../../Interfaces";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {UsageReportList} from "../../share/usageReportList/UsageReportList";

const cx = classNamesBind.bind(styles);

export const ReportNowView = (props: {
    query: Query,
    date: Date,
    onChangeDate: OnChangeValue<Date>
}) => {
    return (

        <BizCol12>
            <div className={cx('box-title')}>
                <div>
                    <Title>일일 정산</Title>
                </div>
                <div className='text-right' style={{flexGrow: 1}}>
                    <BizDatePicker
                        date={props.date}
                        onChange={props.onChangeDate}/>
                </div>
            </div>
            <UsageReportList list={props.query.dailyUserUsageReport}/>
        </BizCol12>
    )
}

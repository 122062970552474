import React, {Fragment, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserViewReseller.module.scss'
import {UserViewGeneral} from "../general/UserViewGeneral";
import gql from "graphql-tag";
import {Title} from "../../../shares/title/Title";
import {useMutation, useQuery} from "@apollo/client";
import {
    Mutation, MutationUpdateUserCommissionPermissionArgs, MutationWithdrawAllRechargeCommissionArgs,
    MutationWithdrawAllSendCommissionArgs, MutationWithdrawRechargeCommissionArgs, MutationWithdrawSendCommissionArgs,
    Query,
} from "../../../../graphql/types";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {UserSendCommission} from "../share/userSendCommission/UserSendCommission";
import {
    UserSendCommissionList as UserSendCommissionListView
} from '../share/userSendCommissionList/UserSendCommissionList';
import {
    UserRechargeCommissionList as UserRechargeCommissionListView
} from '../share/userRechargeCommissionList/UserRechargeCommissionList';
import {UserRechargeCommission} from "../share/userRechargeComission/UserRechargeCommission";
import {UserTotalCommission} from "../share/userTotalCommission/UserTotalCommission";
import {UserAgentList} from "../share/userAgentList/UserAgentList";


const cx = classNamesBind.bind(styles);

export const UserViewReseller = (props: {
    id: number
}) => {
    const {data, refetch} = useQuery<Query, {
        resellerId: number,
        agentPage: number,
        rechargePage: number,
        sendPage: number
    }>(gqlData, {
        variables: {
            resellerId: props.id,
            agentPage: 0,
            rechargePage: 0,
            sendPage: 0,
        }
    });

    const [loadCommission, setLoadCommission] = useState(false);


    const [mutWithdrawAllSendCommission] =
        useMutation<Mutation, MutationWithdrawAllSendCommissionArgs>(gqlWithdrawAllSendCommission);

    const [mutWithdrawAllRechargeCommission] =
        useMutation<Mutation, MutationWithdrawAllRechargeCommissionArgs>(gqlWithdrawAllRechargeCommission);

    const [mutWithdrawSendCommission] =
        useMutation<Mutation, MutationWithdrawSendCommissionArgs>(gqlWithdrawSendCommission);

    const [mutWithdrawRechargeCommission] =
        useMutation<Mutation, MutationWithdrawRechargeCommissionArgs>(gqlWithdrawRechargeCommission);

    const [mutUpdateUserCommissionPermission] =
        useMutation<Mutation, MutationUpdateUserCommissionPermissionArgs>(gqlUpdateUserCommissionPermission);

    const onInit = () => {
        refetch({
            resellerId: props.id,
            agentPage: 0,
            rechargePage: 0,
            sendPage: 0,
        }).catch(ApolloCatch({}));
    }

    const onChangeAgentPage = (page: number) => {
        if (data) {
            refetch({
                resellerId: Number(props.id),
                agentPage: page,
                rechargePage: data.userRechargeCommissionList.page,
                sendPage: data.userSendCommissionList.page,
            }).catch(ApolloCatch({}));
        }

    }

    const onChangePageRecharge = (page: number) => {
        if (data) {
            refetch({
                resellerId: Number(props.id),
                agentPage: data.userChildren.page,
                rechargePage: page,
                sendPage: data.userSendCommissionList.page,
            }).catch(ApolloCatch({}));
        }
    }

    const onChangePageSend = (page: number) => {
        if (data) {
            refetch({
                resellerId: Number(props.id),
                agentPage: data.userChildren.page,
                rechargePage: data.userRechargeCommissionList.page,
                sendPage: page,
            }).catch(ApolloCatch({}));
        }
    }

    const onWithdrawAllRechargeCommission = () => {
        SwalUtil.otp(otp => {
            mutWithdrawAllRechargeCommission({
                variables: {
                    id: Number(props.id),
                    otp
                }
            }).then(res => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });
                onInit()
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        })
    }

    const handler = {
        onWithdrawAllSendCommission: (args: MutationWithdrawAllSendCommissionArgs) => {
            mutWithdrawAllSendCommission({
                variables: args
            }).then(res => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                    sendPage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
        onWithdrawSendCommission: (args: MutationWithdrawSendCommissionArgs) => {
            mutWithdrawSendCommission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                    sendPage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
        onWithdrawAllRechargeCommission: (args: MutationWithdrawAllRechargeCommissionArgs) => {
            mutWithdrawAllRechargeCommission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                    sendPage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
        onWithdrawRechargeCommission: (args: MutationWithdrawRechargeCommissionArgs) => {
            mutWithdrawRechargeCommission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '지급처리 되었습니다.',
                    icon: 'success'
                });

                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                    sendPage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        },
        onUpdateUserCommissionPermission: (args: MutationUpdateUserCommissionPermissionArgs) => {
            mutUpdateUserCommissionPermission({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: '변경 되었습니다.',
                    icon: "success"
                });
                return refetch({
                    resellerId: props.id,
                    agentPage: 0,
                    rechargePage: 0,
                    sendPage: 0,
                });
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                    msg: 'OTP 번호가 틀렸습니다.',
                    icon: 'error'
                })
            }))
        }
    }

    return (
        <UserViewGeneral
            id={props.id}
            onReloaded={onInit}>
            {data &&
                <Fragment>
                    <BizCol12>
                        <Title>대리점 정보</Title>
                        <UserAgentList
                            query={data}
                            onChangeAgentPage={onChangeAgentPage}/>
                    </BizCol12>

                    <BizCol12>
                        <UserTotalCommission
                            userId={props.id}
                            userCommission={data.userCommission}
                            {...handler}/>
                    </BizCol12>

                    {data.userCommission.hasSendCommission &&
                        <>
                            <BizCol12>
                                <Title>전송 커미션 지급</Title>
                                <UserSendCommission
                                    userId={props.id}
                                    query={data}
                                    {...handler}/>
                            </BizCol12>


                            <BizCol12>
                                <Title>전송 커미션 목록</Title>
                                <UserSendCommissionListView
                                    query={data}
                                    onChangeSendCommissionPage={onChangePageSend}/>
                            </BizCol12>
                        </>
                    }

                    {0 < data.userCommission.rechargeCommission &&
                        <>
                            <BizCol12>
                                <Title>충전 커미션 지급</Title>
                                <UserRechargeCommission
                                    userId={props.id}
                                    query={data}
                                    {...handler}/>
                            </BizCol12>
                            <BizCol12>
                                <Title>충전 커미션 목록</Title>
                                <UserRechargeCommissionListView
                                    query={data}
                                    onChangeRechargeCommissionPage={onChangePageRecharge}/>
                            </BizCol12>
                        </>
                    }
                </Fragment>
            }


        </UserViewGeneral>
    )
}


const gqlData = gql`
    query Reseller($resellerId: Int!, $agentPage: Int!, $rechargePage: Int!, $sendPage: Int!) {
        userChildren(userId: $resellerId, page: $agentPage, size: 10) {
            page
            size
            total
            user {
                id
                email
                point
                tax
                userType
                createdAt
                apiPermission {
                    hashedApiKey
                    nm
                    smsFee
                }
            }
        }
        userCommission(id: $resellerId) {
            commission
            paid
            notPaid
            rechargeCommission
            hasSendCommission
            createdAt
        }
        userRechargeCommissionList(id: $resellerId, page: $rechargePage, size: 10) {
            page
            size
            total
            list {
                id
                childrenEmail
                rate
                commission
                isWithdrawal
                withdrawalAt
                chargePoint
                chargeAmount
            }
        }
        userSendCommissionList(id: $resellerId, page: $sendPage, size: 10) {
            page
            size
            total
            list {
                id
                childrenEmail
                margin
                count
                commission
                isWithdrawal
                withdrawalAt
            }
        }
        userRechargeCommission(userId: $resellerId) {
            id
            childrenEmail
            rate
            commission
            isWithdrawal
            withdrawalAt
            chargeAmount
            chargePoint
        }
        userSendCommission(userId: $resellerId) {
            id
            childrenEmail
            margin
            count
            commission
            isWithdrawal
            withdrawalAt
        }
    }
`;

const gqlUpdateUserCommissionPermission = gql`
    mutation updateUserCommissionPermission($input: UpdateUserCommissionPermission!) {
        updateUserCommissionPermission(input: $input)
    }
`;

const gqlWithdrawAllSendCommission = gql`
    mutation WithdrawalAllSendCommission($id: Int!, $otp: String!) {
        withdrawAllSendCommission(id: $id, otp: $otp)
    }
`;

const gqlWithdrawAllRechargeCommission = gql`
    mutation WithdrawAllRechargeCommission($id: Int!, $otp: String!) {
        withdrawAllRechargeCommission(id: $id, otp: $otp)
    }
`;


const gqlWithdrawSendCommission = gql`
    mutation WithdrawSendCommission ($userId: Int!, $commissionIdList: [Int!]!, $otp: String!) {
        withdrawSendCommission(userId: $userId, commissionIdList: $commissionIdList, otp: $otp)
    }
`;


const gqlWithdrawRechargeCommission = gql`
    mutation WithdrawRechargeCommission($userId: Int!, $commissionIdList: [Int!]!, $otp: String!) {
        withdrawRechargeCommission(userId: $userId, commissionIdList: $commissionIdList, otp: $otp)
    }
`;

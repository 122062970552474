import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserRechargeCommissionList.module.scss'
import {Query} from "../../../../../graphql/types";
import moment from "moment";
import Decimal from "decimal.js";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Pager} from "../../../../shares/pager/Pager";
import {OnChangeValue} from "../../../../Interfaces";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

interface Props {
    query: Query,
    onChangeRechargeCommissionPage: OnChangeValue<number>
}

export const UserRechargeCommissionList = (props: Props) => {
    return (
        <Fragment>
            <div className='def-text' style={{marginBottom: 10}}>* 충전포인트의 일정 % 만큼 커미션을 지급합니다.</div>

            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>

            <Pager
                page={props.query.userRechargeCommissionList.page}
                size={props.query.userRechargeCommissionList.size}
                total={props.query.userRechargeCommissionList.total}
                onChange={props.onChangeRechargeCommissionPage}/>
        </Fragment>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            {props.query.userRechargeCommissionList.list.map((value, index) => (
                <div className={cx('table-mb')} key={index}>
                    <div className={cx('status')}>
                        {value.isWithdrawal && <span className='badge badge-success'>지급</span>}
                        {!value.isWithdrawal && <span className='badge badge-secondary'>미지급</span>}
                    </div>
                    <div className={cx('email')}>{value.childrenEmail}</div>
                    <div className={cx('date')}>{value.withdrawalAt && moment(value.withdrawalAt).format('YY.MM.DD')}</div>
                    <div className={cx('calc')}>
                        {Pipe.toSeparatorNumber(value.chargePoint)} 개 X {new Decimal(value.rate).mul(100).toNumber()} 원
                    </div>
                    <div className={cx('commission')}>
                        {Pipe.toSeparatorNumber(value.commission)} 원
                    </div>
                </div>
            ))}
        </>
    )
}

const Web = (props: Props) => {
    return(
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>번호</div>
                <div style={{width: 100}}>지급</div>
                <div style={{width: 150}}>지급일</div>
                <div style={{width: 'calc(100% - 750px)'}}>대리점이메일</div>
                <div style={{width: 100}}>비율</div>
                <div style={{width: 150}}>충전포인트</div>
                <div style={{width: 150}}>커미션</div>
            </div>

            {props.query.userRechargeCommissionList.total === 0 &&
            <div className={cx('table')}>
                <div style={{flexGrow: 1}}>커미션 내역이 없습니다.</div>
            </div>
            }

            {props.query.userRechargeCommissionList.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table')}>
                        <div style={{width: 100}}>{value.id}</div>
                        <div style={{width: 100}}>
                            {value.isWithdrawal && <span className='badge badge-success'>지급</span>}
                            {!value.isWithdrawal && <span className='badge badge-secondary'>미지급</span>}
                        </div>
                        <div style={{width: 150}}>
                            {value.withdrawalAt && moment(value.withdrawalAt).format('YY.MM.DD')}
                        </div>
                        <div style={{width: 'calc(100% - 750px)'}}>{value.childrenEmail}</div>
                        <div className='text-right'
                             style={{width: 100}}>{new Decimal(value.rate).mul(100).toNumber()} %
                        </div>
                        <div className='text-right'
                             style={{width: 150}}>{Pipe.toSeparatorNumber(value.chargePoint)} P
                        </div>
                        <div className='text-right' style={{width: 150}}>
                            {Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                )
            })}
        </>
    )
}

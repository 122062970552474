import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./TelReportV1List.module.scss";
import {MutationDeleteTestMsgReportArgs, TestMsgReport} from "../../../../graphql/types";
import moment from "moment";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {IconButton} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import {OnChangeValue} from "../../../Interfaces";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);

interface Props {
    list: TestMsgReport[];
    onDeleteTestMsgReport: OnChangeValue<MutationDeleteTestMsgReportArgs>
}

export const TelReportV1List = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(500);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "carrierCell")}>통신사</div>
                    <div className={cx("header", "receiveCell")}>수신번호</div>
                    <div className={cx("header", "sentCell")}>발신번호</div>
                    <div className={cx("header", "contentCell")}>내용</div>
                    <div className={cx("header", "dateCell")}>시각</div>
                    <div className={cx("header", "funcCell")}>삭제</div>
                </div>

                {props.list.length === 0 &&
                <BizNoDataTable/>
                }

                {props.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx("carrierCell")}>{value.vendor}</div>
                        <div className={cx("receiveCell")}>{value.receiverNumber}</div>
                        <div className={cx("sentCell")}>{value.senderNumber}</div>
                        <div className={cx("contentCell", "textLeft")}>{value.msg}</div>
                        <div className={cx("dateCell")}>{moment(value.createdAt).format('YY.MM.DD - hh:mm a')}</div>
                        <div className={cx("funcCell")}>
                            <IconButton size='small' onClick={() => {
                                props.onDeleteTestMsgReport({idList: [value.id]});
                            }}>
                                <DeleteOutline/>
                            </IconButton>
                        </div>
                    </div>
                ))}
            </ScrollArea>
        </>
    )
}
import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './AuthJoinView.module.scss'
import {AuthJoinInput} from "../AuthJoin";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {CenterContents} from "../../../shares/centerContents/CenterContents";
import {A} from "hookrouter";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const AuthJoinView = (props: {
    value: AuthJoinInput,
    onChangeValue: OnChangeValue<AuthJoinInput>
    onJoin: OnVoidFunction
}) => {
    return (
        <CenterContents animatedContents>
            <div className={cx('box-join')}>
                <h3 className='text-center' style={{marginBottom: 30}}>Join</h3>
                <form onSubmit={event => {
                    event.preventDefault();
                    props.onJoin();
                }}>
                    <p>Email</p>
                    <input
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.email = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        value={props.value.email} className="form-control" type="email"
                        placeholder={'이메일을 입력하여 주십시오.'} required/>

                    <p>Password</p>
                    <input
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.pw = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        value={props.value.pw}
                        className="form-control"
                        type="password"
                        placeholder={'비밀번호를 입력하여 주십시오.'} required/>

                    <p>Confirm</p>
                    <input
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.confirm = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        value={props.value.confirm}
                        className="form-control"
                        type="password"
                        placeholder={'비빌번호를 다시 입력하여 주십시오.'} required/>

                    <div style={{height: 40}}/>
                    <button
                        type="submit"
                        className="btn btn-primary btn-block">
                        회원가입
                    </button>
                </form>

                <div style={{height: 10}}/>
                <p style={{marginRight: 10}}>아이디가 이미 있으십니까?
                    <A href={'/'} style={{paddingLeft: 10}}>로그인</A>
                </p>
            </div>
        </CenterContents>
    )
}

import React, {ReactNode} from "react";
import {PhotoBackground} from "../../shares/photoBackground/PhotoBackground";

export const NotAuthLayoutWeb = (props: {
    children: ReactNode,
}) => {
    return (
        <PhotoBackground>
            <div style={{minHeight: 'calc(100vh - 60px)'}}>
                {props.children}
            </div>
        </PhotoBackground>
    )
}

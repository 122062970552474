import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './DirectNoticePopupList.module.scss';
import {DirectNoticeList} from "../../../../graphql/types";
import {IconButton, Switch} from "@material-ui/core";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {Pager} from "../../../shares/pager/Pager";
import {OnChangeValue} from "../../../Interfaces";
import {Close} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const DirectNoticePopupList = (props: {
    directNotice: DirectNoticeList,
    onChangePage: OnChangeValue<number>,
    onChangeActivate: OnChangeValue<{ directNoticeId: number, isActivate: boolean }>,
    onChangeUrgent: OnChangeValue<{ directNoticeId: number, isUrgent: boolean }>
    onDeleteDirectNotice: OnChangeValue<{ directNoticeId: number }>
}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight("auto");
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "numCell")}>번호</div>
                    <div className={cx("header", "activateCell")}>활성화</div>
                    <div className={cx("header", "warningCell")}>긴급</div>
                    <div className={cx("header", "contentCell")}>내용</div>
                    <div className={cx("header", "writerCell")}>관리자</div>
                    <div className={cx("header", "funcCell")}>삭제</div>
                </div>
                {props.directNotice.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("numCell")}>{value.id}</div>
                        <div className={cx("activateCell")}>
                            <Switch
                                color="primary"
                                checked={value.isActivate}
                                onChange={ev => {
                                    props.onChangeActivate({
                                        directNoticeId: value.id,
                                        isActivate: ev.target.checked,
                                    })
                                }}
                            />
                        </div>
                        <div className={cx("warningCell")}>
                            <Switch
                                color="primary"
                                checked={value.urgent}
                                onChange={ev => {
                                    props.onChangeUrgent({
                                        directNoticeId: value.id,
                                        isUrgent: ev.target.checked,
                                    })
                                }}
                            />
                        </div>
                        <div className={cx("contentCell", "textLeft")} ref={ref => {
                            if (ref) {
                                ref.innerHTML = value.content
                            }
                        }}/>
                        <div className={cx("writerCell")}>
                            {value.adminEmail}
                        </div>
                        <div className={cx("funcCell")}>
                            <IconButton size='small' onClick={() => {
                                props.onDeleteDirectNotice({
                                    directNoticeId: value.id
                                });
                            }}>
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                ))}

                {props.directNotice.total === 0 && <BizNoDataTable/>}
            </ScrollArea>

            <Pager
                page={props.directNotice.page}
                size={props.directNotice.size}
                total={props.directNotice.total}
                onChange={props.onChangePage}/>
        </>
    )
}

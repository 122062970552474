import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface I18nState {
    langCode: string
}

const initState: I18nState = {
    langCode: 'en'
};

class I18n extends ImmerReducer<typeof initState> {
    setLangCode(langCode: string) {
        this.draftState.langCode = langCode;
    }
}


export const I18nAction = createActionCreators(I18n);
export const I18nReducer = createReducerFunction(I18n, initState);


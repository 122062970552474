import React, {useEffect, useState} from "react";
import {Pagination} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../redux/Layout";

export const Pager = (props: {
    disableTopMargin?: boolean,
    page: number,
    size: number,
    total: number,
    onChange: (page: number) => void
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    const [size, setSize] = useState<typeSize>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setSize(typeSize.medium);
        } else {
            setSize(typeSize.small);
        }
    }, [layout.viewMode]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: props.disableTopMargin ? 0 : 50
        }}>
            <Pagination
                size={size}
                count={count}
                page={props.page + 1}
                shape='rounded'
                onChange={(event, page) => {
                    props.onChange(page - 1);
                }}/>
        </div>
    )
};

enum typeSize {
    medium = "medium",
    small = "small"
};

import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserAlert.module.scss'
import {
    AlertType,
    MutationCreateUserAlertArgs,
    MutationDeleteUserAlertArgs,
    UserAlertList
} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Interfaces";
import {Pager} from "../../../../shares/pager/Pager";
import {Title} from "../../../../shares/title/Title";
import moment from "moment";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {BadgeAlertType} from "../../../../shares/badgeAlertType/BadgeAlertType";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

interface Props {
    userId: number,
    alert: UserAlertList,
    onDeleteUserAlert: OnChangeValue<MutationDeleteUserAlertArgs>
    onCreateUserAlert: OnChangeValue<MutationCreateUserAlertArgs>,
    onChangeUserAlertPage: OnChangeValue<number>
}

type CompProps = Props & { input: MutationCreateUserAlertArgs, setInput: OnChangeValue<MutationCreateUserAlertArgs> };

export const UserAlert = (props: Props) => {
    const [input, setInput] = useState<MutationCreateUserAlertArgs>({
        input: {
            userId: props.userId,
            alertType: AlertType.Info,
            content: "",
        }
    })

    useEffect(() => {
        const copy = CopyUtil.copyAll(input);
        copy.input.userId = props.userId;
        setInput(copy);
    }, [props.userId]);

    return (
        <>
            <ViewSelector
                web={
                    <Web {...props} input={input} setInput={setInput}/>
                }
                mobile={
                    <Mobile {...props} input={input} setInput={setInput}/>
                }/>

            <div className={cx('table')}>
                <div style={{width: 100}}>
                    <select
                        value={input.input.alertType}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.input.alertType = Pipe.toAlertType(ev.target.value);
                            setInput(copy);
                        }}
                        className='form-control form-control-sm'>
                        <option value={AlertType.Success}>녹색</option>
                        <option value={AlertType.Warning}>노랑색</option>
                        <option value={AlertType.Info}>파랑색</option>
                        <option value={AlertType.Error}>빨강색</option>
                    </select>
                </div>
                <div style={{flexGrow: 1}}>
                    <input
                        value={input.input.content}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.input.content = ev.target.value;
                            setInput(copy);
                        }}
                        className='form-control form-control-sm'/>
                </div>
                <div className='text-right' style={{width: 60}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => {
                            props.onCreateUserAlert(CopyUtil.copyAll(input));
                        }}>추가
                    </button>
                </div>
            </div>


            <Pager
                page={props.alert.page}
                size={props.alert.size}
                total={props.alert.total}
                onChange={props.onChangeUserAlertPage}/>
        </>
    )
}

const Mobile = (props: CompProps) => {
    return (
        <>
            <Title>유저 알림</Title>
            {props.alert.list.map((value, index) => (
                <div key={index} className={cx('cont-mb')}>
                    <div className={cx('info')}>
                        {value.hasView && <span className='badge badge-success'>확인</span>}
                        {!value.hasView && <span className='badge badge-danger'>미확인</span>}
                    </div>
                    <div className={cx('date')}>
                        {moment(value.createdAt).format('YY.MM.DD - hh:mm')}
                    </div>
                    <div className={cx('delete')}>
                        <IconButton size='small' onClick={() => {
                            props.onDeleteUserAlert({
                                userAlertId: value.id
                            })
                        }}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div style={{whiteSpace: 'normal'}} className={cx('msg', value.alertType.toLocaleLowerCase())}>
                        {value.content}
                    </div>
                </div>

            ))}
        </>
    )
}


const Web = (props: CompProps) => {
    return (
        <Fragment>
            <Title>유저 알림</Title>
            <div className={cx('table')}>
                <div style={{width: 80}}>삭제</div>
                <div style={{width: 100}}>번호</div>
                <div style={{width: 100}}>종류</div>
                <div style={{width: 100}}>색상</div>
                <div style={{width: 150}}>날짜</div>
                <div style={{width: 250}}>관리자</div>
                <div style={{width: 'calc(100% - 780px)'}}>내용</div>
            </div>


            {props.alert.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div style={{width: 80}}>
                        <IconButton size='small' onClick={ev => {
                            props.onDeleteUserAlert({userAlertId: value.id});
                        }}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div style={{width: 100}}>{value.id}</div>
                    <div style={{width: 100}}>
                        {value.hasView && <span className='badge badge-success'>확인</span>}
                        {!value.hasView && <span className='badge badge-danger'>미확인</span>}

                    </div>
                    <div style={{width: 100}}>
                        <BadgeAlertType alertType={value.alertType}/>
                    </div>
                    <div style={{width: 150}}>
                        {moment(value.createdAt).format('MM.DD - hh:mm a')}
                    </div>
                    <div style={{width: 250}}>{value.adminEmail}</div>
                    <div className='text-left' style={{width: 'calc(100% - 780px)', whiteSpace: 'normal'}}>
                        {value.content}
                    </div>
                </div>
            ))}


        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserRechargeCommission.module.scss'
import {
    MutationWithdrawAllRechargeCommissionArgs,
    MutationWithdrawRechargeCommissionArgs,
    Query,
    UserRechargeCommission as UserCommission
} from "../../../../../graphql/types";
import {Checkbox} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../../utils/pipe/pipe";
import Decimal from "decimal.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import {OnChangeValue} from "../../../../Interfaces";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {Pager} from "../../../../shares/pager/Pager";

const cx = classNamesBind.bind(styles);

interface Props {
    userId: number,
    query: Query,
    onWithdrawAllRechargeCommission: OnChangeValue<MutationWithdrawAllRechargeCommissionArgs>,
    onWithdrawRechargeCommission: OnChangeValue<MutationWithdrawRechargeCommissionArgs>
}

type CompProps = Props & { commissionList: CommissionList, setCommissionList: OnChangeValue<CommissionList> }

export const UserRechargeCommission = (props: Props) => {
    const [commissionList, setCommissionList] = useState<CommissionList>([]);

    useEffect(() => {
        const next: CommissionList = props.query.userRechargeCommission.map(value => ({
            ...value,
            hasChecked: false,
        }))
        setCommissionList(next);
    }, [props.query.userRechargeCommission]);

    const handler = {
        commissionList,
        setCommissionList,
        onWithdrawRechargeCommission: () => {
            const args: MutationWithdrawRechargeCommissionArgs = {
                userId: props.userId,
                commissionIdList: commissionList.filter(value => (value.hasChecked)).map(value => (value.id)),
                otp: ''
            }

            if (args.commissionIdList.length === 0) {
                SwalUtil.ok({
                    msg: '최소 1개 이상 선택하여 주십시오.',
                    icon: 'error'
                });
                return;
            }

            SwalUtil.otp(otp => {
                args.otp = otp;
                props.onWithdrawRechargeCommission(args);
            })
        },
        onWithdrawAllRechargeCommission: () => {
            const args: MutationWithdrawAllRechargeCommissionArgs = {
                id: props.userId,
                otp: ''
            }

            SwalUtil.otp(otp => {
                args.otp = otp;
                props.onWithdrawAllRechargeCommission(args);
            })
        }
    }

    return (
        <Fragment>
            <ViewSelector
                web={<Web {...handler} {...props}/>}
                mobile={<Mobile {...handler} {...props}/>}/>

            <SumView data={commissionList}/>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-outline-success'
                        style={{marginRight: 10}}
                        onClick={handler.onWithdrawRechargeCommission}>선택지급
                    </button>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onWithdrawAllRechargeCommission}>전체지급
                    </button>
                </div>
            </div>

        </Fragment>
    )
}

const Web = (props: CompProps) => {
    const [page, setPage] = useState(0);
    const size = 20;
    let {commissionList} = props;
    const pickedList = commissionList.slice(page * size, (page + 1) * size);


    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>선택</div>
                <div style={{width: 'calc(100% - 500px)'}}>대리점</div>
                <div style={{width: 100}}>비율</div>
                <div style={{width: 150}}>충전포인트</div>
                <div style={{width: 150}}>커미션</div>
            </div>

            <PerfectScrollbar style={{height: 500}}>
                {props.commissionList.length === 0 &&
                    <div className={cx('table')}>
                        <div style={{flexGrow: 1}}>커미션 내역이 없습니다.</div>
                    </div>
                }

                {pickedList.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div style={{width: 100}}>
                            <Checkbox
                                checked={value.hasChecked}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.commissionList);
                                    copy[index].hasChecked = ev.target.checked;
                                    props.setCommissionList(copy);
                                }}/>
                        </div>
                        <div style={{width: 'calc(100% - 500px)'}}>
                            {value.childrenEmail}
                        </div>
                        <div className='text-right' style={{width: 100}}>
                            {new Decimal(value.rate).mul(100).toNumber()} %
                        </div>
                        <div className='text-right' style={{width: 150}}>
                            {Pipe.toSeparatorNumber(value.chargePoint)} P
                        </div>
                        <div className='text-right' style={{width: 150}}>
                            {Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                ))}
                <Pager page={page} size={size} total={props.commissionList.length} onChange={p => setPage(p)}/>
            </PerfectScrollbar>
        </>
    )
}

const Mobile = (props: CompProps) => {
    const [page, setPage] = useState(0);
    const size = 20;
    let {commissionList} = props;
    const pickedList = commissionList.slice(page * size, (page + 1) * size);

    return (
        <>
            {pickedList.map((value, index) => (
                <div key={index} className={cx('table-mb', value.hasChecked && 'checked')} onClick={ev => {
                    const copy = CopyUtil.copyAll(props.commissionList);
                    copy[index].hasChecked = !copy[index].hasChecked;
                    props.setCommissionList(copy);
                }}>
                    <div className={cx('checkbox')}>
                        <Checkbox checked={value.hasChecked} color='primary' size='small'/>
                    </div>
                    <div className={cx('email')}>{value.childrenEmail}</div>
                    <div className={cx('calc')}>
                        <span>{Pipe.toSeparatorNumber(value.chargePoint)} P X {new Decimal(value.rate).mul(100).toNumber()} %</span>
                    </div>
                    <div className={cx('commission')}>
                        {Pipe.toSeparatorNumber(value.commission)} 원
                    </div>
                </div>

            ))}
            <Pager page={page} size={size} total={props.commissionList.length} onChange={p => setPage(p)}/>
        </>
    )
}

const SumView = (props: {
    data: CommissionList
}) => {
    let commission = new Decimal(0);
    let selected = new Decimal(0);

    for (let elem of props.data) {
        commission = commission.add(elem.commission);
        if (elem.hasChecked) {
            selected = selected.add(elem.commission)
        }
    }


    return (
        <div className={cx('table')}>
            <div style={{width: '25%'}}>커미션 합</div>
            <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(commission.toNumber())} 원</div>
            <div style={{width: '25%'}}>선택 합</div>
            <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(selected.toNumber())} 원</div>
        </div>
    )

}

type CommissionList = (UserCommission & { hasChecked: boolean })[];

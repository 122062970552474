import React from "react";
import classNamesBind from "classnames/bind";
import styles from './ReportMonthlyCommissionReceipt.module.scss'
import {CompanyBalance, Query} from "../../../../graphql/types";
import {Pipe} from "../../../../utils/pipe/pipe";
import moment from "moment";
import Decimal from "decimal.js";
import PerfectScrollbar from "react-perfect-scrollbar";

const cx = classNamesBind.bind(styles);

export const ReportMonthlyCommissionReceipt = (props: {
    query: Query
}) => {
    const list = props.query.companyBalances.withdraw.filter(value => {
        return value.type === CompanyBalance.CommissionWithdrawal;
    });

    let sum = new Decimal(0);
    for (let depositWithdraw of list) {
        sum = sum.add(depositWithdraw.amount);
    }

    let scrollHeight: number;

    if (list.length === 0) {
       scrollHeight = 50;
    } else {
        scrollHeight = 300;
    }

    return (
        <>
            <div className={cx('table')}>
                <div className={cx("header", "numCell")}>지급번호</div>
                <div className={cx("header", "dateCell")}>지급일</div>
                <div className={cx("header", "priceCell")}>금액</div>
            </div>


            <PerfectScrollbar style={{height: scrollHeight}}>
                {list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("header", "numCell")}>{value.id}</div>
                        <div className={cx("header", "dateCell")}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                        <div className={cx("header", "priceCell", "textRight")}>{Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                    </div>
                ))}

                {list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{width: "100%"}}>정산 내역이 없습니다.</div>
                </div>
                }
            </PerfectScrollbar>

            <div className={cx('table')}>
                <div className={cx("sumCell")}>합계</div>
                <div className={cx("priceCell", "textRight")}>{Pipe.toSeparatorNumber(sum.toNumber())} 원</div>
            </div>
        </>
    )
}

import React, { ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BoxCont.module.scss';

const cx = classNamesBind.bind(styles);

export const BoxCont = (props: {children: ReactNode}) => {
    return (
        <div className={cx('cont')}>
            {props.children}
        </div>
    )
}

import React, {useState, useEffect} from "react";
import classNamesBind from "classnames/bind";
import styles from './PointRequestReceipt.module.scss'
import {PointHistoryList} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {Pager} from "../../../shares/pager/Pager";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/pipe";
import {BadgeHistoryType} from "../../../shares/badgeHistoryType/BadgeHistoryType";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);

export const PointRequestReceipt = (props: {
    data: PointHistoryList,
    onChangePage: OnChangeValue<number>
}) => {

    const [scrollAreaHeight, setScrollAreaHeight] = useState<string | number>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<string | number>();
    const [scrollAreaContent, setScrollAreaContent] = useState<string | number>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(window.innerHeight - 350);
            setScrollAreaWidth(window.innerWidth - 60);
            setScrollAreaContent(110+70+100+200+200);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx("table", "scrollTable")}>
                    <div className={cx("dateCell")}>날짜</div>
                    <div className={cx("variableCell")}>종류</div>
                    <div className={cx("pointCell")}>포인트</div>
                    <div className={cx("userCell")}>유저</div>
                    <div className={cx("adminCell")}>관리자</div>
                </div>


                {props.data.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx("dateCell")}>
                            {moment(value.createdAt).format('MM.DD - hh:mm a')}
                        </div>
                        <div className={cx("variableCell")}>
                            <BadgeHistoryType type={value.historyType}/>
                        </div>
                        <div className={cx("pointCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.point)} P
                        </div>
                        <div className={cx("userCell")}>
                            {value.userEmail}
                        </div>
                        <div className={cx("adminCell")}>
                            {value.adminEmail}
                        </div>
                    </div>
                ))}
            </ScrollArea>

            <Pager
                page={props.data.page}
                size={props.data.size}
                total={props.data.total}
                onChange={props.onChangePage}/>
        </>
    )
}

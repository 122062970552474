import React, {Fragment, ReactNode} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import ko from 'date-fns/locale/ko';
import moment from "moment";
import styles from './BizPeriodPicker.module.scss'
import classNamesBind from "classnames/bind";
import {OnChangeValue, OnVoidFunction} from "../../Interfaces";
import {SwalUtil} from "../../../utils/swal/swalUtil";

registerLocale('ko', ko);
const cx = classNamesBind.bind(styles);

export const BizPeriodPicker = (props: {
    period: Period,
    onChange: OnChangeValue<Period>,
    onError?: OnVoidFunction,
    children?: ReactNode
}) => {
    return (
        <Fragment>
            <div className={cx('cont')}>
                <div className={cx('label', 'def-text')}>시작일</div>
                <div className={cx('picker')}>
                    <DatePicker
                        className='form-control form-control-sm text-center'
                        locale={'ko'}
                        dateFormat={"yyyy/MM/dd"}
                        selectsStart
                        selected={props.period.stDate}
                        endDate={props.period.edDate}
                        onChange={date => {
                            if (date instanceof Date) {
                                if (moment(props.period.edDate).toDate().getTime() < date.getTime()) {
                                    SwalUtil.ok({
                                        msg: '시작일이 종료일보다 뒤입니다.',
                                        icon: 'error',
                                    })
                                    if (props.onError) {
                                        props.onError();
                                    }
                                    return;
                                }

                                const copy = Object.assign({}, props.period);
                                copy.stDate = date;
                                props.onChange(copy);
                            }
                        }}/>
                </div>
                <div className={cx('label', 'def-text')}>종료일</div>
                <div className={cx('picker')}>
                    <DatePicker
                        className='form-control form-control-sm text-center'
                        locale={'ko'}
                        dateFormat={"yyyy/MM/dd"}
                        selectsEnd
                        selected={props.period.edDate}
                        startDate={props.period.stDate}
                        onChange={date => {
                            if (date instanceof Date) {
                                if (date.getTime() < moment(props.period.stDate).toDate().getTime()) {
                                    SwalUtil.ok({
                                        msg: '종료일이 시작일보다 앞입니다.',
                                        icon: 'error',
                                    })
                                    if (props.onError) {
                                        props.onError();
                                    }
                                    return;
                                }

                                const copy = Object.assign({}, props.period);
                                copy.edDate = date;
                                props.onChange(copy);
                            }
                        }}/>
                </div>
                <div style={{flexGrow: 1, paddingRight: 10}} className='text-right'>
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}

export type Period = {
    stDate: Date;
    edDate: Date;
}

import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutNavTop.module.scss';
import {NotificationsOutlined, PowerSettingsNew, Menu} from "@material-ui/icons";
import {AppBar, Badge, Button, IconButton, Toolbar, Tooltip, Popover} from "@material-ui/core";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {useDispatch, useSelector} from "react-redux";
import {LayoutAction, LayoutState, ViewMode} from "../../../../redux/Layout";
import {navigate} from "hookrouter";
import {UserAction} from "../../../../redux/User";
import gql from "graphql-tag";
import {useMutation, useReactiveVar} from "@apollo/client";
import {LocalStorage} from "../../../../utils/localstorage/LocalStorage";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {GlobalVars} from "../../../../globalVars/globalVars";
import {AlertMenu} from "./alertMenu/AlertMenu";
import {GetSidebar} from "../../../App";

const cx = classNamesBind.bind(styles);

export const LayoutNavTop = (props: {
    navWidth: number | string;
}) => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const menu = GetSidebar()[layout.sidebarIndex].topNav;
    const dispatch = useDispatch();
    const [mutLogout] = useMutation(gqlLogout);
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | undefined>();
    const depositRequestAlertList = useReactiveVar(GlobalVars.alert.depositRequest);
    const chatAlertList = useReactiveVar(GlobalVars.alert.chatAlert);
    const settleCommissionList = useReactiveVar(GlobalVars.alert.settleCommissionAlert);
    const alertTotal = depositRequestAlertList.length + chatAlertList.length + settleCommissionList.length;

    const logout = () => {
        SwalUtil.yn({
            msg: "로그아웃 하시겠습니까?",
            icon: "question",
            ok: () => {
                mutLogout()
                    .then(res => {
                        LocalStorage.clearSession();
                        dispatch(UserAction.logout());
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            SwalUtil.error();
                        }
                    }));

            }
        })
    }

    return (
        <>
            <AppBar position="sticky" color='default'>
                <Toolbar>
                    {menu.map((value, index) => {
                        return (
                            <Button
                                key={index}
                                // className={cx('nav-icon', value.router === path ? 'activated' : '')}
                                onClick={event => {
                                    navigate(value.router);
                                }}>{value.title}</Button>
                        )
                    })}

                    <div className={cx('spacer')}/>

                    <IconButton
                        edge='end'
                        color='inherit'
                        style={{marginRight: 5}}
                        onClick={ev => {
                            setMenuAnchor(ev.currentTarget)
                        }}>
                        <Badge badgeContent={alertTotal} color={'error'}>
                            <NotificationsOutlined/>
                        </Badge>
                    </IconButton>


                    <Popover
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        onClose={() => {
                            setMenuAnchor(undefined);
                        }}
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}>
                        <AlertMenu onClose={() => {
                            setMenuAnchor(undefined);
                        }}/>
                    </Popover>

                    {layout.viewMode === ViewMode.Mobile &&
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={event => {
                            dispatch(LayoutAction.setSidebar(true));
                        }}>
                        <Menu/>
                    </IconButton>
                    }

                    {layout.viewMode === ViewMode.Web &&
                    <IconButton edge="end" color="inherit" aria-label="menu"
                                onClick={event => {
                                    logout();
                                }}>
                        <Tooltip title='로그아웃'>
                            <PowerSettingsNew/>
                        </Tooltip>
                    </IconButton>
                    }

                </Toolbar>
            </AppBar>
        </>
    )
}


const gqlLogout = gql`
    mutation Logout {
        logout
    }
`;

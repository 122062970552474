import React, {useState} from 'react';
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Query, QueryUserListArgs, UserFindOptions} from "../../../graphql/types";
import {Pager} from "../../shares/pager/Pager";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {UserListView} from "./list/UserListView";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {UserListSearch} from "./search/UserListSearch";
import {Title} from "../../shares/title/Title";


export const UserList = (props: {
    page: number,
}) => {
    const {data, refetch} = useQuery<Query, QueryUserListArgs>(gqlUserList, {
        variables: {
            page: props.page,
            size: 20,
            options: {
                isActivate: true
            }
        }
    });

    const [findOption, setFindOption] = useState<UserFindOptions>({
        email: '',
        isActivate: true,
        userType: null
    });

    const handler = {
        onChangePage: (page: number) => {
            refetch({
                page,
                size: 20,
                options: findOption,
            }).catch(ApolloCatch({}))
        },
        onChangeValue: setFindOption,
        onFind: () => {
            handler.onChangePage(0);
        },
        onInit: () => {
            setFindOption({
                isActivate: true
            })
            refetch({
                page: 0,
                size: 20,
                options: {
                    isActivate: true
                },
            }).catch(ApolloCatch({}))
        }
    }


    return (
        <FadeInContainer>
            {data &&
            <BizCol12>
                <Title>회원 찾기</Title>
                <UserListSearch
                    value={findOption}
                    {...handler}/>

                <Title>회원 목록</Title>
                <UserListView userList={data.userList}/>

                <Pager
                    page={data.userList.page}
                    size={data.userList.size}
                    total={data.userList.total}
                    onChange={handler.onChangePage}/>
            </BizCol12>
            }

        </FadeInContainer>
    )
}


const gqlUserList = gql`
    query UserList($page: Int!, $options: UserFindOptions!) {
        userList(page: $page, size: 20, options: $options) {
            page
            size
            total
            user {
                id
                isActivate
                email
                point
                tax
                userType
                createdAt
                memo
                categoryNm
                apiPermission {
                    hashedApiKey
                    nm
                    smsFee
                }
            }
        }
    }
`;

import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {Init} from "../graphql/types";

export enum AppStatus {
    Loading,
    Ok,
    NotAuth,
}


export interface SystemState {
    init: Init,
    appStatus: AppStatus
}

const initState: SystemState = {
    init: {
        fb: '',
        appNm: '',
    },
    appStatus: AppStatus.Loading,
};

class System extends ImmerReducer<typeof initState> {
    setInit(init: Init) {
        this.draftState.init = init;
    }

    setAppStatus(status: AppStatus) {
        this.draftState.appStatus = status;
    }
}


export const SystemAction = createActionCreators(System);
export const SystemReducer = createReducerFunction(System, initState);

export type LangCode = { code: string, nm: string };

import React from 'react';
import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";

export const BizBackdrop = (props: {
    loading: boolean,
}) => {
    const classes = useStyles();

    return (
        <Backdrop invisible className={classes.backdrop} open={props.loading}>
            <CircularProgress color="primary"/>
        </Backdrop>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


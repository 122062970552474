import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {Admin} from "../graphql/types";


export interface UserState {
    isLogin: boolean;
    admin: Admin
}

const initAdmin = {
    id: 0,
    email: "",
    hasPw: false,
    salt: ""
}

const initState: UserState = {
    isLogin: false,
    admin: initAdmin,
};



class User extends ImmerReducer<typeof initState> {
    login(admin: Admin) {
        this.draftState.isLogin = true;
        this.draftState.admin = admin;
    }

    logout() {
        this.draftState.isLogin = false;
        this.draftState.admin = initAdmin;
    }
}



export const UserAction = createActionCreators(User);
export const UserReducer = createReducerFunction(User, initState);


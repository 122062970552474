import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationAllowRechargeCommissionSettleRequestArgs,
    MutationAllowSendCommissionSettleRequestArgs, MutationDenyRechargeCommissionSettleRequestArgs,
    MutationDenySendCommissionSettleRequestArgs,
    Query
} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {Title} from "../../shares/title/Title";
import {PointCommissionSendList} from "./sendList/PointCommissionSendList";
import {PointCommissionRechargeList} from "./rechargeList/PointCommissionRechargeList";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {PointCommissionSendHistory} from "./sendHistory/PointCommissionSendHistory";
import {PointCommissionRechargeHistory} from "./rechargeHistory/PointCommissionRechargeHistory";
import {navigate} from "hookrouter";

export const PointCommission = (props: {
    sendPage: number,
    rechargePage: number,
}) => {
    const {data, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });
    const [mutAllowSend] = useMutation<Mutation, MutationAllowSendCommissionSettleRequestArgs>(gqlAllowSend);
    const [mutDenySend] = useMutation<Mutation, MutationDenySendCommissionSettleRequestArgs>(gqlDenySend);
    const [mutAllowRecharge] = useMutation<Mutation, MutationAllowRechargeCommissionSettleRequestArgs>(gqlAllowRecharge);
    const [mutDenyRecharge] = useMutation<Mutation, MutationDenyRechargeCommissionSettleRequestArgs>(gqlDenyRecharge);

    const handler = {
        allowSend: (args: MutationAllowSendCommissionSettleRequestArgs) => {
            SwalUtil.yn({
                msg: '승인 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutAllowSend({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '승인 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({}))
                }
            })
        },
        denySend: (args: MutationDenySendCommissionSettleRequestArgs) => {
            SwalUtil.yn({
                msg: '반려 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDenySend({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '반려 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({}))
                }
            });
        },
        allowRecharge: (args: MutationAllowRechargeCommissionSettleRequestArgs) => {
            SwalUtil.yn({
                msg: '승인 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutAllowRecharge({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '승인 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({}))
                }
            })

        },
        denyRecharge: (args: MutationDenyRechargeCommissionSettleRequestArgs) => {
            SwalUtil.yn({
                msg: '반려 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDenyRecharge({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '반려 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({}))
                }
            });
        },
        onChangePageRecharge: (p: number) => {
            navigate(`/point/commission/${p}/${props.sendPage}`);
        },
        onChangePageSend: (p: number) => {
            navigate(`/point/commission/${props.rechargePage}/${p}`);
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>충전 커미션 정산 요청</Title>
                    <PointCommissionRechargeList
                        {...handler}
                        {...data}/>
                </BizCol12>

                <BizCol12>
                    <Title>전송 커미션 정산 요청</Title>
                    <PointCommissionSendList
                        {...handler}
                        {...data}/>
                </BizCol12>

                <BizCol12>
                    <Title>충전 커미션 목록</Title>
                    <PointCommissionRechargeHistory
                        {...handler}
                        {...data}/>

                </BizCol12>

                <BizCol12>
                    <Title>전송 커미션 목록</Title>
                    <PointCommissionSendHistory
                        {...handler}
                        {...data}/>


                </BizCol12>

            </FadeInContainer>
            }
        </>
    )
}

const gqlAllowSend = gql`
    mutation AllowSend($sendCommissionSettleRequestId: Int!, $otp: String!) {
        allowSendCommissionSettleRequest(sendCommissionSettleRequestId: $sendCommissionSettleRequestId, otp: $otp)
    }
`;

const gqlDenySend = gql`
    mutation DenySend($sendCommissionSettleRequestId: Int!) {
        denySendCommissionSettleRequest(sendCommissionSettleRequestId: $sendCommissionSettleRequestId)
    }
`;

const gqlAllowRecharge = gql`
    mutation AllowRecharge($rechargeCommissionSettleRequestId: Int!, $otp: String!) {
        allowRechargeCommissionSettleRequest(rechargeCommissionSettleRequestId: $rechargeCommissionSettleRequestId, otp: $otp)
    }
`;

const gqlDenyRecharge = gql`
    mutation DenyRecharge($rechargeCommissionSettleRequestId: Int!) {
        denyRechargeCommissionSettleRequest(rechargeCommissionSettleRequestId: $rechargeCommissionSettleRequestId)
    }
`;

const gqlData = gql`
    query DATA ($rechargePage: Int!, $sendPage: Int!) {
        rechargeCommissionSettleRequest {
            id
            commissionCount
            commissionTotal
            commissionType
            status
            userEmail
            bankNumber
            createdAt
        }
        sendCommissionSettleRequest {
            id
            commissionCount
            commissionTotal
            commissionType
            status
            userEmail
            bankNumber
            createdAt
        }
        rechargeCommissionSettleRequestList(page: $rechargePage, size: 10) {
            page
            size
            total
            list {
                id
                commissionCount
                commissionTotal
                commissionType
                status
                userEmail
                createdAt
                settleAt
                bankNumber
            }
        }
        sendCommissionRequestSettleList(page: $sendPage, size: 10) {
            page
            size
            total
            list {
                id
                commissionCount
                commissionTotal
                commissionType
                status
                userEmail
                createdAt
                settleAt
                bankNumber
            }
        }
    }
`;

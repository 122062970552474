import React from "react";
import classNamesBind from "classnames/bind";
import styles from './UserListSearch.module.scss'
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {UserFindOptions, UserType} from "../../../../graphql/types";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {Title} from "../../../shares/title/Title";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Pipe} from "../../../../utils/pipe/pipe";

const cx = classNamesBind.bind(styles);

interface Props {
    value: UserFindOptions,
    onChangeValue: OnChangeValue<UserFindOptions>,
    onFind: OnVoidFunction,
    onInit: OnVoidFunction,
}

export const UserListSearch = (props: Props) => {
    return (
        <ViewSelector
            mobile={<Mobile {...props}/>}
            web={<Web {...props}/>}
        />
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '10%'}}>종류</div>
                <div style={{width: '40%'}}>
                    <select
                        className='form-control form-control-sm'
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.userType = Pipe.toUserType(ev.target.value);
                            props.onChangeValue(copy);
                        }}>
                        <option value={''}>전체</option>
                        <option value={UserType.General}>정회원</option>
                        <option value={UserType.Reseller}>총판</option>
                        <option value={UserType.Agent}>대리점</option>
                        {process.env.REACT_APP_TREE_USER === 'true' &&
                        <option value={UserType.Tree}>추천회원</option>
                        }
                    </select>
                </div>

                <div style={{width: '10%'}}>아이디</div>
                <div style={{width: '40%'}}>
                    <input
                        placeholder='이메일을 입력하여 주십시오.'
                        className='form-control form-control-sm'
                        value={props.value.email || ''}
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.email = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                props.onFind();
                            }
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '10%'}}>옵션</div>
                <div style={{width: '90%'}} className='text-left'>
                    <FormControlLabel
                        style={{margin: 0}}
                        control={
                            <Checkbox
                                checked={props.value.isActivate || false}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy.isActivate = ev.target.checked ? true : null;
                                    props.onChangeValue(copy);
                                }}
                                name="checkedB"
                                color="primary"/>
                        }
                        label="차단 회원 가리기"/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '100%'}} className='text-right'>
                    <button
                        style={{width: 100, marginRight: 10}}
                        className='btn btn-sm btn-success'
                        onClick={props.onFind}>검색
                    </button>
                    <button
                        style={{width: 100}}
                        className='btn btn-sm btn-outline-success'
                        onClick={props.onInit}>초기화
                    </button>
                </div>
            </div>

            <div style={{height: 80}}/>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <>

            <div className={cx('table')}>
                <div style={{width: '30%'}}>종류</div>
                <div style={{width: '70%'}}>
                    <select
                        className='form-control form-control-sm'
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.userType = Pipe.toUserType(ev.target.value);
                            props.onChangeValue(copy);
                        }}>
                        <option value={''}>전체</option>
                        <option value={UserType.General}>정회원</option>
                        <option value={UserType.Reseller}>총판</option>
                        <option value={UserType.Agent}>대리점</option>
                    </select>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '30%'}}>아이디</div>
                <div style={{width: '70%'}}>
                    <input
                        placeholder='이메일을 입력하여 주십시오.'
                        className='form-control form-control-sm'
                        value={props.value.email || ''}
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.email = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                props.onFind();
                            }
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '30%'}}>옵션</div>
                <div style={{width: '70%'}} className='text-left'>
                    <FormControlLabel
                        style={{margin: 0, fontSize: '0.8rem'}}
                        control={
                            <Checkbox
                                checked={props.value.isActivate || false}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy.isActivate = ev.target.checked ? true : null;
                                    props.onChangeValue(copy);
                                }}
                                name="checkedB"
                                color="primary"/>
                        }
                        label="차단 회원 가리기"/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '50%'}}>
                    <button
                        style={{width: '100%'}}
                        className='btn btn-sm btn-success'
                        onClick={props.onFind}>검색
                    </button>
                </div>

                <div style={{width: '50%'}}>
                    <button
                        style={{width: '100%'}}
                        className='btn btn-sm btn-outline-success'
                        onClick={props.onInit}>초기화
                    </button>
                </div>
            </div>

            <div style={{height: 80}}/>
        </>

    )
}


// import {MultiLangs} from "../../graphql/types";

import {AlertType, BoardType, DepositRechargeDepositFindType, UserType} from "../../graphql/types";
import moment from "moment";

export class Pipe {
    static toSeparatorNumber(numb: number): string {
        return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    static toBoardType(type: String): BoardType {
        switch (type) {
            case "Notice":
                return BoardType.Notice;
            case "Popup":
                return BoardType.Popup;
            default:
                return BoardType.Notice;
        }
    }

    static toAlertType(type: string): AlertType {
        switch (type) {
            case "Success":
                return AlertType.Success;
            case "Warning":
                return AlertType.Warning;
            case "Info" :
                return AlertType.Info;
            case "Error" :
                return AlertType.Error;
            default:
                return AlertType.Success;
        }
    }

    static toUserType(type: string): UserType | null {
        switch (type) {
            case "General":
                return UserType.General;
            case "Reseller":
                return UserType.Reseller;
            case "Agent":
                return UserType.Agent;
            case "Tree":
                return UserType.Tree;
            default:
                return null;
        }

    }

    static toTimeMonth(date: Date): number {
        return Number(moment(date).format("YYYYMM"));
    }

    static toTimeDay(date: Date): number {
        return Number(moment(date).format('YYYYMMDD'));
    }

    static cvtTimeMonthToDate(timeMonth: number): Date {
        if (timeMonth < 100000) {
            return new Date();
        }
        const str = String(timeMonth);
        return moment(`${str.substring(0, 4)}-${str.substring(4, 6)}-01 09:00:00`).toDate();
    }

    static toThisMonthTimeDay(date: Date): { stDate: number, edDate: number } {
        const now = moment(date).hour(9);
        const stDate = moment(`${now.year()}-${now.month() + 1}-01 00:00:00`);
        const edDate = moment(`${now.year()}-${now.month() + 1}-${now.daysInMonth()} 00:00:00`);

        return {
            stDate: Number(stDate.format("YYYYMMDD")),
            edDate: Number(edDate.format("YYYYMMDD")),
        }
    }

    static toDepositRechargeDepositFindType(value: string): DepositRechargeDepositFindType {
        switch (value) {
            case 'Nm':
                return DepositRechargeDepositFindType.Nm;
            case 'Email':
                return DepositRechargeDepositFindType.Email;
            default:
                return DepositRechargeDepositFindType.Nm
        }
    }
}



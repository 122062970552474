import React from "react";
import classNamesBind from "classnames/bind";
import styles from './AuthLoginView.module.scss'
import {AuthLoginInput} from "../AuthLogin";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {navigate} from "hookrouter";
import {CenterContents} from "../../../shares/centerContents/CenterContents";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {useSelector} from "react-redux";
import {SystemState} from "../../../../redux/System";

const cx = classNamesBind.bind(styles);

export const AuthLoginView = (props: {
    value: AuthLoginInput,
    onChangeValue: OnChangeValue<AuthLoginInput>,
    onRegisterOtp: OnVoidFunction,
    onLogin: OnVoidFunction,
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    return (
        <CenterContents animatedContents>
            <form className={cx('box-login')} onSubmit={ev => {
                ev.preventDefault();
                props.onLogin();
            }}>
                <h3 style={{marginBottom: 30}}>{system.init.appNm} Admin</h3>
                <p>Email</p>
                <input
                    className='form-control'
                    type='email'
                    placeholder='이메일을 입력하여 주십시오.'
                    value={props.value.email}
                    onChange={event => {
                        const copy = CopyUtil.copyAll(props.value);
                        copy.email = event.target.value;
                        props.onChangeValue(copy);
                    }}/>
                <p>Password</p>
                <input
                    className='form-control'
                    type='password'
                    placeholder='비밀번호를 입력하여 주십시오.'
                    value={props.value.pw}
                    onChange={event => {
                        const copy = CopyUtil.copyAll(props.value);
                        copy.pw = event.target.value;
                        props.onChangeValue(copy);
                    }}/>

                <p>OTP</p>
                <input
                    className='form-control'
                    placeholder='OTP 를 입력하여 주십시오.'
                    value={props.value.otp}
                    onKeyUp={event => {
                        if (event.key === 'Enter') {
                            props.onLogin();
                        }
                    }}
                    onChange={event => {
                        const copy = CopyUtil.copyAll(props.value);
                        copy.otp = event.target.value;
                        props.onChangeValue(copy);
                    }}/>


                <div style={{height: 40}}/>
                <button
                    style={{marginBottom: 10}}
                    type="submit"
                    className="btn btn-primary btn-block">
                    로그인
                </button>

                <button
                    onClick={props.onRegisterOtp}
                    style={{marginBottom: 10}}
                    type="button"
                    className="btn btn-outline-secondary btn-block">
                    OTP 등록
                </button>

                {/*회원가입*/}
                <button
                    type='button'
                    onClick={event => {
                        navigate('/join');
                    }}
                    className='btn btn-outline-secondary btn-block'>
                    회원가입
                </button>

                {/*회원가입, 비밀번호 찾기 안내 */}
                {/*<div style={{height: 10}}/>*/}
                {/*<p>비밀번호를 잊으셨습니까?*/}
                {/*    <span style={{*/}
                {/*        paddingLeft: 10,*/}
                {/*        color: '#86a7cd',*/}
                {/*        cursor: "pointer"*/}
                {/*    }}>초기화</span>*/}
                {/*</p>*/}
            </form>
        </CenterContents>
    )
}

import React from "react";
import classNamesBind from "classnames/bind";
import styles from './ReportBalanceTotal.module.scss'
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {Pipe} from "../../../../utils/pipe/pipe";
import {CompanyBalances} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

interface Props {
    companyBalance: CompanyBalances
}

export const ReportBalanceTotal = (props: Props) => {
    return (
        <ViewSelector
            mobile={<Mobile {...props}/>}
            web={<Web {...props}/>}
        />
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '33%'}}>입금액</div>
                <div className={cx('header')} style={{width: '33%'}}>출금액</div>
                <div className={cx('header')} style={{width: '34%'}}>잔고</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalDeposit)} 원</div>
                <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalWithdraw)} 원</div>
                <div style={{width: '34%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalMargin)} 원</div>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '20%'}}>입금액</div>
                <div className='text-right' style={{width: '80%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalDeposit)} 원</div>
            </div>

            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '20%'}}>출금액</div>
                <div className='text-right' style={{width: '80%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalWithdraw)} 원</div>
            </div>

            <div className={cx('table')}>
                <div className={cx('header')} style={{width: '20%'}}>잔고</div>
                <div className='text-right' style={{width: '80%'}}>{Pipe.toSeparatorNumber(props.companyBalance.totalMargin)} 원</div>
            </div>
        </>
    )
}


import React from 'react';
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {
    MutationCreateReseller1JoinCodeArgs,
    Mutation,
    MutationCreateGeneralJoinCodeArgs,
    Query,
    MutationCreateReseller2JoinCodeArgs,
    MutationCreateTreeJoinCodeArgs,
    MutationDeleteResellerJoinCodeArgs,
    MutationDeleteGeneralJoinCodeArgs,
    MutationDeleteTreeJoinCodeArgs
} from "../../../graphql/types";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {Title} from "../../shares/title/Title";
import {navigate} from "hookrouter";
import {CodeIssueGeneralList} from "./issuedCodeList/CodeIssueGeneralList";
import {CodeIssueResellerList} from "./issuedCodeList/CodeIssueResellerList";
import {CodeIssueInput} from "./input/CodeIssueInput";
import {CodeIssueTreeList} from "./issuedCodeList/CodeIssueTreeList";

export const CodeIssue = (props: {
    generalPage: number,
    resellerPage: number,
    treePage: number,
}) => {
    const {data, refetch} = useQuery<Query, typeof props>(gqlJoinCodeList, {
        variables: props
    });
    const [mutCreateGeneral] = useMutation<Mutation, MutationCreateGeneralJoinCodeArgs>(gqlCreateGeneral);
    const [mutCreateReseller1] = useMutation<Mutation, MutationCreateReseller1JoinCodeArgs>(gqlCreateReseller1);
    const [mutCreateReseller2] = useMutation<Mutation, MutationCreateReseller2JoinCodeArgs>(gqlCreateReseller2);
    const [mutCreateTree] = useMutation<Mutation, MutationCreateTreeJoinCodeArgs>(gqlCreateTree);

    const [mutDeleteGeneral] = useMutation<Mutation, MutationDeleteGeneralJoinCodeArgs>(gqlDeleteGeneral);
    const [mutDeleteReseller] = useMutation<Mutation, MutationDeleteResellerJoinCodeArgs>(gqlDeleteReseller);
    const [mutDeleteTree] = useMutation<Mutation, MutationDeleteTreeJoinCodeArgs>(gqlDeleteTree);


    const onReload = () => {
        refetch({...props})
            .catch(ApolloCatch({}));
    }

    const onChangeGeneralPage = (p: number) => {
        navigate(`/code/${p}/${props.resellerPage}/${props.treePage}`);
    }

    const onChangeResellerPage = (p: number) => {
        navigate(`/code/${props.generalPage}/${p}/${props.treePage}`);
    }

    const onChangeTreePage = (p: number) => {
        navigate(`/code/${props.generalPage}/${props.resellerPage}/${p}`);
    }

    const onCreateGeneral = (input: CreateJoinCodeArgs) => {
        mutCreateGeneral({variables: input})
            .then(res => {
                SwalUtil.ok({
                    msg: `발급되었습니다.<br/>${res.data!.createGeneralJoinCode}`,
                    icon: "success"
                });
                return refetch(props);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError()
            }))

    }

    const onCreateReseller1 = (args: CreateJoinCodeArgs) => {
        mutCreateReseller1({variables: args})
            .then(res => {
                SwalUtil.ok({
                    msg: `발급되었습니다.<br/>${res.data!.createReseller1JoinCode}`,
                    icon: "success"
                });
                return refetch(props);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError()
            }))
    }

    const onCreateReseller2 = (args: CreateJoinCodeArgs) => {
        mutCreateReseller2({variables: args})
            .then(res => {
                SwalUtil.ok({
                    msg: `발급되었습니다.<br/>${res.data!.createReseller2JoinCode}`,
                    icon: "success"
                });
                return refetch(props);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError()
            }))
    }

    const onCreateTree = (args: CreateJoinCodeArgs) => {
        mutCreateTree({variables: args})
            .then(res => {
                SwalUtil.ok({
                    msg: `발급되었습니다.<br/>${res.data!.createTreeJoinCode}`,
                    icon: "success"
                });
                return refetch(props);
            })
            .catch(ApolloCatch({}))
    }

    const onDeleteGeneral = (joinCode: string) => {
        SwalUtil.yn({
            msg: `삭제 하시겠습니까? <br/> ${joinCode}`,
            icon: "question",
            ok: () => {
                mutDeleteGeneral({variables: {joinCode}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '삭제되었습니다.',
                            icon: "success"
                        });
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        });
    }

    const onDeleteReseller = (joinCode: string) => {
        SwalUtil.yn({
            msg: `삭제 하시겠습니까? <br/> ${joinCode}`,
            icon: "question",
            ok: () => {
                mutDeleteReseller({variables: {joinCode}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '삭제되었습니다.',
                            icon: "success"
                        });
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        });
    }

    const onDeleteTree = (joinCode: string) => {
        SwalUtil.yn({
            msg: `삭제 하시겠습니까? <br/> ${joinCode}`,
            icon: "question",
            ok: () => {
                mutDeleteTree({variables: {joinCode}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '삭제되었습니다.',
                            icon: "success"
                        });
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        });
    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>일반회원 코드 발급</Title>
                    <CodeIssueInput
                        apiKeyList={data.apiKeyList}
                        onCreate={onCreateGeneral}/>
                </BizCol12>

                <BizCol12>
                    <Title>총판1 코드 발급</Title>
                    <div className='def-text' style={{marginBottom: 10}}>* 건당 가격의 차익을 커미션으로 주는 총판</div>
                    <CodeIssueInput
                        apiKeyList={data.apiKeyList}
                        onCreate={onCreateReseller1}/>
                </BizCol12>

                <BizCol12>
                    <Title>총판2 코드 발급</Title>
                    <div className='def-text' style={{marginBottom: 10}}>* 대리점 충전 포인트의 일정 %를 커미션으로 주는 총판 (5%)</div>
                    <CodeIssueInput
                        apiKeyList={data.apiKeyList}
                        onCreate={onCreateReseller2}/>
                </BizCol12>

                {process.env.REACT_APP_TREE_USER === 'true' &&
                <BizCol12>
                    <Title>추천회원 코드 발급</Title>
                    <div className='def-text' style={{marginBottom: 10}}>* 추천 가입한 회원에게 충전 포인트의 일정 %를 커미션으로 주는 회원 (5%)
                    </div>
                    <CodeIssueInput
                        apiKeyList={data.apiKeyList}
                        onCreate={onCreateTree}/>

                </BizCol12>
                }

                <BizCol12>
                    <Title>일반회원 코드 리스트</Title>
                    <CodeIssueGeneralList
                        list={data.joinCodeGeneral}
                        onChangePage={onChangeGeneralPage}
                        onDelete={onDeleteGeneral}/>
                </BizCol12>

                <BizCol12>
                    <Title>총판 코드 리스트</Title>
                    <CodeIssueResellerList
                        list={data.joinCodeReseller}
                        onChangePage={onChangeResellerPage}
                        onDelete={onDeleteReseller}/>
                </BizCol12>


                {process.env.REACT_APP_TREE_USER === 'true' &&
                <BizCol12>
                    <Title>추천 회원 코드 리스트</Title>
                    <CodeIssueTreeList
                        list={data.joinCodeTree}
                        onChangePage={onChangeTreePage}
                        onDelete={onDeleteTree}/>
                </BizCol12>
                }
            </FadeInContainer>

            }

        </>
    )
}

export type CreateJoinCodeArgs =
    MutationCreateGeneralJoinCodeArgs
    | MutationCreateReseller1JoinCodeArgs
    | MutationCreateReseller2JoinCodeArgs;

const gqlCreateTree = gql`
    mutation Create($input: CreateJoinCode!, $otp: String!)  {
        createTreeJoinCode(input: $input, otp: $otp)
    }
`;

const gqlCreateGeneral = gql`
    mutation CreateGeneralCode($input: CreateJoinCode!, $otp: String!) {
        createGeneralJoinCode(input: $input, otp: $otp)
    }
`;

const gqlCreateReseller1 = gql`
    mutation CreateGenerate1Reseller($input: CreateJoinCode!, $otp: String!) {
        createReseller1JoinCode(input: $input, otp: $otp)
    }
`;

const gqlCreateReseller2 = gql`
    mutation CreateGenerate2Reseller($input: CreateJoinCode!, $otp: String!) {
        createReseller2JoinCode(input: $input, otp: $otp)
    }
`;


const gqlDeleteGeneral = gql`
    mutation DeleteGeneralCode($joinCode: String!) {
        deleteGeneralJoinCode(joinCode: $joinCode)
    }
`;

const gqlDeleteReseller = gql`
    mutation DeleteResellerCode($joinCode: String!) {
        deleteResellerJoinCode(joinCode: $joinCode)
    }
`;

const gqlDeleteTree = gql`
    mutation Delete($joinCode: String!) {
        deleteTreeJoinCode(joinCode: $joinCode)
    }
`;

const gqlJoinCodeList = gql`
    query JoinCodeList($generalPage: Int!, $resellerPage: Int!, $treePage: Int!) {
        apiKeyList {
            hashedApiKey
            nm
            smsFee
        }
        joinCodeGeneral(page: $generalPage, size: 20) {
            page
            size
            total
            list {
                adminEmail
                apiKey {
                    nm
                    smsFee
                }
                joinCode
                reusable
                tax
            }
        }
        joinCodeReseller(page: $resellerPage, size: 20) {
            page
            size
            total
            list {
                adminEmail
                commission {
                    hasSendCommission
                    rechargeCommissionRate
                }
                apiKey {
                    nm
                    smsFee
                }
                joinCode
                reusable
                tax
            }
        }
        joinCodeTree(page: $treePage, size: 20) {
            page
            size
            total
            list {
                code
                adminEmail
                rechargePermissionRate
                createdAt
                tax
                apiKeyList {
                    nm
                    smsFee
                }
            }
        }
    }
`

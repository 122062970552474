import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BoardCreate.module.scss'
import {BoardType, CreateBoard} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {Title} from "../../shares/title/Title";
import {BoardEditor} from "../share/editor/BoardEditor";
import {CopyUtil} from "../../../utils/copy/CopyUtil";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";

const cx = classNamesBind.bind(styles);

export const BoardCreate = (props: {
    boardType?: BoardType
}) => {
    const [board, setBoard] = useState<CreateBoard>({
        bgColor: "#ffffff",
        boardType: BoardType.Notice,
        contents: "",
        title: ""
    });

    const [mutCreateBoard] = useMutation<{
        createBoard: boolean
    }, {
        input: CreateBoard
    }>(gqlCreate);



    useEffect(() => {
        const copy = Object.assign({}, board);
        if (props.boardType) {
            copy.boardType = props.boardType;
        } else {
            copy.boardType = BoardType.Notice
        }
        setBoard(copy);
    }, [props.boardType]);

    const onCreateBoard = () => {
        if (!board.title) {
            SwalUtil.ok({
                msg: '제목을 입력하여 주십시오.',
                icon: 'warning'
            });
            return;
        }

        if (!board.contents) {
            SwalUtil.ok({
                msg: '내용을 입력하여 주십시오.',
                icon: 'warning'
            });
            return;
        }

        mutCreateBoard({
            variables: {
                input: board
            }
        }).then(res => {
            SwalUtil.ok({
                msg: '게시 되었습니다.',
                icon: 'success'
            });

            switch (board.boardType) {
                case BoardType.Popup:
                    navigate('/board/list/Popup');
                    break;
                case  BoardType.Notice:
                    navigate('/board/list/Notice');
                    break;
                default:
                    navigate('/');
            }
        }).catch(ApolloCatch({
            [BizErrors.default]: SwalUtil.apolloError(),
        }))
    }

    return (
        <Fragment>
            <FadeInContainer>
                <div className='col-12'>
                    <BoxCont>
                        <Title>
                            {props.boardType === BoardType.Popup && "팝업공지"}
                            {props.boardType === BoardType.Notice && "공지사항"}
                        </Title>
                        <BoardEditor
                            value={board}
                            onChange={value => {
                                const copy: CreateBoard = {
                                    bgColor: value.bgColor,
                                    boardType: board.boardType,
                                    contents: value.contents,
                                    title: value.title
                                }

                                setBoard(copy);
                            }}/>
                        <div className={cx('table')}>
                            <div
                                className='text-right'
                                style={{width: '100%'}}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onCreateBoard}>저장</button>
                            </div>
                        </div>
                    </BoxCont>
                </div>

            </FadeInContainer>
        </Fragment>
    )
}

const gqlCreate = gql`
    mutation CreateBoard($input: CreateBoard!) {
        createBoard(input: $input)
    }
`;

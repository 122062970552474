import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./PointRechargeList.module.scss";
import {ClientRechargeRequestStatus, MutationCancelRechargeRequestArgs, Query} from "../../../../graphql/types";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Decimal} from "decimal.js";
import {IconButton} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import {OnChangeValue} from "../../../Interfaces";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {BadgeClientRechargeRequestStatus} from "../../../shares/badgeClientRechargeRequestStatus/BadgeClientRechargeRequestStatus";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

interface Props {
    query: Query,
    onCancelRechargeRequest: OnChangeValue<MutationCancelRechargeRequestArgs>
}

export const PointRechargeList = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(270);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "dateCell")}>요청일</div>
                    <div className={cx("header", "statusCell")}>상태</div>
                    <div className={cx("header", "valueCell")}>충전 건수</div>
                    <div className={cx("header", "pointCell")}>충전 포인트</div>
                    <div className={cx("header", "depositCell")}>입금액</div>
                    <div className={cx("header", "funcCell")}>취소</div>
                </div>

                {props.query.clientRechargeRequestList.total === 0 && <BizNoDataTable/>}

                {props.query.clientRechargeRequestList.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("dateCell")}>{moment(value.createdAt).format('MM.DD - hh:mm a')}</div>
                        <div className={cx("statusCell")}>
                            <BadgeClientRechargeRequestStatus status={value.status}/>
                        </div>
                        <div className={cx("valueCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.coupon.smsCount)} 개
                        </div>
                        <div className={cx("pointCell", "textRight")}>
                            {Pipe.toSeparatorNumber(new Decimal(value.coupon.smsCount).mul(value.coupon.smsFee).toNumber())} P
                        </div>
                        <div className={cx("depositCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.coupon.amount)} 원
                        </div>
                        <div className={cx("funcCell")}>
                            {value.status === ClientRechargeRequestStatus.Requested &&
                            <IconButton
                                size='small'
                                color='secondary'
                                onClick={() => {
                                    SwalUtil.otp(otp => {
                                        props.onCancelRechargeRequest({
                                            otp,
                                            clientRechargeRequestId: value.id,
                                        })
                                    })
                                }}>
                                <DeleteOutline/>
                            </IconButton>
                            }
                        </div>
                    </div>

                ))}
            </ScrollArea>
        </>
    )
};
import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {FB} from "../../../services/fb/FB";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {DataUtil} from "../../../utils/data/DataUtil";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import QRCODE from 'qrcode.react';
import {Mutation, MutationLoginArgs, MutationRegisterOtpArgs} from "../../../graphql/types";
import {AuthLoginView} from "./view/AuthLoginView";

export const AuthLogin = () => {
    const [state, setState] = useState<AuthLoginInput>({
        email: "",
        pw: "",
        otp: ""
    });
    const [mutLogin] = useMutation<Mutation, MutationLoginArgs>(gqlLogin);
    const [mutRegisterOtp] = useMutation<Mutation, MutationRegisterOtpArgs>(gqlRegisterOtp);
    const [registerOtpModal, setRegisterOtpModal] = useState(false);
    const [registerOtpUrl, setRegisterOtpUrl] = useState("");

    const handler = {
        onChangeValue: setState,
        onLogin: () => {
            FB.login(state)
                .then(uid => {
                    return mutLogin({variables: {uid, otp: state.otp}})
                })
                .then(res => {
                    LocalStorage.setSession(res.data!.login);
                    return DataUtil.refreshSession()
                })
                .catch(
                    ApolloCatch({
                        [BizErrors.loginLimited]: () => {
                            SwalUtil.ok({
                                msg: "로그인 제한 계정입니다.",
                                icon: "warning"
                            });
                        },
                        [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                            msg: "OTP 번호가 틀렸습니다.",
                            icon: "error"
                        })
                    })
                )
        },
        onRegisterOtp: () => {
            setRegisterOtpUrl("");
            FB.login(state)
                .then(uid => {
                    return mutRegisterOtp({variables: {uid}});
                })
                .then(res => {
                    if (res.data) {
                        setRegisterOtpUrl(res.data.registerOtp);
                        setRegisterOtpModal(true);
                    }
                })
                .catch(ApolloCatch({
                    [BizErrors.alreadyRegisteredOtp]: () => {
                        SwalUtil.ok({
                            msg: "이미 등록하셨습니다.",
                            icon: "warning"
                        })
                    }
                }));
        }
    }

    return (
        <>
            <ModalWeb
                title={'OTP 등록'}
                size={'sm'}
                isShow={registerOtpModal}
                onCancel={() => {
                    setRegisterOtpModal(false);
                }}
                onOk={() => {
                    setRegisterOtpModal(false);
                }}>
                <h5 style={{color: 'red', marginBottom: 30}}>주의 !! 다시 요청할 수 없습니다.!!!</h5>
                <div className='text-center'>
                    <QRCODE value={registerOtpUrl}/>
                </div>
            </ModalWeb>

            <AuthLoginView
                value={state}
                {...handler}/>

        </>

    )
}

export interface AuthLoginInput {
    email: string;
    pw: string;
    otp: string;
}

const gqlLogin = gql`
    mutation Login($uid: String!, $otp: String!) {
        login(uid: $uid, otp: $otp)
    }
`

const gqlRegisterOtp = gql`
    mutation RegisterOtp($uid: String!) {
        registerOtp(uid: $uid)
    }
`

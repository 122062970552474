import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ValuesDefault.module.scss'
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {Title} from "../../shares/title/Title";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {IconButton, Switch} from "@material-ui/core";
import {Add, Delete} from "@material-ui/icons";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {
    CreateTestNumber,
    Mutation,
    MutationUpdateServerStateArgs,
    Query,
    ServerState
} from "../../../graphql/types";
import {CopyUtil} from "../../../utils/copy/CopyUtil";
import moment from "moment";

const cx = classNamesBind.bind(styles);

export const ValuesDefault = () => {
    const {data, refetch} = useQuery<Query>(gqlData);

    const [mutUpdateBankNumber] = useMutation<{
        updateBankNumber: boolean
    }, {
        value: string
    }>(gqlUpdateBankNumber);

    const [mutUpdateTestNumber] = useMutation<{
        updateTestNumber: boolean,
    }, {
        value: CreateTestNumber[]
    }>(gqlUpdateTestNumber);

    const [mutUpdateServerState] = useMutation<Mutation, MutationUpdateServerStateArgs>(gqlUpdateServerState);
    const [input, setInput] = useState<ValuesDefaultInput | undefined>();


    useEffect(() => {
        if (data) {
            const nextInput: ValuesDefaultInput = {
                testNumber: data.testNumber.map(value => ({
                    testNumber: value.testNumber,
                    vendor: value.vendor,
                    testNumberWithKoreaRegionCode: value.testNumberWithKoreaRegionCode,
                    healthCheck: value.healthCheck,
                })),
                bankNumber: data.bankNumber
            }
            setInput(nextInput);
        }
    }, [data]);

    const onUpdateBankNumber = () => {
        if (input) {
            SwalUtil.yn({
                msg: '저장 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateBankNumber({
                        variables: {
                            value: input.bankNumber
                        }
                    }).then(res => {
                        SwalUtil.ok({
                            msg: '저장 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError(),
                    }))
                }
            })
        }
    }

    const onUpdateTestNumber = () => {
        if (input) {
            SwalUtil.yn({
                msg: '저장 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateTestNumber({
                        variables: {
                            value: input.testNumber.map(value => ({
                                vendor: value.vendor,
                                testNumber: value.testNumber,
                                testNumberWithKoreaRegionCode: value.testNumberWithKoreaRegionCode
                            })),
                        }
                    }).then(res => {
                        SwalUtil.ok({
                            msg: '저장 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError(),
                    }))
                }
            })

        }
    }

    const onChangeServerState = (swt: boolean) => {
        SwalUtil.otp(otp => {
            mutUpdateServerState({
                variables: {
                    value: swt ? ServerState.Ok : ServerState.ShutDown,
                    otp,
                }
            }).then(() => {
                SwalUtil.ok({
                    msg: '변경되었습니다',
                    icon: 'success'
                });
                return refetch()
            }).catch(ApolloCatch({}));
        })

    }

    return (
        <>
            {data && input &&
            <FadeInContainer>
                <div className='col-12' style={{marginBottom: 30}}>
                    <BoxCont>
                        <Title>서버 상태</Title>
                        <div className={cx("table")}>
                            <div style={{width: 100}}>
                                <Switch
                                    color='primary'
                                    checked={data.serverState === ServerState.Ok}
                                    onChange={ev => {
                                        onChangeServerState(ev.target.checked);
                                    }}/>
                            </div>
                            <div style={{width: 100}}>
                                {data.serverState === ServerState.Ok && "운영중"}
                                {data.serverState === ServerState.ShutDown && "점검중"}
                            </div>
                        </div>
                    </BoxCont>
                </div>

                <div className='col-12' style={{marginBottom: 30}}>
                    <BoxCont>
                        <Title>입금 계좌번호</Title>
                        <input
                            value={input.bankNumber}
                            className='form-control'
                            onChange={event => {
                                const copy = CopyUtil.copyAll(input!)
                                copy.bankNumber = event.target.value;
                                setInput(copy);
                            }}/>

                        <div className='text-right' style={{width: '100%', paddingTop: 10}}>
                            <button
                                className='btn btn-sm btn-success'
                                onClick={onUpdateBankNumber}>저장
                            </button>
                        </div>
                    </BoxCont>
                </div>

                <div className='col-12' style={{marginBottom: 30}}>
                    <BoxCont>
                        <Title>테스트 번호</Title>
                        {input.testNumber.map((value, index) => {
                            return (
                                <div
                                    key={index} className={cx('table')}>
                                    <div className={cx("funcCell")}>
                                        <IconButton
                                            size='small'
                                            color='secondary'
                                            onClick={event => {
                                                const copy = CopyUtil.copyAll(input!)
                                                copy.testNumber = [...copy.testNumber.slice(0, index), ...copy.testNumber.slice(index + 1, copy.testNumber.length)]
                                                setInput(copy);
                                            }}>
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                    <div className={cx("carrierCell")}>
                                        <input
                                            value={value.vendor}
                                            className='form-control'
                                            onChange={event => {
                                                const copy = CopyUtil.copyAll(input!)
                                                copy.testNumber[index].vendor = event.target.value;
                                                setInput(copy);
                                            }}/>
                                    </div>
                                    <div className={cx("phoneCell")}>
                                        <input
                                            value={value.testNumber}
                                            className='form-control'
                                            onChange={event => {
                                                const copy = CopyUtil.copyAll(input!)
                                                copy.testNumber[index].testNumber = event.target.value;
                                                copy.testNumber[index].testNumberWithKoreaRegionCode = '82' + event.target.value.substring(1, event.target.value.length)
                                                setInput(copy);
                                            }}/>
                                    </div>
                                    <div className={cx("statusCell")}>
                                        {moment().diff(value.healthCheck, 'm') > 1 ?
                                            <span className='badge badge-danger' style={{marginRight: 10}}>지연</span>
                                            :
                                            <span className='badge badge-success' style={{marginRight: 10}}>정상</span>
                                        }
                                        {moment(value.healthCheck).format('YY.MM.DD hh:mm')}
                                    </div>
                                </div>
                            )
                        })}

                        <div className={cx('table', 'hover')}
                             onClick={event => {
                                 const copy = Object.assign({}, input!);
                                 copy.testNumber.push({
                                     vendor: '',
                                     testNumber: '010',
                                     testNumberWithKoreaRegionCode: '8210',
                                     healthCheck: new Date(),
                                 })
                                 setInput(copy);
                             }}>
                            <div style={{width: '100%'}}>
                                <Add/>
                            </div>
                        </div>


                        <div className={cx('table')}>
                            <div>
                            </div>
                            <div className='text-right' style={{flexGrow: 1, paddingRight: 0}}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onUpdateTestNumber}>저장
                                </button>
                            </div>
                        </div>
                    </BoxCont>
                </div>
            </FadeInContainer>
            }
        </>
    )
}

interface ValuesDefaultInput {
    testNumber: (CreateTestNumber & { healthCheck: Date })[],
    bankNumber: string,
}

const gqlData = gql`
    query GetData {
        bankNumber
        serverState
        testNumber {
            vendor
            testNumber
            testNumberWithKoreaRegionCode
            healthCheck
        }
    }
`

const gqlUpdateBankNumber = gql`
    mutation UpdateBankNumber($value: String!) {
        updateBankNumber(value: $value)
    }
`

const gqlUpdateTestNumber = gql`
    mutation UpdateTestNumber($value: [CreateTestNumber!]!) {
        updateTestNumber(value: $value)
    }
`;

const gqlUpdateServerState = gql`
    mutation UpdateServerState($value: ServerState!, $otp: String!) {
        updateServerState(value: $value, otp: $otp)
    }
`;

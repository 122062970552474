import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './ChatDefaultList.module.scss';
import {Query} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {Pager} from "../../../shares/pager/Pager";
import {Badge} from "@material-ui/core";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";
import moment from "moment/moment";

const cx = classNamesBind.bind(styles);

export const ChatDefaultList = (props: {
    value: Query,
    onChangeChatTitlePage: OnChangeValue<number>,
    onLoadChatContent: OnChangeValue<number>
}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                {props.value.chatList.list.map((value, index) => (
                    <div key={index}
                         className={cx('table', 'hover', "scrollTable")}
                         onClick={() => {
                             props.onLoadChatContent(value.userId);
                         }}>
                        <div className={cx("numCell")}>
                            {value.userId}
                        </div>
                        <div className={cx("userCell", "textLeft")}>
                                <Badge
                                    badgeContent={value.notify}
                                    color='secondary'
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    variant={"dot"}
                                >{value.userEmail}</Badge>
                        </div>
                        <div className={cx("timeCell")}>
                            {moment(value.createdAt).format('YY.MM.DD hh:mm a')}
                        </div>
                        <div className={cx("newCell", "textRight")}>
                            {!value.isSolved && <span className='badge badge-warning'>신규</span>}
                        </div>
                    </div>
                ))}
            </ScrollArea>

            <Pager
                page={props.value.chatList.page}
                size={props.value.chatList.size}
                total={props.value.chatList.total}
                onChange={props.onChangeChatTitlePage}/>

        </>
    )
}

import React, {Fragment} from "react";
import {PointHistoryType} from "../../../graphql/types";

export const BadgeHistoryType = (props: {
    type: PointHistoryType
}) => {
    return (
        <Fragment>
            {props.type === PointHistoryType.Admin && <span className='badge badge-success'>관리자</span>}
            {props.type === PointHistoryType.Deposit && <span className='badge badge-primary'>입금확인</span>}
            {props.type === PointHistoryType.Commission && <span className='badge badge-warning'>커미션</span>}
        </Fragment>
    )
}

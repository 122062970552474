import React, {Fragment} from "react";
import {ClientRechargeRequestStatus} from "../../../graphql/types";

export const BadgeClientRechargeRequestStatus = (props: {
    status: ClientRechargeRequestStatus
}) => {
    return (
        <Fragment>
            {ClientRechargeRequestStatus.Requested === props.status && <span className='badge badge-warning'>요청대기</span>}
            {ClientRechargeRequestStatus.Allow === props.status && <span className='badge badge-success'>승인완료</span>}
            {ClientRechargeRequestStatus.Deny === props.status && <span className='badge badge-danger'>승인거절</span>}
            {ClientRechargeRequestStatus.UserCancel === props.status && <span className='badge badge-secondary'>취소</span>}
        </Fragment>
    )
}

import React, {Fragment} from "react";
import {DepositRequestStatus} from "../../../graphql/types";

export const BadgeDepositRechargeStatus = (props: {
    value: DepositRequestStatus
}) => {
    return (
        <Fragment>
            {props.value === DepositRequestStatus.Allow && <span className='badge badge-success'>승인</span>}
            {props.value === DepositRequestStatus.Wait && <span className='badge badge-warning'>대기</span>}
            {props.value === DepositRequestStatus.Deny && <span className='badge badge-danger'>반려</span>}
            {props.value === DepositRequestStatus.Cancel && <span className='badge badge-secondary'>취소</span>}
        </Fragment>
    )
}

import React from "react";
import classNamesBind from "classnames/bind";
import styles from './UserAgentList.module.scss'
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {A} from "hookrouter";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {Pager} from "../../../../shares/pager/Pager";
import {Query} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Interfaces";

const cx = classNamesBind.bind(styles);

interface Props {
    query: Query,
    onChangeAgentPage: OnChangeValue<number>
}

export const UserAgentList = (props: Props) => {
    return (
        <>
            <ViewSelector
                mobile={<Mobile {...props}/>}
                web={<Web {...props}/>}
            />

            {props.query.userChildren.total === 0 &&
            <div className={cx('table')}>
                <div style={{width: '100%'}}>등록된 대리점이 없습니다.</div>
            </div>
            }

            <Pager
                page={props.query.userChildren.page}
                size={props.query.userChildren.size}
                total={props.query.userChildren.total}
                onChange={props.onChangeAgentPage}/>
        </>

    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '10%'}}>번호</div>
                <div style={{width: '15%'}}>등급</div>
                <div style={{width: '30%'}}>유저</div>
                <div style={{width: '15%'}}>회선정보</div>
                <div style={{width: '30%'}}>포인트</div>
            </div>


            {props.query.userChildren.user.map((value, index) => {
                return (
                    <A href={`/user/view/${value.id}/${value.userType}`}
                       key={index}
                       style={{color: 'black'}}>
                        <div key={index} className={cx('table', 'hover')}>
                            <div style={{width: '10%'}}>{value.id}</div>
                            <div style={{width: '15%'}}>{value.userType}</div>
                            <div style={{width: '30%'}}>{value.email}</div>
                            <div style={{width: '15%'}}>
                                {value.apiPermission.map((apiKey, idx) => (
                                    <div style={{padding: 0}} key={idx}>{apiKey.nm} : {apiKey.smsFee} 원</div>
                                ))}
                            </div>
                            <div className='text-right'
                                 style={{width: '30%'}}>{Pipe.toSeparatorNumber(value.point)} P
                            </div>
                        </div>
                    </A>
                )
            })}


        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            {props.query.userChildren.user.map((value, index) => (
                <A href={`/user/view/${value.id}/${value.userType}`} key={index}>
                    <div className={cx('table-mb')}>
                        <div className={cx('email')}>{value.email}</div>
                        <div className={cx('point')}>{Pipe.toSeparatorNumber(value.point)} P</div>
                    </div>
                </A>

            ))}
        </>
    )
}


import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserApiKeyWithGeneral.module.scss'
import {
    HashedApiKey,
    UpdateUserApiPermission,
    User
} from "../../../../../graphql/types";
import {Title} from "../../../../shares/title/Title";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import {Switch} from "@material-ui/core";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import Decimal from "decimal.js";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {OnChangeValue, OnVoidFunction} from "../../../../Interfaces";

const cx = classNamesBind.bind(styles);

type ApiPermission = (HashedApiKey & { userSmsFee: number, userNm: string, isActivate: boolean })[];

interface Props {
    user: User,
    apiKeyList: HashedApiKey[],
    onUpdateUserApiPermission: (input: UpdateUserApiPermission) => void,
}

type CompProps = Props & {
    input: ApiPermission,
    setInput: OnChangeValue<ApiPermission>,
    onClickSave: OnVoidFunction
};

export const UserApiKeyWithGeneral = (props: Props) => {
    const [input, setInput] = useState<ApiPermission>([]);

    useEffect(() => {
        const nextInput: ApiPermission = props.apiKeyList.map(value => {
            return {
                isActivate: false,
                hashedApiKey: value.hashedApiKey,
                nm: value.nm,
                smsFee: value.smsFee,
                userNm: value.nm,
                userSmsFee: value.smsFee
            }
        });

        for (let elem of nextInput) {
            const userApiPermission = props.user.apiPermission.find(value => {
                return value.hashedApiKey === elem.hashedApiKey;
            });

            if (!userApiPermission) {
                continue;
            }

            elem.isActivate = true;
            elem.userNm = userApiPermission.nm;
            elem.userSmsFee = userApiPermission.smsFee;
        }

        setInput(nextInput);
    }, [props.user, props.apiKeyList]);

    const handler = {
        input,
        setInput,
        onClickSave: () => {
            const args: UpdateUserApiPermission = {
                userId: props.user.id,
                data: []
            }

            for (let elem of input) {
                if (!elem.isActivate) {
                    continue;
                }

                if (elem.userNm === "") {
                    SwalUtil.ok({
                        msg: '활성화된 회선에는 이름을 지정하셔야 합니다.',
                        icon: 'error',
                    })
                    return;
                }

                if (new Decimal(elem.userSmsFee).lt(elem.smsFee)) {
                    SwalUtil.ok({
                        msg: '건당가격을 회선가격보다 낮게 책정할수 없습니다.',
                        icon: 'error'
                    });
                    return;
                }

                args.data.push({
                    hashedApiKey: elem.hashedApiKey,
                    nm: elem.userNm,
                    smsFee: elem.userSmsFee,
                })
            }

            if (args.data.length === 0) {
                SwalUtil.ok({
                    msg: '회선은 최소 1개 이상 활성화 하셔야 합니다.',
                    icon: 'error'
                });
                return;
            }

            props.onUpdateUserApiPermission(args);
        }
    }


    return (
        <>
            <ViewSelector
                web={<Web {...handler} {...props}/>}
                mobile={<Mobile {...handler} {...props}/>}
            />
            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onClickSave}>적용
                    </button>
                </div>
            </div>
        </>

    )
}

const Mobile = (props: CompProps) => {
    return (
        <>
            <Title>회선 정보</Title>
            {props.input.map((value, index) => (
                <div key={index} className={cx('cont-mb')}>
                    <div className={cx('activate')}>
                        <Switch
                            color='primary'
                            checked={value.isActivate}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].isActivate = ev.target.checked;
                                props.setInput(copy);
                            }}/>
                    </div>
                    <div className={cx('api-nm')}>
                        {value.nm}
                    </div>
                    <div className={cx('api-sms-fee')}>
                        {value.smsFee} 원
                    </div>

                    <div className={cx('half-width')}>
                        <input
                            className='form-control form-control-sm text-center'
                            value={value.userNm}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].userNm = ev.target.value;
                                props.setInput(copy);
                            }}
                        />
                    </div>
                    <div className={cx('half-width')}>
                        <NumberFormat
                            suffix={' 원'}
                            allowNegative={false}
                            decimalScale={1}
                            className='form-control form-control-sm text-right'
                            value={value.userSmsFee}
                            onValueChange={values => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].userSmsFee = values.floatValue || 0;
                                props.setInput(copy);
                            }}/>
                    </div>
                </div>
            ))}
        </>
    )
}

const Web = (props: CompProps) => {
    return (
        <>
            <Title>회선 정보</Title>
            <div className={cx('table')}>
                <div style={{width: '20%'}}>활성화</div>
                <div style={{width: '20%'}}>회선이름</div>
                <div style={{width: '20%'}}>회선가격</div>
                <div style={{width: '20%'}}>이름</div>
                <div style={{width: '20%'}}>건당가격</div>
            </div>

            {props.input.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div style={{width: '20%'}}>
                        <Switch
                            color='primary'
                            checked={value.isActivate}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].isActivate = ev.target.checked;
                                props.setInput(copy);
                            }}/>
                    </div>
                    <div style={{width: '20%'}}>
                        {value.nm}
                    </div>
                    <div style={{width: '20%'}}>
                        {value.smsFee} 원
                    </div>
                    <div style={{width: '20%'}}>
                        <input
                            className='form-control form-control-sm text-center'
                            value={value.userNm}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].userNm = ev.target.value;
                                props.setInput(copy);
                            }}
                        />
                    </div>
                    <div style={{width: '20%'}}>
                        <NumberFormat
                            suffix={' 원'}
                            allowNegative={false}
                            decimalScale={1}
                            className='form-control form-control-sm text-right'
                            value={value.userSmsFee}
                            onValueChange={values => {
                                const copy = CopyUtil.copyAll(props.input);
                                copy[index].userSmsFee = values.floatValue || 0;
                                props.setInput(copy);
                            }}/>
                    </div>
                </div>
            ))
            }
        </>
    )
}

import React, {Fragment, ReactNode, useState} from "react";
import gql from "graphql-tag";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    IUpdateUserPassword,
    Mutation,
    MutationCreateUserAlertArgs,
    MutationDeleteUserAlertArgs,
    Query,
    QueryUserTelBookArgs, QueryUserTelBookWithPeriodArgs,
    UpdateUser,
    UpdateUserApiPermission,
    UserType
} from "../../../../graphql/types";
import {UserDefView} from "../share/userDefView/UserDefView";
import {FadeInContainer} from "../../../shares/fadeInContainer/FadeInContainer";
import {UserBalanceListView} from "../share/userBalanceListView/UserBalanceListView";
import {UserMsgHistory} from "../share/userMsgHistory/UserMsgHistory";
import {BoxCont} from "../../../shares/boxCont/BoxCont";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {BizErrors} from "../../../../graphql/errors";
import {UserAdminRecharge} from "../share/userAdminRecharge/UserAdminRecharge";
import {UserApiKeyWithGeneral} from "../share/userApiKeyWithGeneral/UserApiKeyWithGeneral";
import {UserAlert} from "../share/userAlert/UserAlert";
import {saveAs} from 'file-saver';
import {UserApiWithReseller} from "../share/userApiKeyWithReseller/UserApiWithReseller";
import {UserTelBookWithPeriod} from "../share/userTelBookWithPeriod/UserTelBookWithPeriod";
import moment from "moment";
import {BizCol12} from "../../../shares/bizCol12/BizCol12";
import {Title} from "../../../shares/title/Title";
import {UserSmsSendGraph} from "../share/userSmsSendGraph/UserSmsSendGraph";
import {UserChargeUsageGraph} from "../share/userChargeUsageGraph/UserChargeUsageGraph";
import {BizPeriodPicker, Period} from "../../../shares/bizPeriodPicker/BizPeriodPicker";
import {Pipe} from "../../../../utils/pipe/pipe";
import {ModalWeb} from "../../../shares/modal/ModalWeb";
import {UserTelBookModal} from "../share/userTelBookModal/UserTelBookModal";
import {ExcelUtil} from "../../../../utils/excel/ExcelUtil";
import UserLoginGraph from "../share/userLoginGraph/UserLoginGraph";

interface ReqParams {
    id: number,
    balancePage: number,
    msgPage: number,
    userAlertPage: number,
    usageStDate: number,
    usageEdDate: number,
}

export const UserViewGeneral = (props: {
    id: number,
    onReloaded?: () => void,
    children?: ReactNode
}) => {
    const {data, refetch} = useQuery<Query, ReqParams>(gqlData, {
        variables: {
            id: Number(props.id),
            msgPage: 0,
            balancePage: 0,
            userAlertPage: 0,
            usageStDate: Number(moment().days(-7).format('YYYYMMDD')),
            usageEdDate: Number(moment().format('YYYYMMDD')),
        }
    });

    const [mutUpdateUser] = useMutation<{
        updateUser: boolean
    }, {
        input: UpdateUser
    }>(gqlUpdateUser);

    const [mutUpdatePassword] = useMutation<{
        updatePassword: boolean
    }, {
        input: IUpdateUserPassword
    }>(gqlUpdatePassword);

    const [mutIssuePoint] = useMutation<{
        issuePoint: boolean
    }, {
        userId: number,
        point: number,
        otp: string,
        memo: string
    }>(gqlIssuePoint);

    const [mutUpdateUserApiPermission] = useMutation<any, {
        input: UpdateUserApiPermission
    }>(gqlUpdateUserApiPermission);

    const [mutCreateUserAlert] = useMutation<Mutation, MutationCreateUserAlertArgs>(gqlCreateUserAlert);

    const [mutDeleteUserAlert] = useMutation<Mutation, MutationDeleteUserAlertArgs>(gqlDeleteUserAlert);

    const [getTelBookWithPeriod] = useLazyQuery<Query, QueryUserTelBookWithPeriodArgs>(gqlUserTelBookWithPeriod, {
        onCompleted: res => {
            if (!data) {
                return;
            }

            ExcelUtil.downloadTelBook(data.userWithId.email, res.userTelBookWithPeriod);

            //
            // let page = "";
            // for (let telNumber of res.userTelBookWithPeriod) {
            //     page += `${telNumber.number},${telNumber.status}\n`
            // }
            // let saveData = new Blob([page], {type: 'application/csv'});
            // saveAs(saveData, `${data.userWithId.email}-${moment().format('YYMMDD')}.csv`);
        }
    })

    const [getDownloadTelBook] = useLazyQuery<Query, QueryUserTelBookArgs>(gqlUserTelBook, {
        onCompleted: value => {
            let page = "전화번호,상태\n";
            for (let telNumber of value.userTelBook.numberList) {
                page += `${telNumber.number},${telNumber.status}\n`;
            }

            let dataValue = new Blob([page], {type: 'application/csv'});
            saveAs(dataValue, `${data?.userWithId.email}-${value.userTelBook.requestId}.csv`);
        }
    });

    const [getTelBookModal, resTelBookModal] = useLazyQuery<Query, QueryUserTelBookArgs>(gqlUserTelBook);
    const [telBookModal, setTelBookModal] = useState(false);


    const [graphPeriod, setGraphPeriod] = useState<Period>({
        stDate: moment().days(-7).toDate(),
        edDate: moment().toDate(),
    })

    const onChangeBalancePage = (page: number) => {
        if (data) {
            refetch({
                id: Number(props.id),
                msgPage: data.userMsgHistoryList.page,
                balancePage: page,
                userAlertPage: data.userAlert.page,
            }).catch(ApolloCatch({}));
        }
    }

    const onUpdateUser = (input: UpdateUser) => {
        SwalUtil.yn({
            msg: '변경하시겠습니까?',
            icon: "question",
            ok: () => {
                mutUpdateUser({variables: {input}})
                    .then(_ => {
                        return refetch({
                            id: Number(props.id),
                            msgPage: 0,
                            balancePage: 0,
                            userAlertPage: 0,
                        })
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        })
    }

    const onUpdatePassword = (input: IUpdateUserPassword) => {
        SwalUtil.yn({
            msg: "변경하시겠습니까?",
            icon: "question",
            ok: () => {
                mutUpdatePassword({variables: {input}})
                    .then(_ => {
                        SwalUtil.ok({
                            msg: "변경 되었습니다.",
                            icon: "success"
                        })
                    })
                    .then(_ => {
                        return refetch({
                            id: Number(props.id),
                            msgPage: 0,
                            balancePage: 0,
                            userAlertPage: 0
                        })
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))
            }
        })
    };

    const onChargePoint = (userId: number, point: number, otp: string, memo: string) => {
        SwalUtil.yn({
            msg: point < 0 ? '회수 하시겠습니까?' : '충전 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutIssuePoint({
                    variables: {userId, point, otp, memo}
                }).then(_ => {
                    return refetch({
                        id: Number(props.id),
                        msgPage: 0,
                        balancePage: 0,
                        userAlertPage: 0,
                    })
                }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.apolloError(),
                    [BizErrors.failVerifyOTP]: SwalUtil.apolloErrorOk({
                        msg: 'OTP 번호가 틀립니다.',
                        icon: 'error'
                    })
                }))
            }
        })
    }

    const onChangeMsgHistory = (page: number) => {
        if (data) {
            refetch({
                id: Number(props.id),
                msgPage: page,
                balancePage: data.userBalanceHistoryList.page,
                userAlertPage: data.userAlert.page,
            }).catch(ApolloCatch({}))
        }
    }

    const onUpdateUserApiPermission = (input: UpdateUserApiPermission) => {
        SwalUtil.yn({
            msg: 'API Key 정보를 업데이트 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutUpdateUserApiPermission({variables: {input}})
                    .then(_ => {
                        SwalUtil.ok({
                            msg: '업데이트 되었습니다.',
                            icon: "success",
                        })
                        return refetch({
                            id: Number(props.id),
                            msgPage: 0,
                            balancePage: 0,
                            userAlertPage: 0,
                        });
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.apolloError()
                    }))

            }
        })
    }

    const handler = {
        onInitPage: () => {
            if (data) {
                refetch({
                    id: Number(props.id),
                    msgPage: data.userMsgHistoryList.page,
                    balancePage: data.userBalanceHistoryList.page,
                    userAlertPage: data.userAlert.page,
                    usageStDate: Pipe.toTimeDay(graphPeriod.stDate),
                    usageEdDate: Pipe.toTimeDay(graphPeriod.edDate)
                }).catch(ApolloCatch({}));
            }
        },
        onChangeUserAlertPage: (page: number) => {
            if (data) {
                refetch({
                    id: Number(props.id),
                    msgPage: data.userMsgHistoryList.page,
                    balancePage: data.userBalanceHistoryList.page,
                    userAlertPage: page,
                    usageStDate: Pipe.toTimeDay(graphPeriod.stDate),
                    usageEdDate: Pipe.toTimeDay(graphPeriod.edDate)
                }).catch(ApolloCatch({}));
            }
        },
        onDeleteUserAlert: (args: MutationDeleteUserAlertArgs) => {
            SwalUtil.yn({
                msg: '삭제하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutDeleteUserAlert({variables: args})
                        .then(() => {
                            handler.onInitPage();
                        })
                        .catch(ApolloCatch({}));
                }
            })

        },
        onCreateUserAlert: (args: MutationCreateUserAlertArgs) => {
            SwalUtil.yn({
                msg: '추가하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutCreateUserAlert({variables: args})
                        .then(() => {
                            handler.onInitPage();
                        })
                        .catch(ApolloCatch({}));
                }
            })
        },
        onDownloadUserTelBook: (args: QueryUserTelBookArgs) => {
            getDownloadTelBook({
                variables: args,
            })
        },
        onTelBookWithPeriod: (args: QueryUserTelBookWithPeriodArgs) => {
            SwalUtil.yn({
                msg: '다운로드 하시겠습니까?<br/>(시간이 다소 걸릴 수 있습니다.)',
                icon: 'question',
                ok: () => {
                    getTelBookWithPeriod({variables: args});
                }
            });
        },
        onChangeGraphPeriod: () => {
            if (!data) {
                return;
            }

            const diffDays = moment(graphPeriod.edDate).diff(graphPeriod.stDate, 'd');
            if (30 < diffDays) {
                SwalUtil.ok({
                    msg: '30일 이상의 기간은 선택할 수 없습니다.',
                    icon: 'error'
                });
                return;
            }

            refetch({
                id: Number(props.id),
                msgPage: data.userMsgHistoryList.page,
                balancePage: data.userBalanceHistoryList.page,
                userAlertPage: data.userAlert.page,
                usageStDate: Pipe.toTimeDay(graphPeriod.stDate),
                usageEdDate: Pipe.toTimeDay(graphPeriod.edDate)
            }).catch(ApolloCatch({}));
        },
        onClickMsgReceipt: (requestId: string) => {
            getTelBookModal({
                variables: {requestId}
            });
            setTelBookModal(true);
        }
    }


    return (
        <Fragment>
            <ModalWeb
                title={''}
                isShow={telBookModal && Boolean(resTelBookModal.data)}
                onCancel={() => {
                    setTelBookModal(false);
                }}
                onOk={() => {
                    setTelBookModal(false);
                }}>
                {resTelBookModal.data &&
                    <UserTelBookModal
                        telBook={resTelBookModal.data.userTelBook}/>
                }
            </ModalWeb>

            {data &&
                <FadeInContainer>
                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserDefView
                                user={data.userWithId}
                                userHistory={data.userHistory}
                                userCategory={data.userCategoryList}
                                onUpdateUser={onUpdateUser}
                                onUpdatePassword={onUpdatePassword}
                            />
                        </BoxCont>
                    </div>

                    <BizCol12>
                        <Title>그래프 기간 선택</Title>
                        <hr/>
                        <BizPeriodPicker
                            period={graphPeriod}
                            onChange={setGraphPeriod}>

                        </BizPeriodPicker>
                        <hr/>
                        <div className='text-right' style={{marginRight: 10}}>
                            <button
                                className='btn btn-sm btn-success'
                                onClick={handler.onChangeGraphPeriod}>조회
                            </button>
                        </div>
                    </BizCol12>

                    <BizCol12>
                        <Title>사용량 그래프</Title>
                        <UserSmsSendGraph list={data.userUsageReport}/>
                    </BizCol12>

                    <BizCol12>
                        <Title>로그인 그래프</Title>
                        <UserLoginGraph history={data.userLoginReport}/>
                    </BizCol12>

                    <BizCol12>
                        <Title>포인트 사용 그래프</Title>
                        <UserChargeUsageGraph list={data.userUsageReport}/>
                    </BizCol12>

                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserAlert
                                userId={data.userWithId.id}
                                alert={data.userAlert}
                                {...handler}/>
                        </BoxCont>
                    </div>


                    {((data.userWithId.userType === UserType.General) || (data.userWithId.userType === UserType.Tree)) &&
                        <div className='col-12' style={{marginBottom: 30}}>
                            <BoxCont>
                                <UserApiKeyWithGeneral
                                    user={data.userWithId}
                                    apiKeyList={data.apiKeyList}
                                    onUpdateUserApiPermission={onUpdateUserApiPermission}/>
                            </BoxCont>
                        </div>
                    }

                    {((data.userWithId.userType === UserType.Reseller) || (data.userWithId.userType === UserType.Agent)) &&
                        <div className='col-12' style={{marginBottom: 30}}>
                            <BoxCont>
                                <UserApiWithReseller
                                    user={data.userWithId}
                                    apiKeyList={data.apiKeyList}
                                    onUpdateUserApiPermission={onUpdateUserApiPermission}/>
                            </BoxCont>
                        </div>
                    }

                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserAdminRecharge
                                userId={data.userWithId.id}
                                onChargePoint={onChargePoint}/>
                        </BoxCont>
                    </div>

                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserTelBookWithPeriod
                                userId={data.userWithId.id}
                                {...handler}/>
                        </BoxCont>
                    </div>


                    {props.children && props.children}


                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserBalanceListView
                                history={data.userBalanceHistoryList}
                                onChangePage={onChangeBalancePage}/>
                        </BoxCont>

                    </div>

                    <div className='col-12' style={{marginBottom: 30}}>
                        <BoxCont>
                            <UserMsgHistory
                                userMsgHistoryList={data.userMsgHistoryList}
                                onChangePage={onChangeMsgHistory}
                                {...handler}
                            />
                        </BoxCont>
                    </div>


                </FadeInContainer>
            }
        </Fragment>
    )
}

const gqlData = gql`
    query General($id: Int!, $balancePage: Int!, $msgPage: Int!, $userAlertPage: Int!, $usageStDate: Int!, $usageEdDate: Int!) {
        userCategoryList {
            id
            nm
        }
        apiKeyList {
            hashedApiKey
            nm
            smsFee
        }
        userWithId(id: $id) {
            id
            email
            point
            tax
            userType
            createdAt
            isActivate
            memo
            contact
            msgPermission
            apiPermission {
                hashedApiKey
                nm
                smsFee
            }
            categoryNm
            categoryId
        }
        userMsgHistoryList(id: $id, page: $msgPage, size: 10) {
            page
            size
            total
            list {
                id
                requestId
                point
                prevPoint
                changedPoint
                sendTotal
                contents
                createdAt
            }
        }
        userBalanceHistoryList(id: $id, page: $balancePage, size: 10) {
            page
            size
            total
            list {
                id
                receiptType
                point
                prevPoint
                changedPoint
                createdAt
                memo
            }
        }
        userAlert(userId: $id, page: $userAlertPage, size: 10) {
            page
            size
            total
            list {
                id
                adminEmail
                hasView
                alertType
                content
                createdAt
            }
        }
        userUsageReport(userId: $id, stDate: $usageStDate, edDate: $usageEdDate) {
            chargeAmount
            chargePoint
            point
            profit
            sentSms
            taxAmount
            userEmail
            userId
            userType
            createdAt
        }
        userHistory(userId: $id) {
            id
            createdAt
            updatedAt
        }
        userLoginReport(userId: $id, stDate: $usageStDate, edDate: $usageEdDate) {
            userId
            createdAt
            count
        }
    }
`;

const gqlUserTelBookWithPeriod = gql`
    query TelBookWithPeriod($userId: Int!, $stDate: Int!, $edDate: Int!){
        userTelBookWithPeriod(userId: $userId, stDate: $stDate, edDate: $edDate) {
            number
            status
        }
    }
`;


const gqlUpdateUser = gql`
    mutation UpdateUser($input: UpdateUser!) {
        updateUser(input: $input)
    }
`;

const gqlUpdatePassword = gql`
    mutation UpdatePassword($input: IUpdateUserPassword!) {
        updateUserPassword(i: $input)
    }
`;

const gqlIssuePoint = gql`
    mutation IssuePoint($userId: Int!, $point: Decimal!, $otp: String!, $memo: String!) {
        issuePoint(userId: $userId, point: $point, otp: $otp, memo: $memo)
    }
`;

const gqlUpdateUserApiPermission = gql`
    mutation UpdateUserApiPermission($input: UpdateUserApiPermission!) {
        updateUserApiPermission(input: $input)
    }
`;

const gqlCreateUserAlert = gql`
    mutation CreateUserAlert($input: CreateUserAlert!) {
        createUserAlert(input: $input)
    }
`;

const gqlDeleteUserAlert = gql`
    mutation DeleteUserAlert($userAlertId: Int!) {
        deleteUserAlert(userAlertId: $userAlertId)
    }
`;

const gqlUserTelBook = gql`
    query userTelBook($requestId: String!) {
        userTelBook(requestId: $requestId) {
            requestId
            msg
            requestTotal
            totalSend
            sent
            fail
            numberList {
                number
                status
            }
            createdAt
        }
    }
`;

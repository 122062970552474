import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './PointRequestList.module.scss'
import {RequestDepositRecharge} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import moment from "moment";
import NumberFormat from "react-number-format";
import {Check, Close} from "@material-ui/icons";
import {ViewSelector} from "../../../shares/viewSelector/ViewSelector";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Button} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

interface Props {
    list: RequestDepositRecharge[],
    onAllow: OnChangeValue<number>,
    onDeny: OnChangeValue<number>,
}

export const PointRequestList = (props: Props) => {
    return (
        <Fragment>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </Fragment>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            {props.list.map((value, index) => (
                <div className={cx('table-mb')} key={index}>
                    <div className={cx('nm')}>{value.nm}</div>
                    <div className={cx('amount')}>{Pipe.toSeparatorNumber(value.amount)} 원</div>

                    <div className={cx('label')}>포인트</div>
                    <div className={cx('label-value')} style={{paddingRight: 10}}>{Pipe.toSeparatorNumber(value.point)} P</div>


                    <div className={cx('label')}>부가세</div>
                    <div className={cx('label-value')}>{Pipe.toSeparatorNumber(value.tax)} 원</div>

                    <div className={cx('label')}>연락처</div>
                    <div className={cx('label-value', 'text-center')} style={{paddingRight: 10}}>{value.tel || '-'}</div>

                    <div className={cx('label')}>요청일</div>
                    <div className={cx('label-value')}>{moment(value.createdAt).format('YY.MM.DD - hh:mm a')}</div>

                    <div className={cx('func')}>
                        <Button
                            startIcon={<Close/>}
                            size='small'
                            color='secondary'
                            variant='outlined'
                            onClick={event => {
                                props.onDeny(value.id);
                            }}>
                            거절
                        </Button>
                        <Button
                            startIcon={<Check/>}
                            size='small'
                            color='primary'
                            variant='contained'
                            onClick={event => {
                                props.onAllow(value.id);
                            }}>
                             승인
                        </Button>
                    </div>
                </div>
            ))}
        </>
    )
}


const Web = (props: Props) => {
    return (
        <>
            <div className={cx('table')}>
                <div style={{width: '15%'}}>요청일</div>
                <div style={{width: '15%'}}>입금자명</div>
                <div style={{width: '20%'}}>유저</div>
                <div style={{width: '20%'}}>입금액</div>
                <div style={{width: '15%'}}>확인전송</div>
                <div style={{width: '15%'}}>기능</div>
            </div>

            {props.list.length === 0 &&
            <div className={cx('table')}>
                <div style={{flexGrow: 1}}>입금확인요청이 없습니다.</div>
            </div>
            }


            {props.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div
                            style={{width: '15%'}}>{moment(value.createdAt).format('YY.MM.DD - hh:mm a')}</div>
                        <div style={{width: '15%'}}>{value.nm}</div>
                        <div style={{width: '20%'}}>{value.userEmail}</div>
                        <div style={{width: '20%', padding: '5px 0'}}>
                            <div className={cx('box-deposit', 'text-right')}>
                                <div className={cx('amount')}>
                                    <NumberFormat
                                        value={value.amount}
                                        displayType='text'
                                        suffix={' 원'}
                                        thousandSeparator/>
                                </div>
                                <div className={cx('info')}>
                                    <NumberFormat
                                        value={value.point}
                                        prefix={'포인트 : '}
                                        suffix={' P'}
                                        displayType='text'
                                        thousandSeparator/>
                                </div>
                            </div>

                        </div>
                        <div style={{width: '15%'}}>
                            {value.sendSms &&
                            <Fragment>
                                {value.tel}
                            </Fragment>
                            }

                            {!value.sendSms &&
                            <Fragment>
                                <span className='badge badge-secondary'>미입력</span>
                            </Fragment>
                            }
                        </div>
                        <div style={{width: '15%'}}>
                            <button
                                className='btn btn-sm btn-success'
                                style={{marginRight: 10}}
                                onClick={event => {
                                    props.onAllow(value.id);
                                }}>
                                <Check/>
                            </button>
                            <button
                                className='btn btn-sm btn-outline-danger'
                                onClick={event => {
                                    props.onDeny(value.id);
                                }}>
                                <Close/>
                            </button>

                        </div>
                    </div>
                )
            })}
        </>
    )
}

import React, {Fragment} from "react";

export const BadgeIsActivate = (props: {
    isActivate: boolean
}) => {
    return (
        <Fragment>
            {props.isActivate && <span className='badge badge-success'>활성화</span>}
            {!props.isActivate && <span className='badge badge-danger'>비활성화</span>}
        </Fragment>
    )
}

import React, {Fragment} from "react";
import {UserLoginReport} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import moment from "moment/moment";
import Chart from "react-apexcharts";

function UserLoginGraph (props: {
    history: UserLoginReport[]
}) {
    const options: ApexOptions = {
        title: {text:"로그인 그래프"},
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        xaxis: {
            categories: props.history.map(value => {
                return [moment(value.createdAt).format('MM.DD')]
            }),
        },
        yaxis: {
            tickAmount: 1
        },
        markers: {
            size: 0
        },
        stroke: {
            curve: 'straight'
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
        },
    }

    const series = [
        {
            name: '로그인 횟수',
            type: 'line',
            data: props.history.map(value => {
                return value.count
            })
        },
    ];

    return (
        <Fragment>
            {/*{props.list.length === 0 &&*/}
            {/*<BizNoDataTable/>*/}
            {/*}*/}

            {/*{props.list.length !== 0 &&*/}
            <Chart
                options={options}
                series={series}
                width={'100%'}
                height={'300px'}/>
            {/*}*/}
        </Fragment>
    )
}

export default UserLoginGraph;

import React, {ReactNode} from "react";
import {useSpring, animated} from "react-spring";

export const FadeInContainer = (props: {
    children: ReactNode,
    defaultTopMargin?: boolean
}) => {
    const [contProps, setContProps] = useSpring(() => ({opacity: 0}));
    setContProps({opacity: 1})

    return (
        <animated.div style={contProps}>
            {props.defaultTopMargin && <div style={{height: 30}}/>}
            <div className='container-fluid' style={{maxWidth: 2000}}>
                <div className='row'>
                    {props.children}
                </div>
            </div>
        </animated.div>
    )
}

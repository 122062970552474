export class LocalStorage {

    static ALERT_SOUND = 'alertSound';

    static setSession(token: string) {
        localStorage.setItem('api-token', token);
    }

    static getSession(): string {
        return localStorage.getItem('api-token') || '';
    }

    static clearSession() {
        localStorage.removeItem('api-token');
    }

    static setLangCode(langCode: string) {
        localStorage.setItem("langCode", langCode);
    }

    static getLangCode(): string {
        return localStorage.getItem("langCode") || 'en';
    }

    static getAlertSound(): boolean {
        const value = localStorage.getItem(this.ALERT_SOUND);
        return !!(value && value == 'true');
    }

    static setAlertSound(swt: boolean) {
        localStorage.setItem(this.ALERT_SOUND, swt ? 'true' : 'false');
    }
}

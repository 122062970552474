export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
  Map: any;
  Time: any;
  Upload: any;
};


export type Admin = {
  __typename?: 'Admin';
  id: Scalars['Int'];
  email: Scalars['String'];
};

export type Agent = User & {
  __typename?: 'Agent';
  id: Scalars['Int'];
  contact: Scalars['String'];
  email: Scalars['String'];
  apiPermission: Array<HashedApiKey>;
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  userType: UserType;
  createdAt: Scalars['Time'];
  reseller: Reseller;
  isActivate: Scalars['Boolean'];
  memo: Scalars['String'];
  msgPermission: Array<MsgPermission>;
  categoryId: Scalars['Int'];
  categoryNm: Scalars['String'];
};

export enum AlertType {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success'
}

export enum BalanceReceiptType {
  Init = 'Init',
  Admin = 'Admin',
  Msg = 'Msg',
  Deposit = 'Deposit',
  SharePoint = 'SharePoint',
  SendTemplate = 'SendTemplate',
  SendBulk = 'SendBulk',
  Commission = 'Commission'
}

export type Board = {
  __typename?: 'Board';
  id: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
  adminEmail: Scalars['String'];
  isActivate: Scalars['Boolean'];
  bgColor: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type BoardList = {
  __typename?: 'BoardList';
  size: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Board>;
};

export enum BoardType {
  Popup = 'Popup',
  Notice = 'Notice'
}

export type ChatAlert = {
  __typename?: 'ChatAlert';
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  chatCount: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export type ChatContent = {
  __typename?: 'ChatContent';
  id: Scalars['Int'];
  content: Scalars['String'];
  writer: WriterType;
  userEmail: Scalars['String'];
  adminEmail: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type ChatContentList = {
  __typename?: 'ChatContentList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<ChatContent>;
};

export type ChatList = {
  __typename?: 'ChatList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<ChatTitle>;
};

export type ChatTitle = {
  __typename?: 'ChatTitle';
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  notify: Scalars['Int'];
  isSolved: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export type ClientApiPermission = {
  __typename?: 'ClientApiPermission';
  hashedApiKey: Scalars['String'];
  smsFee: Scalars['Decimal'];
  nm: Scalars['String'];
  userDefalut: Scalars['Boolean'];
  defalutUserSmsFee: Scalars['Decimal'];
  systemDefalut: Scalars['Boolean'];
};

export type ClientRechargeCoupon = {
  __typename?: 'ClientRechargeCoupon';
  id: Scalars['Int'];
  smsCount: Scalars['Int'];
  smsFee: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
};

export type ClientRechargeRequest = {
  __typename?: 'ClientRechargeRequest';
  id: Scalars['Int'];
  status: ClientRechargeRequestStatus;
  coupon: ClientRechargeCoupon;
  createdAt: Scalars['Time'];
};

export type ClientRechargeRequestList = {
  __typename?: 'ClientRechargeRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<ClientRechargeRequest>;
};

export enum ClientRechargeRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  UserCancel = 'UserCancel'
}

export enum CompanyBalance {
  DepositRecharge = 'DepositRecharge',
  CommissionWithdrawal = 'CommissionWithdrawal',
  Custom = 'Custom'
}

export type CompanyBalances = {
  __typename?: 'CompanyBalances';
  totalDeposit: Scalars['Decimal'];
  totalWithdraw: Scalars['Decimal'];
  totalMargin: Scalars['Decimal'];
  deposit: Array<DepositWithdraw>;
  withdraw: Array<DepositWithdraw>;
};

export type CreateBoard = {
  title: Scalars['String'];
  boardType: BoardType;
  contents: Scalars['String'];
  bgColor: Scalars['String'];
};

export type CreateChat = {
  userId: Scalars['Int'];
  content: Scalars['String'];
};

export type CreateCompanyBalanceDepositWithdraw = {
  date: Scalars['Time'];
  amount: Scalars['Decimal'];
  memo: Scalars['String'];
};

export type CreateDirectNotice = {
  content: Scalars['String'];
  urgent: Scalars['Boolean'];
  isActivate: Scalars['Boolean'];
};

export type CreateJoinCode = {
  tax: Scalars['Decimal'];
  apiKeyList: Array<CreateJoinCodeApiKey>;
};

export type CreateJoinCodeApiKey = {
  hashedApiKey: Scalars['String'];
  smsFee: Scalars['Decimal'];
  nm: Scalars['String'];
};

export type CreateTestNumber = {
  vendor: Scalars['String'];
  testNumber: Scalars['String'];
  testNumberWithKoreaRegionCode: Scalars['String'];
};

export type CreateUserAlert = {
  userId: Scalars['Int'];
  alertType: AlertType;
  content: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  sendSms: Scalars['Int'];
  chargeAmount: Scalars['Decimal'];
  restBalance: Scalars['Decimal'];
  readyForCharge: Scalars['Int'];
};


export type DepositRecharge = {
  __typename?: 'DepositRecharge';
  id: Scalars['Int'];
  status: DepositRequestStatus;
  tel?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  nm: Scalars['String'];
  amount: Scalars['Decimal'];
  point: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export type DepositRechargeDepositFindOptions = {
  findType: DepositRechargeDepositFindType;
  value: Scalars['String'];
};

export enum DepositRechargeDepositFindType {
  Email = 'Email',
  Nm = 'Nm'
}

export type DepositRechargeList = {
  __typename?: 'DepositRechargeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<DepositRecharge>;
};

export type DepositRechargeReceipt = {
  __typename?: 'DepositRechargeReceipt';
  id: Scalars['Int'];
  nm: Scalars['String'];
  amount: Scalars['Decimal'];
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export type DepositRechargeReceiptList = {
  __typename?: 'DepositRechargeReceiptList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<DepositRechargeReceipt>;
};

export type DepositRequestAlert = {
  __typename?: 'DepositRequestAlert';
  id: Scalars['Int'];
  userEmail: Scalars['String'];
  nm: Scalars['String'];
  amount: Scalars['Decimal'];
  tel?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
};

export enum DepositRequestStatus {
  Wait = 'Wait',
  Allow = 'Allow',
  Deny = 'Deny',
  Cancel = 'Cancel'
}

export type DepositWithdraw = {
  __typename?: 'DepositWithdraw';
  id: Scalars['Int'];
  isDeletable: Scalars['Boolean'];
  type: CompanyBalance;
  memo: Scalars['String'];
  amount: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export type DirectNotice = {
  __typename?: 'DirectNotice';
  id: Scalars['Int'];
  adminEmail: Scalars['String'];
  content: Scalars['String'];
  urgent: Scalars['Boolean'];
  isActivate: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export type DirectNoticeList = {
  __typename?: 'DirectNoticeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<DirectNotice>;
};

export type General = User & {
  __typename?: 'General';
  id: Scalars['Int'];
  contact: Scalars['String'];
  email: Scalars['String'];
  apiPermission: Array<HashedApiKey>;
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  userType: UserType;
  createdAt: Scalars['Time'];
  isActivate: Scalars['Boolean'];
  memo: Scalars['String'];
  msgPermission: Array<MsgPermission>;
  categoryId: Scalars['Int'];
  categoryNm: Scalars['String'];
};

export type HashedApiKey = {
  __typename?: 'HashedApiKey';
  hashedApiKey: Scalars['String'];
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type IUpdateUserPassword = {
  userId: Scalars['Int'];
  password: Scalars['String'];
};

export type Init = {
  __typename?: 'Init';
  fb: Scalars['String'];
  appNm: Scalars['String'];
};

export type JoinCode = {
  __typename?: 'JoinCode';
  code: Scalars['String'];
  userType: UserType;
  commission: UserCommission;
  apiKeyList: Array<HashedApiKey>;
  createdAt: Scalars['Time'];
};

export type JoinCodeGeneral = {
  __typename?: 'JoinCodeGeneral';
  joinCode: Scalars['String'];
  reusable: Scalars['Boolean'];
  tax: Scalars['Decimal'];
  adminEmail: Scalars['String'];
  apiKey: Array<HashedApiKey>;
};

export type JoinCodeGeneralList = {
  __typename?: 'JoinCodeGeneralList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<JoinCodeGeneral>;
};

export type JoinCodeList = {
  __typename?: 'JoinCodeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<JoinCode>;
};

export type JoinCodeReseller = {
  __typename?: 'JoinCodeReseller';
  joinCode: Scalars['String'];
  reusable: Scalars['Boolean'];
  tax: Scalars['Decimal'];
  adminEmail: Scalars['String'];
  apiKey: Array<HashedApiKey>;
  commission: UserCommissionInfo;
};

export type JoinCodeResellerList = {
  __typename?: 'JoinCodeResellerList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<JoinCodeReseller>;
};

export type JoinCodeTree = {
  __typename?: 'JoinCodeTree';
  code: Scalars['String'];
  userType: UserType;
  rechargePermissionRate: Scalars['Decimal'];
  apiKeyList: Array<HashedApiKey>;
  adminEmail: Scalars['String'];
  tax: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export type JoinCodeTreeList = {
  __typename?: 'JoinCodeTreeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<JoinCodeTree>;
};

export enum JoinType {
  ResellerA = 'ResellerA',
  ResellerB = 'ResellerB',
  ResellerC = 'ResellerC',
  General = 'General'
}


export enum MsgPermission {
  Bulk = 'Bulk',
  Template = 'Template'
}

export type Mutation = {
  __typename?: 'Mutation';
  session: Admin;
  logout: Scalars['Boolean'];
  registerOtp: Scalars['String'];
  login: Scalars['String'];
  join: Scalars['Boolean'];
  createBoard: Scalars['Boolean'];
  updateBoard: Scalars['Boolean'];
  uploadBoardFile: Array<UploadedFile>;
  deleteBoard: Scalars['Boolean'];
  createDirectNotice: Scalars['Boolean'];
  deleteDirectNotice: Scalars['Boolean'];
  changeDirectNoticeActivation: Scalars['Boolean'];
  changeDirectNoticeUrgent: Scalars['Boolean'];
  createUserAlert: Scalars['Boolean'];
  deleteUserAlert: Scalars['Boolean'];
  createChat: Scalars['Boolean'];
  deleteChat: Scalars['Boolean'];
  deleteAllChat: Scalars['Boolean'];
  changeSolvedChat: Scalars['Boolean'];
  createGeneralJoinCode: Scalars['String'];
  createReseller1JoinCode: Scalars['String'];
  createReseller2JoinCode: Scalars['String'];
  createTreeJoinCode: Scalars['String'];
  deleteGeneralJoinCode: Scalars['Boolean'];
  deleteResellerJoinCode: Scalars['Boolean'];
  deleteTreeJoinCode: Scalars['Boolean'];
  deleteTestMsgReport: Scalars['Boolean'];
  deleteAllTestMsgReport: Scalars['Boolean'];
  allowRequestDepositRecharge: Scalars['Boolean'];
  denyRequestDepositRecharge: Scalars['Boolean'];
  issuePoint: Scalars['Boolean'];
  requestRechargeRequest: Scalars['Boolean'];
  cancelRechargeRequest: Scalars['Boolean'];
  allowSendCommissionSettleRequest: Scalars['Boolean'];
  denySendCommissionSettleRequest: Scalars['Boolean'];
  allowRechargeCommissionSettleRequest: Scalars['Boolean'];
  denyRechargeCommissionSettleRequest: Scalars['Boolean'];
  deleteCompanyBalanceDeposit: Scalars['Boolean'];
  deleteCompanyBalanceWithdraw: Scalars['Boolean'];
  createCompanyBalanceDeposit: Scalars['Boolean'];
  createCompanyBalanceWithdraw: Scalars['Boolean'];
  withdrawAllSendCommission: Scalars['Boolean'];
  withdrawSendCommission: Scalars['Boolean'];
  withdrawAllRechargeCommission: Scalars['Boolean'];
  withdrawRechargeCommission: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateUserApiPermission: Scalars['Boolean'];
  updateUserCommissionPermission: Scalars['Boolean'];
  updateUserPassword: Scalars['Boolean'];
  updateServerState: Scalars['Boolean'];
  updateBankNumber: Scalars['Boolean'];
  updateTestNumber: Scalars['Boolean'];
};


export type MutationRegisterOtpArgs = {
  uid: Scalars['String'];
};


export type MutationLoginArgs = {
  uid: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationJoinArgs = {
  uid: Scalars['String'];
};


export type MutationCreateBoardArgs = {
  input: CreateBoard;
};


export type MutationUpdateBoardArgs = {
  input: UpdateBoard;
};


export type MutationUploadBoardFileArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationDeleteBoardArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDirectNoticeArgs = {
  input: CreateDirectNotice;
};


export type MutationDeleteDirectNoticeArgs = {
  directNoticeId: Scalars['Int'];
};


export type MutationChangeDirectNoticeActivationArgs = {
  directNoticeId: Scalars['Int'];
  isActivate: Scalars['Boolean'];
};


export type MutationChangeDirectNoticeUrgentArgs = {
  directNoticeId: Scalars['Int'];
  isUrgent: Scalars['Boolean'];
};


export type MutationCreateUserAlertArgs = {
  input: CreateUserAlert;
};


export type MutationDeleteUserAlertArgs = {
  userAlertId: Scalars['Int'];
};


export type MutationCreateChatArgs = {
  input: CreateChat;
};


export type MutationDeleteChatArgs = {
  userChatId: Scalars['Int'];
};


export type MutationDeleteAllChatArgs = {
  userId: Scalars['Int'];
};


export type MutationChangeSolvedChatArgs = {
  userId: Scalars['Int'];
};


export type MutationCreateGeneralJoinCodeArgs = {
  input: CreateJoinCode;
  otp: Scalars['String'];
};


export type MutationCreateReseller1JoinCodeArgs = {
  input: CreateJoinCode;
  otp: Scalars['String'];
};


export type MutationCreateReseller2JoinCodeArgs = {
  input: CreateJoinCode;
  otp: Scalars['String'];
};


export type MutationCreateTreeJoinCodeArgs = {
  input: CreateJoinCode;
  otp: Scalars['String'];
};


export type MutationDeleteGeneralJoinCodeArgs = {
  joinCode: Scalars['String'];
};


export type MutationDeleteResellerJoinCodeArgs = {
  joinCode: Scalars['String'];
};


export type MutationDeleteTreeJoinCodeArgs = {
  joinCode: Scalars['String'];
};


export type MutationDeleteTestMsgReportArgs = {
  idList: Array<Scalars['Int']>;
};


export type MutationAllowRequestDepositRechargeArgs = {
  id: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationDenyRequestDepositRechargeArgs = {
  id: Scalars['Int'];
};


export type MutationIssuePointArgs = {
  userId: Scalars['Int'];
  point: Scalars['Decimal'];
  otp: Scalars['String'];
  memo: Scalars['String'];
};


export type MutationRequestRechargeRequestArgs = {
  clientRechargeCouponId: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationCancelRechargeRequestArgs = {
  clientRechargeRequestId: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationAllowSendCommissionSettleRequestArgs = {
  sendCommissionSettleRequestId: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationDenySendCommissionSettleRequestArgs = {
  sendCommissionSettleRequestId: Scalars['Int'];
};


export type MutationAllowRechargeCommissionSettleRequestArgs = {
  rechargeCommissionSettleRequestId: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationDenyRechargeCommissionSettleRequestArgs = {
  rechargeCommissionSettleRequestId: Scalars['Int'];
};


export type MutationDeleteCompanyBalanceDepositArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCompanyBalanceWithdrawArgs = {
  id: Scalars['Int'];
};


export type MutationCreateCompanyBalanceDepositArgs = {
  input: CreateCompanyBalanceDepositWithdraw;
};


export type MutationCreateCompanyBalanceWithdrawArgs = {
  input: CreateCompanyBalanceDepositWithdraw;
};


export type MutationWithdrawAllSendCommissionArgs = {
  id: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationWithdrawSendCommissionArgs = {
  userId: Scalars['Int'];
  commissionIdList: Array<Scalars['Int']>;
  otp: Scalars['String'];
};


export type MutationWithdrawAllRechargeCommissionArgs = {
  id: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationWithdrawRechargeCommissionArgs = {
  userId: Scalars['Int'];
  commissionIdList: Array<Scalars['Int']>;
  otp: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUser;
};


export type MutationUpdateUserApiPermissionArgs = {
  input: UpdateUserApiPermission;
};


export type MutationUpdateUserCommissionPermissionArgs = {
  input: UpdateUserCommissionPermission;
};


export type MutationUpdateUserPasswordArgs = {
  i: IUpdateUserPassword;
};


export type MutationUpdateServerStateArgs = {
  value: ServerState;
  otp: Scalars['String'];
};


export type MutationUpdateBankNumberArgs = {
  value: Scalars['String'];
};


export type MutationUpdateTestNumberArgs = {
  value: Array<CreateTestNumber>;
};

export enum OtpTypes {
  Login = 'Login',
  Code = 'Code',
  Point = 'Point'
}

export type PointHistory = {
  __typename?: 'PointHistory';
  id: Scalars['Int'];
  point: Scalars['Decimal'];
  adminEmail: Scalars['String'];
  userEmail: Scalars['String'];
  historyType: PointHistoryType;
  createdAt: Scalars['Time'];
};

export type PointHistoryList = {
  __typename?: 'PointHistoryList';
  size: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<PointHistory>;
};

export enum PointHistoryType {
  Admin = 'Admin',
  Deposit = 'Deposit',
  Commission = 'Commission'
}

export type Query = {
  __typename?: 'Query';
  init: Init;
  depositRequestAlert: Array<DepositRequestAlert>;
  chatAlert: Array<ChatAlert>;
  settleCommissionAlert: Array<SettleCommissionAlert>;
  board: Board;
  boardList: BoardList;
  directNotice: DirectNoticeList;
  userAlert: UserAlertList;
  chatNotify: Scalars['Int'];
  chatList: ChatList;
  chatTitle: ChatTitle;
  chatContent: ChatContentList;
  dashboard: Dashboard;
  dailyUsageGraph: Array<ReportGraph>;
  recentlySms: Array<RecentlySms>;
  apiKeyList: Array<HashedApiKey>;
  joinCodeGeneral: JoinCodeGeneralList;
  joinCodeReseller: JoinCodeResellerList;
  joinCodeTree: JoinCodeTreeList;
  testMsgReport: TestMsgReportList;
  requestDepositRecharge: Array<RequestDepositRecharge>;
  depositRechargeReceipt: DepositRechargeReceiptList;
  depositRechargeList: DepositRechargeList;
  pointHistory: PointHistoryList;
  sendCommissionSettleRequest: Array<SendCommissionSettleRequest>;
  sendCommissionRequestSettleList: SendCommissionRequestSettleList;
  rechargeCommissionSettleRequest: Array<RechargeCommissionSettleRequest>;
  rechargeCommissionSettleRequestList: RechargeCommissionSettleRequestList;
  clientRechargeRequestList: ClientRechargeRequestList;
  clientRechargeCoupon: Array<ClientRechargeCoupon>;
  dailyUserUsageReport: Array<UserUsageReport>;
  monthlyUserUsageReport: Array<UserUsageReport>;
  companyBalances: CompanyBalances;
  reportPerSmsFee: Array<ReportPerSmsFee>;
  userCategoryList: Array<UserCategory>;
  user: Array<User>;
  userWithId: User;
  userChildren: UserList;
  userParent: User;
  userList: UserList;
  userMsgHistoryList: UserMsgHistoryList;
  userBalanceHistoryList: UserBalanceHistoryList;
  userUsageReport: Array<UserUsageReport>;
  userLoginReport: Array<UserLoginReport>;
  userRechargeCommission: Array<UserRechargeCommission>;
  userRechargeCommissionList: UserRechargeCommissionList;
  userSendCommission: Array<UserSendCommission>;
  userSendCommissionList: UserSendCommissionList;
  userCommission: UserCommission;
  userTelBook: TelBook;
  userTelBookWithPeriod: Array<TelNumber>;
  userTelBookFileWithPeriod: Scalars['Upload'];
  userHistory: UserHistory;
  userHistoryList: UserHistoryList;
  serverState: ServerState;
  clientApiPermission: Array<ClientApiPermission>;
  bankNumber: Scalars['String'];
  testNumber: Array<TestNumber>;
};


export type QueryBoardArgs = {
  id: Scalars['Int'];
};


export type QueryBoardListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  boardType: BoardType;
};


export type QueryDirectNoticeArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserAlertArgs = {
  userId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryChatListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryChatTitleArgs = {
  userId: Scalars['Int'];
};


export type QueryChatContentArgs = {
  userId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryDailyUsageGraphArgs = {
  beforeDay: Scalars['Int'];
};


export type QueryRecentlySmsArgs = {
  size: Scalars['Int'];
};


export type QueryJoinCodeGeneralArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryJoinCodeResellerArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryJoinCodeTreeArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTestMsgReportArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryDepositRechargeReceiptArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryDepositRechargeListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  options?: Maybe<DepositRechargeDepositFindOptions>;
};


export type QueryPointHistoryArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
};


export type QuerySendCommissionRequestSettleListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryRechargeCommissionSettleRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryClientRechargeRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryDailyUserUsageReportArgs = {
  date: Scalars['Time'];
};


export type QueryMonthlyUserUsageReportArgs = {
  month: Scalars['Time'];
};


export type QueryCompanyBalancesArgs = {
  month: Scalars['Time'];
};


export type QueryReportPerSmsFeeArgs = {
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryUserWithIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserChildrenArgs = {
  userId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserParentArgs = {
  userId: Scalars['Int'];
};


export type QueryUserListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  options: UserFindOptions;
};


export type QueryUserMsgHistoryListArgs = {
  id: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserBalanceHistoryListArgs = {
  id: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserUsageReportArgs = {
  userId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryUserLoginReportArgs = {
  userId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryUserRechargeCommissionArgs = {
  userId: Scalars['Int'];
};


export type QueryUserRechargeCommissionListArgs = {
  id: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserSendCommissionArgs = {
  userId: Scalars['Int'];
};


export type QueryUserSendCommissionListArgs = {
  id: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserCommissionArgs = {
  id: Scalars['Int'];
};


export type QueryUserTelBookArgs = {
  requestId: Scalars['String'];
};


export type QueryUserTelBookWithPeriodArgs = {
  userId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryUserTelBookFileWithPeriodArgs = {
  userId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryUserHistoryArgs = {
  userId: Scalars['Int'];
};


export type QueryUserHistoryListArgs = {
  userId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type RecentlySms = {
  __typename?: 'RecentlySms';
  id: Scalars['Int'];
  userEmail: Scalars['String'];
  contents: Scalars['String'];
  count: Scalars['Int'];
  sendAt: Scalars['Time'];
};

export type RechargeCommissionSettleRequest = {
  __typename?: 'RechargeCommissionSettleRequest';
  id: Scalars['Int'];
  userEmail: Scalars['String'];
  status: RechargeCommissionSettleRequestStatus;
  commissionTotal: Scalars['Decimal'];
  commissionType: SettleCommissionType;
  commissionCount: Scalars['Int'];
  bankNumber: Scalars['String'];
  createdAt: Scalars['Time'];
  settleAt?: Maybe<Scalars['Time']>;
};

export type RechargeCommissionSettleRequestList = {
  __typename?: 'RechargeCommissionSettleRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<RechargeCommissionSettleRequest>;
};

export enum RechargeCommissionSettleRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  Cancel = 'Cancel'
}

export type ReportGraph = {
  __typename?: 'ReportGraph';
  date: Scalars['Time'];
  count: Scalars['Int'];
};

export type ReportPerSmsFee = {
  __typename?: 'ReportPerSmsFee';
  smsFee: Scalars['Decimal'];
  sentSms: Scalars['Int'];
};

export type RequestDepositRecharge = {
  __typename?: 'RequestDepositRecharge';
  id: Scalars['Int'];
  nm: Scalars['String'];
  userEmail: Scalars['String'];
  amount: Scalars['Decimal'];
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  sendSms: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  tel?: Maybe<Scalars['String']>;
};

export type Reseller = User & {
  __typename?: 'Reseller';
  id: Scalars['Int'];
  contact: Scalars['String'];
  email: Scalars['String'];
  apiPermission: Array<HashedApiKey>;
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  rechargeCommission: Scalars['Decimal'];
  userType: UserType;
  createdAt: Scalars['Time'];
  sendCommission: Scalars['Boolean'];
  children: Array<User>;
  isActivate: Scalars['Boolean'];
  memo: Scalars['String'];
  msgPermission: Array<MsgPermission>;
  categoryId: Scalars['Int'];
  categoryNm: Scalars['String'];
};

export type SendCommissionRequestSettleList = {
  __typename?: 'SendCommissionRequestSettleList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<SendCommissionSettleRequest>;
};

export type SendCommissionSettleRequest = {
  __typename?: 'SendCommissionSettleRequest';
  id: Scalars['Int'];
  userEmail: Scalars['String'];
  status: SendCommissionSettleRequestStatus;
  commissionTotal: Scalars['Decimal'];
  commissionType: SettleCommissionType;
  commissionCount: Scalars['Int'];
  bankNumber: Scalars['String'];
  createdAt: Scalars['Time'];
  settleAt?: Maybe<Scalars['Time']>;
};

export enum SendCommissionSettleRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  Cancel = 'Cancel'
}

export enum ServerState {
  Ok = 'Ok',
  ShutDown = 'ShutDown'
}

export enum SettleAlertType {
  Recharge = 'Recharge',
  Send = 'Send'
}

export type SettleCommissionAlert = {
  __typename?: 'SettleCommissionAlert';
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  settlePoint: Scalars['Decimal'];
  settleAlertType: SettleAlertType;
  createdAt: Scalars['Time'];
};

export enum SettleCommissionType {
  ChargePoint = 'ChargePoint',
  Withdraw = 'Withdraw'
}

export type TelBook = {
  __typename?: 'TelBook';
  requestId: Scalars['String'];
  msg: Scalars['String'];
  requestTotal: Scalars['Int'];
  totalSend: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  numberList: Array<TelNumber>;
  createdAt: Scalars['Time'];
};

export type TelNumber = {
  __typename?: 'TelNumber';
  number: Scalars['String'];
  status: Scalars['String'];
};

export type TestMsgReport = {
  __typename?: 'TestMsgReport';
  id: Scalars['Int'];
  receiverNumber: Scalars['String'];
  senderNumber: Scalars['String'];
  msg: Scalars['String'];
  vendor: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type TestMsgReportList = {
  __typename?: 'TestMsgReportList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<TestMsgReport>;
};

export type TestNumber = {
  __typename?: 'TestNumber';
  vendor: Scalars['String'];
  testNumber: Scalars['String'];
  testNumberWithKoreaRegionCode: Scalars['String'];
  healthCheck: Scalars['Time'];
};


export type UpdateBoard = {
  id: Scalars['Int'];
  title: Scalars['String'];
  contents: Scalars['String'];
  isActivate: Scalars['Boolean'];
  bgColor: Scalars['String'];
};

export type UpdateDefaultSmsFee = {
  hashedApiKey: Scalars['String'];
  nm: Scalars['String'];
  defalutUserSmsFee: Scalars['Decimal'];
  isUserDefalut: Scalars['Boolean'];
  isSystemDefalut: Scalars['Boolean'];
};

export type UpdateUser = {
  userId: Scalars['Int'];
  memo: Scalars['String'];
  isActivate: Scalars['Boolean'];
  tax: Scalars['Decimal'];
  msgPermission: Array<MsgPermission>;
  categoryId: Scalars['Int'];
};

export type UpdateUserApiPermission = {
  userId: Scalars['Int'];
  data: Array<UpdateUserApiPermissionData>;
};

export type UpdateUserApiPermissionData = {
  hashedApiKey: Scalars['String'];
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type UpdateUserCommissionPermission = {
  userId: Scalars['Int'];
  otp: Scalars['String'];
  hasSendCommission: Scalars['Boolean'];
  rechargeCommissionRate: Scalars['Decimal'];
};


export type UploadedFile = {
  __typename?: 'UploadedFile';
  id: Scalars['Int'];
  src: Scalars['String'];
  nm: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type User = {
  id: Scalars['Int'];
  email: Scalars['String'];
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  userType: UserType;
  isActivate: Scalars['Boolean'];
  msgPermission: Array<MsgPermission>;
  apiPermission: Array<HashedApiKey>;
  memo: Scalars['String'];
  contact: Scalars['String'];
  categoryId: Scalars['Int'];
  categoryNm: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type UserAlert = {
  __typename?: 'UserAlert';
  id: Scalars['Int'];
  adminEmail: Scalars['String'];
  alertType: AlertType;
  content: Scalars['String'];
  hasView: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export type UserAlertList = {
  __typename?: 'UserAlertList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserAlert>;
};

export type UserApiPermission = {
  __typename?: 'UserApiPermission';
  hasedApiKey: Scalars['String'];
  nm: Scalars['String'];
  smsFee: Scalars['Int'];
};

export type UserBalanceHistory = {
  __typename?: 'UserBalanceHistory';
  id: Scalars['Int'];
  receiptType: BalanceReceiptType;
  point: Scalars['Decimal'];
  prevPoint: Scalars['Decimal'];
  changedPoint: Scalars['Decimal'];
  createdAt: Scalars['Time'];
  memo: Scalars['String'];
};

export type UserBalanceHistoryList = {
  __typename?: 'UserBalanceHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserBalanceHistory>;
};

export type UserCategory = {
  __typename?: 'UserCategory';
  id: Scalars['Int'];
  nm: Scalars['String'];
};

export type UserCommission = {
  __typename?: 'UserCommission';
  commission: Scalars['Decimal'];
  paid: Scalars['Decimal'];
  notPaid: Scalars['Decimal'];
  createdAt: Scalars['Time'];
  hasSendCommission: Scalars['Boolean'];
  rechargeCommission: Scalars['Decimal'];
};

export type UserCommissionInfo = {
  __typename?: 'UserCommissionInfo';
  rechargeCommissionRate: Scalars['Decimal'];
  hasSendCommission: Scalars['Boolean'];
};

export type UserDepositHistory = {
  __typename?: 'UserDepositHistory';
  id: Scalars['Int'];
};

export type UserDepositHistoryList = {
  __typename?: 'UserDepositHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserDepositHistory>;
};

export type UserFindOptions = {
  email?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
  isActivate?: Maybe<Scalars['Boolean']>;
};

export type UserHistory = {
  __typename?: 'UserHistory';
  id: Scalars['Int'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type UserHistoryList = {
  __typename?: 'UserHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  userHistory: Array<UserHistory>;
};

export type UserList = {
  __typename?: 'UserList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  user: Array<User>;
};

export type UserLoginReport = {
  __typename?: 'UserLoginReport';
  userId: Scalars['Int'];
  createdAt: Scalars['Time'];
  count: Scalars['Int'];
};

export type UserMsgHistory = {
  __typename?: 'UserMsgHistory';
  id: Scalars['Int'];
  requestId: Scalars['String'];
  point: Scalars['Decimal'];
  prevPoint: Scalars['Decimal'];
  changedPoint: Scalars['Decimal'];
  sendTotal: Scalars['Int'];
  contents: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type UserMsgHistoryList = {
  __typename?: 'UserMsgHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserMsgHistory>;
};

export type UserRechargeCommission = {
  __typename?: 'UserRechargeCommission';
  id: Scalars['Int'];
  childrenEmail: Scalars['String'];
  rate: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  isWithdrawal: Scalars['Boolean'];
  chargeAmount: Scalars['Decimal'];
  chargePoint: Scalars['Decimal'];
  withdrawalAt?: Maybe<Scalars['Time']>;
};

export type UserRechargeCommissionList = {
  __typename?: 'UserRechargeCommissionList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserRechargeCommission>;
};

export type UserSendCommission = {
  __typename?: 'UserSendCommission';
  id: Scalars['Int'];
  childrenEmail: Scalars['String'];
  margin: Scalars['Decimal'];
  count: Scalars['Int'];
  commission: Scalars['Decimal'];
  isWithdrawal: Scalars['Boolean'];
  withdrawalAt?: Maybe<Scalars['Time']>;
};

export type UserSendCommissionList = {
  __typename?: 'UserSendCommissionList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserSendCommission>;
};

export enum UserType {
  Reseller = 'Reseller',
  General = 'General',
  Agent = 'Agent',
  Tree = 'Tree'
}

export type UserUsageReport = {
  __typename?: 'UserUsageReport';
  userId: Scalars['Int'];
  userEmail: Scalars['String'];
  userType: UserType;
  point: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  chargeAmount: Scalars['Decimal'];
  chargePoint: Scalars['Decimal'];
  profit: Scalars['Decimal'];
  sentSms: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export enum WriterType {
  Admin = 'Admin',
  User = 'User'
}

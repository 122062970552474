import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './BoardView.module.scss'
import {Board} from "../../../../graphql/types";
import moment from "moment";

const cx = classNamesBind.bind(styles);

export const BoardView = (props: {
    board: Board
}) => {
    return (
        <Fragment>
            <div className={cx('table')}>
                <div className='text-left' style={{flexGrow: 1}}>{props.board.title}</div>
                <div style={{width: 200}}>{moment(props.board.createdAt).format('YY.MM.DD')}</div>
            </div>

            <div className={cx('table')}>
            </div>

            <div className={cx('cont-contents')} style={{background: props.board.bgColor}}
                 ref={ref => {
                if (ref) {
                    ref.innerHTML = props.board.content;
                }
            }}/>
        </Fragment>
    )
}

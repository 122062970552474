import React, {Fragment} from "react";
import {MsgPermission} from "../../../graphql/types";

export const BadgeMsgPermission = (props: {
    msgPermission: MsgPermission
}) => {
    return (
        <Fragment>
            {props.msgPermission === MsgPermission.Bulk &&
            <span className='badge badge-success' style={{marginRight: 10}}>대량전송</span>}
            {props.msgPermission === MsgPermission.Template &&
            <span className='badge badge-primary' style={{marginRight: 10}}>탬플릿전송</span>}
        </Fragment>
    )
}

import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./PointCommissionRechargeHistory.module.scss";
import {RechargeCommissionSettleRequestList, SettleCommissionType} from "../../../../graphql/types";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {BadgeSettleCommissionType} from "../../../shares/badgeSettleCommissionType/BadgeSettleCommissionType";
import {IconButton} from "@material-ui/core";
import {OpenInNew} from "@material-ui/icons";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Pager} from "../../../shares/pager/Pager";
import {BadgeRechargeCommissionSettleRequestStatus} from "../../../shares/badgeRechargeCommissionSettleRequestStatus/BadgeRechargeCommissionSettleRequestStatus";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import {OnChangeValue} from "../../../Interfaces";

const cx = classNamesBind.bind(styles);

interface Props {
    rechargeCommissionSettleRequestList: RechargeCommissionSettleRequestList,
    onChangePageRecharge: OnChangeValue<number>,
}

export const PointCommissionRechargeHistory = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    const handler = {
        onShowBankNumber: (numb: string) => {
            SwalUtil.ok({
                msg: numb,
                icon: 'success'
            })
        }
    }

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('cont')}>
                    <div className={cx('box-table')}>
                        <div className={cx("table", "scrollTable")}>
                            <div className={cx("header", "requestDateCell")}>요청일</div>
                            <div className={cx("header", "caculateDateCell")}>정산일</div>
                            <div className={cx("header", "statusCell")}>상태</div>
                            <div className={cx("header", "userCell")}>유저</div>
                            <div className={cx("header", "wayCell")}>정산방법</div>
                            <div className={cx("header", "pointCell")}>정산포인트</div>
                        </div>

                        {props.rechargeCommissionSettleRequestList.total === 0 && <BizNoDataTable/>}

                        {props.rechargeCommissionSettleRequestList.list.map((value, index) => (
                            <div key={index} className={cx('table', 'hover')}>
                                <div className={cx("requestDateCell")}>
                                    {moment(value.createdAt).format('MM.DD - hh:mm a')}
                                </div>
                                <div className={cx("caculateDateCell")}>
                                    {value.settleAt ? moment(value.settleAt).format('MM.DD - hh:mm a') : '-'}
                                </div>
                                <div className={cx("statusCell")}>
                                    <BadgeRechargeCommissionSettleRequestStatus
                                        status={value.status}/>
                                </div>
                                <div className={cx("userCell")}>
                                    {value.userEmail}
                                </div>
                                <div className={cx("wayCell")}>
                                    <BadgeSettleCommissionType
                                        status={value.commissionType}/>
                                    {value.commissionType === SettleCommissionType.Withdraw &&
                                    <IconButton size='small' onClick={() => handler.onShowBankNumber(value.bankNumber)}>
                                        <OpenInNew/>
                                    </IconButton>}
                                </div>
                                <div className={cx("pointCell", "textRight")}>
                                    {Pipe.toSeparatorNumber(value.commissionTotal)} P
                                </div>

                            </div>
                        ))}

                    </div>
                </div>
            </ScrollArea>
            <div className={cx('space')}/>
            <div className={cx('pager')}>
                <Pager
                    page={props.rechargeCommissionSettleRequestList.page}
                    size={props.rechargeCommissionSettleRequestList.size}
                    total={props.rechargeCommissionSettleRequestList.total}
                    onChange={props.onChangePageRecharge}/>
            </div>
        </>
    )
};

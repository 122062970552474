import React, {Fragment} from "react";
import {UserUsageReport} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import moment from "moment";
import Chart from "react-apexcharts";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";

export const UserChargeUsageGraph = (props: {
    list: UserUsageReport[],
}) => {
    const options: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        xaxis: {
            categories: props.list.map(value => {
                return [moment(value.createdAt).format('MM.DD')]
            }),
        },
        yaxis: {
            tickAmount: 1
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
        },
    }

    const series = [
        {
            name: '충전',
            type: 'bar',
            data: props.list.map(value => {
                return value.chargeAmount
            })
        },
        {
            name: '사용',
            type: 'bar',
            data: props.list.map(value => {
                return -value.point
            })
        },
    ];

    return (
        <Fragment>
            {props.list.length === 0 &&
            <BizNoDataTable/>
            }
            {props.list.length !== 0 &&
            <Chart
                options={options}
                series={series}
                width={'100%'}
                height={'400px'}/>
            }
        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BoardUpdate.module.scss'
import {Board, BoardType, UpdateBoard} from "../../../graphql/types";
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {Validator} from "../../../utils/validator/Validator";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {Title} from "../../shares/title/Title";
import {BoardEditor} from "../share/editor/BoardEditor";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";

const cx = classNamesBind.bind(styles);

export const BoardUpdate = (props: {
    boardType: BoardType,
    id: string,
}) => {
    const [getData, resData] = useLazyQuery<{
        board: Board
    }, {
        id: number
    }>(gqlBoard);
    const [mutUpdateBoard] = useMutation<{
        updateBoard: boolean,
    }, {
        input: UpdateBoard
    }>(gqlUpdateBoard);
    const [updateBoard, setUpdateBoard] = useState<UpdateBoard | undefined>();


    useEffect(() => {
        const id = Validator.isNumber(props.id);
        if (id === 0) {
            SwalUtil.ok({
                msg: '잘못된 접근입니다.',
                icon: 'error'
            });
            navigate(`/board/list/${props.boardType}`);
            return;
        }
        getData({variables: {id}});
    }, [props.boardType, props.id]);

    useEffect(() => {
        if (resData.data) {
            const board = resData.data.board;
            const data: UpdateBoard = {
                id: board.id,
                bgColor: board.bgColor,
                isActivate: board.isActivate,
                title: board.title,
                contents: board.content
            }
            setUpdateBoard(data);
        }
    }, [resData.data]);

    const onUpdateBoard = () => {
        if (!updateBoard) {
            return;
        }

        if (!updateBoard.title) {
            SwalUtil.ok({
                msg: '제목을 입력하여 주십시오.',
                icon: 'warning'
            });
            return;
        }

        if (!updateBoard.contents) {
            SwalUtil.ok({
                msg: '내용을 입력하여 주십시오.',
                icon: 'warning'
            });
            return;
        }

        SwalUtil.yn({
            msg: '수정 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutUpdateBoard({
                    variables: {
                        input: updateBoard
                    }
                }).then(res => {
                    SwalUtil.ok({
                        msg: '수정되었습니다.',
                        icon: 'success',
                    })
                    navigate(`/board/list/${props.boardType}`);
                }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.apolloError()
                }))
            }
        })
    }


    return (
        <Fragment>
            {updateBoard &&
            <FadeInContainer>
                <div className='col-12'>
                    <BoxCont>
                        <Title>{props.boardType} 수정</Title>
                        <BoardEditor value={{...updateBoard, boardType: props.boardType}} onChange={value => {
                            const copy: UpdateBoard = {
                                bgColor: value.bgColor,
                                contents: value.contents,
                                title: value.title,
                                id: updateBoard.id,
                                isActivate: updateBoard.isActivate
                            };
                            setUpdateBoard(copy);
                        }}/>

                        <div className={cx('table')}>
                            <div style={{width: '100%'}} className='text-right'>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={onUpdateBoard}>수정
                                </button>
                            </div>
                        </div>
                    </BoxCont>
                </div>
            </FadeInContainer>
            }
        </Fragment>
    )
}

const gqlBoard = gql`
    query Board($id: Int!) {
        board(id: $id) {
            id
            title
            content
            isActivate
            bgColor
        }
    }
`;

const gqlUpdateBoard = gql`
    mutation UpdateBoard($input: UpdateBoard!) {
        updateBoard(input: $input)
    }
`;

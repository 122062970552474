import React, {Fragment} from "react";
import {UserUsageReport} from "../../../../../graphql/types";
import moment from "moment";
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";


export const UserSmsSendGraph = (props: {
    list: UserUsageReport[],
}) => {
    const options: ApexOptions = {
        title: {text:"사용량 그래프"},
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        xaxis: {
            categories: props.list.map(value => {
                return [moment(value.createdAt).format('MM.DD')]
            }),
        },
        yaxis: {
            tickAmount: 1
        },
        markers: {
            size: 0
        },
        stroke: {
            curve: 'straight'
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
        },
    }

    const series = [
        {
            name: '전송량',
            type: 'line',
            data: props.list.map(value => {
                return value.sentSms
            })
        },
    ];

    return (
        <Fragment>
            {/*{props.list.length === 0 &&*/}
            {/*<BizNoDataTable/>*/}
            {/*}*/}

            {/*{props.list.length !== 0 &&*/}
            <Chart
                options={options}
                series={series}
                width={'100%'}
                height={'300px'}/>
            {/*}*/}
        </Fragment>
    )
}

import {UserType, UserUsageReport} from "../../graphql/types";
import {Decimal} from "decimal.js";

export const SumUtil = {
    userUsageReport: (list: UserUsageReport[]): UserUsageReport => {
        const res: UserUsageReport = {
            chargeAmount: 0,
            chargePoint: 0,
            profit: 0,
            point: 0,
            sentSms: 0,
            userId: 0,
            taxAmount: 0,
            userType: UserType.General,
            userEmail: "",
            createdAt: null,
        }


        let chargeAmount = new Decimal(0);
        let chargePoint = new Decimal(0);
        let sentSms = new Decimal(0);
        let profit = new Decimal(0)
        let taxAmount = new Decimal(0);

        for (let item of list) {
            chargeAmount = chargeAmount.add(item.chargeAmount)
            chargePoint = chargePoint.add(item.chargePoint);
            sentSms = sentSms.add(item.sentSms);
            profit = profit.add(item.profit);
            taxAmount = taxAmount.add(item.taxAmount);
        }

        res.chargeAmount = chargeAmount.toNumber();
        res.chargePoint = chargePoint.toNumber();
        res.sentSms = sentSms.toNumber();
        res.profit = profit.toNumber();
        res.taxAmount = taxAmount.toNumber();

        return res;
    }
}

export class BizErrors {
    static default = 'default';
    static needLogin = "needLogin";
    static notFoundSession = 'notFoundSession';
    static notFoundAdmin = "notFoundAdmin";
    static notFoundToken = "notFoundToken"
    static loginLimited = "loginLimited";
    static loginFail = "loginFail";
    static invalidPassword = "invalidPassword";
    static passwordNotFound = "passwordNotFound";
    static notFoundOtp = "notFoundOtp";
    static failVerifyOTP = "failVerifyOTP";
    static alreadyRegisteredOtp = "alreadyRegisteredOtp";
    static notFoundData = "notFoundData";
    static overMaxSize = "overMaxSize";
}

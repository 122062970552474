import React from "react";
import styles from './PointRequestDepositListSearchBar.module.scss'
import {DepositRechargeDepositFindOptions, DepositRechargeDepositFindType} from "../../../../graphql/types";
import {OnChangeValue, OnVoidFunction} from "../../../Interfaces";
import {Pipe} from "../../../../utils/pipe/pipe";


export const PointRequestDepositListSearchBar = (props: {
    value: DepositRechargeDepositFindOptions,
    onChangeValue: OnChangeValue<DepositRechargeDepositFindOptions>,
    onClear: OnVoidFunction,
    onSearch: OnVoidFunction,
}) => {
    return (
        <form className={styles.contSearchBar}
            onSubmit={ev => {
                ev.preventDefault();

            }}>
            <div className={styles.select}>
                <select
                    value={props.value.findType}
                    className='form-control form-control-sm'
                    onChange={ev => props.onChangeValue({...props.value, findType: Pipe.toDepositRechargeDepositFindType(ev.target.value)})}>
                    <option value={DepositRechargeDepositFindType.Nm}>입금자명</option>
                    <option value={DepositRechargeDepositFindType.Email}>이메일</option>
                </select>
            </div>
            <div className={styles.input}>
                <input
                    placeholder='검색값 입력'
                    value={props.value.value}
                    className='form-control form-control-sm'
                    onChange={ev => props.onChangeValue({...props.value, value: ev.target.value})}/>
            </div>

            <div className={styles.btn}>
                <button
                    onClick={props.onClear}
                    type='button'
                    className='btn btn-sm btn-block btn-outline-success'>
                    초기화
                </button>
            </div>

            <div className={styles.btn}>
                <button
                    onClick={props.onSearch}
                    className='btn btn-sm btn-block btn-success'>
                    검색
                </button>
            </div>
        </form>
    )
}

import React from "react";
import {CompanyBalance} from "../../../graphql/types";

export const BadgeCompanyBalance = (props: {
    value: CompanyBalance
}) => {
    return (
        <>
            {props.value === CompanyBalance.CommissionWithdrawal &&
            <span className='badge badge-warning'>커미션</span>
            }

            {props.value === CompanyBalance.Custom &&
            <span className='badge badge-primary'>관리자추가</span>
            }

            {props.value === CompanyBalance.DepositRecharge &&
            <span className='badge badge-success'>입금확인</span>
            }
        </>
    )
}

import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './CodeIssueInput.module.scss'
import {
    HashedApiKey,
    MutationCreateGeneralJoinCodeArgs
} from "../../../../graphql/types";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import {Switch} from "@material-ui/core";
import {OnChangeValue} from "../../../Interfaces";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import Decimal from "decimal.js";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";

const cx = classNamesBind.bind(styles);
const DEFAULT_TAX = 0;
// const DEFAULT_RECHARGE_RATE = 0;

type ApiPermissionInputData = (HashedApiKey & { isActivate: boolean, userNm: string, userSmsFee: number })[];

const InputApiPermission = (props: {
    value: ApiPermissionInputData,
    onChangeValue: OnChangeValue<ApiPermissionInputData>
}) => {

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(200);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx("table", "scrollTable")}>
                    <div className={cx("activateCell")}>활성화</div>
                    <div className={cx("lineCell")}>회선이름</div>
                    <div className={cx("priceCell")}>회선가격</div>
                    <div className={cx("nameCell")}>이름</div>
                    <div className={cx("numberOfMessageCell")}>건당가격</div>
                </div>
                {props.value.map((value, index) => (
                    <div key={index} className={cx('table', "scrollTable")}>
                        <div className={cx("activateCell")}>
                            <Switch
                                color='primary'
                                checked={value.isActivate}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy[index].isActivate = ev.target.checked;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                        <div className={cx("lineCell")}>{value.nm}</div>
                        <div className={cx("priceCell")}>{value.smsFee} 원</div>
                        <div className={cx("nameCell")}>
                            <input
                                className='form-control form-control-sm text-center'
                                value={value.userNm}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy[index].userNm = ev.target.value;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                        <div className={cx("numberOfMessageCell")}>
                            <NumberFormat
                                className='form-control form-control-sm text-right'
                                suffix={' 원'}
                                allowNegative={false}
                                decimalScale={1}
                                value={value.userSmsFee}
                                onValueChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy[index].userSmsFee = ev.floatValue || 0;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                    </div>
                ))}
            </ScrollArea>
        </>
    )

}

export const CodeIssueInput = (props: {
    apiKeyList: HashedApiKey[],
    onCreate: OnChangeValue<MutationCreateGeneralJoinCodeArgs>,
}) => {
    const [inputData, setInputData] = useState<ApiPermissionInputData>([]);

    useEffect(() => {
        const nextInputData: ApiPermissionInputData = props.apiKeyList.map(value => {
            return {
                isActivate: false,
                hashedApiKey: value.hashedApiKey,
                nm: value.nm,
                smsFee: value.smsFee,
                userNm: value.nm,
                userSmsFee: 17,
            }
        })
        setInputData(nextInputData);
    }, []);


    const handler = {
        onChangeValue: (data: ApiPermissionInputData) => {
            setInputData(data);
        },
        onCreateJoinCode: () => {
            const args: MutationCreateGeneralJoinCodeArgs = {
                input: {
                    apiKeyList: [],
                    tax: DEFAULT_TAX
                },
                otp: ''
            }

            for (let elem of inputData) {
                if (!elem.isActivate) {
                    continue;
                }

                if (elem.userNm === "") {
                    SwalUtil.ok({
                        msg: '활성화된 회선에는 이름을 지정하셔야 합니다.',
                        icon: 'error',
                    })
                    return;
                }

                if (new Decimal(elem.userSmsFee).lt(elem.smsFee)) {
                    SwalUtil.ok({
                        msg: '건당가격을 회선가격보다 낮게 책정할수 없습니다.',
                        icon: 'error'
                    });
                    return;
                }

                args.input.apiKeyList.push({
                    hashedApiKey: elem.hashedApiKey,
                    nm: elem.userNm,
                    smsFee: elem.userSmsFee,
                })
            }

            if (args.input.apiKeyList.length === 0) {
                SwalUtil.ok({
                    msg: '회선은 최소 1개 이상 활성화 하셔야 합니다.',
                    icon: 'error'
                });
                return;
            }

            SwalUtil.otp(otp => {
                args.otp = otp;
                props.onCreate(args);
            });
        }
    };

    return (
        <>
            <InputApiPermission
                value={inputData}
                {...handler}/>
            <div className={cx('table')}>
                <div style={{width: '100%'}} className='text-right'>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onCreateJoinCode}>생성
                    </button>
                </div>
            </div>
        </>
    )
}


// export const CodeIssueReseller1Input = (props: {
//     apiKeyList: HashedApiKey[],
//     onCreate: OnChangeValue<MutationCreateReseller1JoinCodeArgs>,
// }) => {
//     const [inputData, setInputData] = useState<ApiPermissionInputData>([]);
//
//     useEffect(() => {
//         const nextInputData: ApiPermissionInputData = props.apiKeyList.map(value => {
//             return {
//                 isActivate: false,
//                 hashedApiKey: value.hashedApiKey,
//                 nm: value.nm,
//                 smsFee: value.smsFee,
//                 userNm: "",
//                 userSmsFee: 18,
//             }
//         })
//         setInputData(nextInputData);
//     }, []);
//
//
//     const handler = {
//         onChangeValue: (data: ApiPermissionInputData) => {
//             setInputData(data);
//         }
//     };
//
//     return (
//         <>
//             <InputApiPermission
//                 value={inputData}
//                 {...handler}/>
//             <div className={cx('table')}>
//                 <div style={{width: '100%'}} className='text-right'>
//                     <button className='btn btn-sm btn-success'>생성</button>
//                 </div>
//             </div>
//         </>
//     )
// }

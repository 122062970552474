import React, {Fragment} from "react";

export const BadgeNumberStatus = (props: {
    numberStatus: string
}) => {
    return (
        <Fragment>
            {props.numberStatus === 'Sent' && <span className='badge badge-success'>전송완료</span>}
            {props.numberStatus === 'Fail' && <span className='badge badge-danger'>전송실패</span>}
            {props.numberStatus === 'DlrDelivered' && <span className='badge badge-primary'>송신완료</span>}
            {props.numberStatus === 'DlrUndelivered' && <span className='badge badge-warning'>송신결과값대기</span>}
        </Fragment>
    )
}

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {App} from "./components/App";
import {createStore} from "redux";
import {RootReducer} from "./redux";
import './styles/common.scss'
import {Provider} from "react-redux";
import {ApolloProvider} from "@apollo/client";
import {Apollo} from "./utils/apollo/apollo";
import {LayoutAction} from "./redux/Layout";
import {DataUtil} from "./utils/data/DataUtil";
import {BizBackdrop} from "./components/shares/bizBackdrop/BizBackdrop";

export const ReduxStore =
    createStore(RootReducer,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__());


// 화면 크기 조정
ReduxStore.dispatch(LayoutAction.setWindowWidth(window.innerWidth));
window.addEventListener("resize", () => {
    ReduxStore.dispatch(LayoutAction.setWindowWidth(window.innerWidth));
});
DataUtil.init();

ReactDOM.render(
    <Suspense fallback={<BizBackdrop loading={true}/>}>
        <ApolloProvider client={Apollo.client}>
            <Provider store={ReduxStore}>
                <App/>
            </Provider>
        </ApolloProvider>
    </Suspense>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './AlertMenu.module.scss'
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../../../../../globalVars/globalVars";
import {MenuItem, Switch} from "@material-ui/core";
import {ChatOutlined, HowToVoteOutlined, Money, Notifications} from "@material-ui/icons";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {navigate} from "hookrouter";
import {OnVoidFunction} from "../../../../Interfaces";
import {LocalStorage} from "../../../../../utils/localstorage/LocalStorage";

const cx = classNamesBind.bind(styles);

export const AlertMenu = (props: {
    onClose: OnVoidFunction
}) => {
    const depositRequestAlertList = useReactiveVar(GlobalVars.alert.depositRequest);
    const chatAlertList = useReactiveVar(GlobalVars.alert.chatAlert);
    const settleCommissionAlertList = useReactiveVar(GlobalVars.alert.settleCommissionAlert);
    const alertSound = useReactiveVar(GlobalVars.alert.alertSound);

    return (
        <Fragment>
            <div style={{width: 300}}>
                <MenuItem disabled>
                    <HowToVoteOutlined/> 입금확인요청
                </MenuItem>
                {depositRequestAlertList.map((value, index) => (
                    <div className={cx('cont-alert')} key={index} onClick={ev => {
                        navigate('/point');
                        props.onClose();
                    }}>
                        <div className={cx('email')}>{value.userEmail}</div>
                        <div className={cx('nm')}>
                            <div>{value.nm}</div>
                            <div>{Pipe.toSeparatorNumber(value.amount)} 원</div>
                        </div>
                    </div>
                ))}

                {depositRequestAlertList.length === 0 &&
                <div className={cx('cont-alert')}>
                    <div className={cx('email')}>알림이 없습니다.</div>
                </div>
                }


                <div style={{height: 30}}/>
                <MenuItem disabled>
                    <ChatOutlined/> 문의요청
                </MenuItem>

                {chatAlertList.map((value, index) => (
                    <div className={cx('cont-alert')} key={index} onClick={ev => {
                        navigate(`/chat/${0}/${value.userId}`);
                        props.onClose();
                    }}>
                        <div className={cx('email')}>{value.userEmail}</div>
                        <div className={cx('nm')}>
                            <div>메세지</div>
                            <div>{Pipe.toSeparatorNumber(value.chatCount)} 개</div>
                        </div>
                    </div>
                ))}

                {chatAlertList.length === 0 &&
                <div className={cx('cont-alert')}>
                    <div className={cx('email')}>알림이 없습니다.</div>
                </div>
                }


                <div style={{height: 30}}/>
                <MenuItem disabled>
                    <Money/> 정산요청
                </MenuItem>
                {settleCommissionAlertList.map((value, index) => (
                    <div className={cx('cont-alert')} key={index} onClick={ev => {
                        navigate(`/point/commission`);
                        props.onClose();
                    }}>
                        <div className={cx('email')}>{value.userEmail}</div>
                        <div className={cx('nm')}>
                            <div></div>
                            <div>{Pipe.toSeparatorNumber(value.settlePoint)} P</div>
                        </div>
                    </div>
                ))}

                {settleCommissionAlertList.length === 0 &&
                <div className={cx('cont-alert')}>
                    <div className={cx('email')}>알림이 없습니다.</div>
                </div>
                }

                <div style={{height: 30}}/>
                <div className={cx('table')} style={{marginTop: 10}}>
                    <div style={{width: '30%'}}><Notifications/>알림음</div>
                    <div className='text-right' style={{width: '70%'}}>
                        <Switch
                            checked={alertSound}
                            onChange={ev => {
                                GlobalVars.alert.alertSound(ev.target.checked);
                                LocalStorage.setAlertSound(ev.target.checked);
                            }}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

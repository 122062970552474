import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './ChatDefaultContent.module.scss';
import {
    ChatContent,
    MutationChangeSolvedChatArgs,
    MutationCreateChatArgs,
    Query,
    WriterType
} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Interfaces";
import {Pager} from "../../../shares/pager/Pager";
import moment from "moment";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Title} from "../../../shares/title/Title";

const cx = classNamesBind.bind(styles);

export const ChatDefaultContent = (props: {
    userId: number,
    value: Query,
    onChangeChatContentPage: OnChangeValue<number>,
    onCreateChat: OnChangeValue<MutationCreateChatArgs>,
    onChangeSolvedChat: OnChangeValue<MutationChangeSolvedChatArgs>
}) => {
    const [refChatBox, setRefChatBox] = useState<HTMLElement | null>();
    const [input, setInput] = useState<MutationCreateChatArgs>({
        input: {
            userId: props.userId,
            content: ""
        }
    })

    const handler = {
        onCreateUserChat: () => {
            props.onCreateChat(CopyUtil.copyAll(input));
            setInput({
                input: {
                    userId: props.userId,
                    content: ""
                }
            })
        },
        onScrollDownChat: () => {
            if (refChatBox) {
                refChatBox.scrollTo(0, refChatBox.scrollHeight);
            }

        },
        onClickChangeSolvedChat: () => {
            props.onChangeSolvedChat({userId: props.userId});
        }
    }

    useEffect(() => {
        handler.onScrollDownChat();
    }, [props.value.chatList])

    handler.onScrollDownChat();

    return (
        <>
            <div className={cx('cont-title')}>
                <div className={cx('half')}>
                    <Title>내용</Title>
                </div>
                <div className={cx('half', 'text-right')}>
                    {!props.value.chatTitle.isSolved && <button className='btn btn-sm btn-outline-warning'
                                                                onClick={handler.onClickChangeSolvedChat}>해결</button>}
                </div>
            </div>

            <PerfectScrollbar
                className={cx('cont-scroll')}
                containerRef={setRefChatBox}>
                <div className={cx('cont-chat')}>
                    {props.value.chatContent.list.map((value, index) => (
                        <ChatBox key={index} chat={value}/>
                    ))}
                </div>
            </PerfectScrollbar>

            <form
                className={cx('input-chat')}
                onSubmit={ev => {
                    ev.preventDefault()
                    handler.onCreateUserChat();
                }}>
                <input
                    required
                    value={input.input.content}
                    className='form-control form-control-sm'
                    placeholder="이곳에 답변을 입력하여 주십시오."
                    onChange={ev => {
                        const copy = CopyUtil.copyAll(input);
                        copy.input.content = ev.target.value;
                        setInput(copy);
                    }}/>
                <button
                    className='btn btn-sm btn-success'
                    type='submit'>전송
                </button>
            </form>

            <Pager
                page={props.value.chatContent.page}
                size={props.value.chatContent.size}
                total={props.value.chatContent.total}
                onChange={props.onChangeChatContentPage}/>
        </>
    )
}


const ChatBox = (props: {
    chat: ChatContent
}) => {

    return (
        <>
            {props.chat.writer === WriterType.Admin &&
            <div className={cx('cont-chat-box', 'box-admin')}>
                <div className={cx('chat-writer')}>{props.chat.adminEmail}</div>
                <div className={cx("chat-content")}>{props.chat.content}</div>
                <div className={cx('created-at')}>{moment(props.chat.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            }

            {props.chat.writer === WriterType.User &&
            <div className={cx('cont-chat-box', 'box-user')}>
                <div className={cx('chat-writer')}>{props.chat.userEmail}</div>
                <div className={cx("chat-content")}>{props.chat.content}</div>
                <div className={cx('created-at')}>{moment(props.chat.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            }
        </>
    )
}

import React, {Fragment} from "react";
import {RechargeCommissionSettleRequestStatus} from "../../../graphql/types";

export const BadgeRechargeCommissionSettleRequestStatus = (props: {
    status: RechargeCommissionSettleRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === RechargeCommissionSettleRequestStatus.Requested &&
            <span className='badge badge-secondary'>요청대기</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Cancel &&
            <span className='badge badge-secondary'>유저취소</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Deny &&
            <span className='badge badge-dark'>반려</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Allow &&
            <span className='badge badge-success'>정산완료</span>}
        </Fragment>
    )
}

import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './UserTotalCommission.module.scss'
import {MutationUpdateUserCommissionPermissionArgs, Scalars, UserCommission} from "../../../../../graphql/types";
import {Title} from "../../../../shares/title/Title";
import moment from "moment";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {OnChangeValue, OnVoidFunction} from "../../../../Interfaces";
import {ModalWeb} from "../../../../shares/modal/ModalWeb";
import {Switch} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";

const cx = classNamesBind.bind(styles);

interface Props {
    userId: number,
    userCommission: UserCommission
    onUpdateUserCommissionPermission: OnChangeValue<MutationUpdateUserCommissionPermissionArgs>
}

export const UserTotalCommission = (props: Props) => {
    const [changeModal, setChangeModal] = useState(false);
    return (
        <>
            <Title>커미션 통계</Title>
            <ViewSelector
                mobile={<Mobile {...props}/>}
                web={<Web {...props}/>}/>
            <hr/>

            <ModalWeb
                title={'커미션 종류 변경'}
                size={'lg'}
                isShow={changeModal}
                onCancel={() => setChangeModal(false)}>
                <UserCommissionChangeModal
                    onClose={() => setChangeModal(false)}
                    {...props}/>
            </ModalWeb>


            <div className='text-right'>
                <button
                    className='btn btn-sm btn-success'
                    onClick={() => setChangeModal(true)}>변경
                </button>
            </div>
        </>
    )
}

const Web = (props: Props) => {
    return (
        <>
            <div className='def-text'
                 style={{marginBottom: 10}}>{props.userCommission.hasSendCommission && '전송 커미션 회원입니다.'}</div>
            <div className='def-text'
                 style={{marginBottom: 10}}>{0 < props.userCommission.rechargeCommission && '충전 커미션 회원입니다.'}</div>

            <div className={cx('table')}>
                <div style={{width: '25%'}}>기준일</div>
                <div style={{width: '25%'}}>전체</div>
                <div style={{width: '25%'}}>지급</div>
                <div style={{width: '25%'}}>미지급</div>
            </div>
            <div className={cx('table')}>
                <div style={{width: '25%'}}>
                    {moment(props.userCommission.createdAt).format('YY.MM.DD hh:mm a')}
                </div>
                <div style={{width: '25%'}}>
                    {Pipe.toSeparatorNumber(props.userCommission.commission)} 원
                </div>
                <div style={{width: '25%'}}>
                    {Pipe.toSeparatorNumber(props.userCommission.paid)} 원
                </div>
                <div style={{width: '25%'}}>
                    {Pipe.toSeparatorNumber(props.userCommission.notPaid)} 원
                </div>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <>
            <div className='def-text'
                 style={{marginBottom: 10}}>{props.userCommission.hasSendCommission && '전송 커미션 회원입니다.'}</div>
            <div className='def-text'
                 style={{marginBottom: 10}}>{0 < props.userCommission.rechargeCommission && '충전 커미션 회원입니다.'}</div>

            <div className={cx('table')}>
                <div style={{width: '30%'}}>기준시</div>
                <div style={{width: '70%'}}>{moment(props.userCommission.createdAt).format('YY.MM.DD - hh:mm a')}</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '30%'}}>전체</div>
                <div className='text-right'
                     style={{width: '70%'}}>{Pipe.toSeparatorNumber(props.userCommission.commission)} 원
                </div>
            </div>


            <div className={cx('table')}>
                <div style={{width: '30%'}}>지급</div>
                <div className='text-right'
                     style={{width: '70%'}}>{Pipe.toSeparatorNumber(props.userCommission.paid)} 원
                </div>
            </div>


            <div className={cx('table')}>
                <div style={{width: '30%'}}>미지급</div>
                <div className='text-right'
                     style={{width: '70%'}}>{Pipe.toSeparatorNumber(props.userCommission.notPaid)} 원
                </div>
            </div>


        </>
    )
}


type UserCommissionChangeModalData = {
    sendCommission: {
        isActivate: boolean,
    },
    rechargeCommission: {
        isActivate: boolean,
        rate: number,
    },
    otp: string,
}

const UserCommissionChangeModal = (props: {
    userId: number,
    userCommission: UserCommission,
    onUpdateUserCommissionPermission: OnChangeValue<MutationUpdateUserCommissionPermissionArgs>,
    onClose: OnVoidFunction,
}) => {
    const [input, setInput] = useState<UserCommissionChangeModalData>({
        sendCommission: {
            isActivate: props.userCommission.hasSendCommission
        },
        rechargeCommission: {
            isActivate: 0 < props.userCommission.rechargeCommission,
            rate: props.userCommission.rechargeCommission,
        },
        otp: '',
    })

    const handler = {
        onUpdate: () => {
            if (input.sendCommission.isActivate) {
                props.onUpdateUserCommissionPermission({
                    input: {
                        userId: props.userId,
                        otp: input.otp,
                        hasSendCommission: true,
                        rechargeCommissionRate: 0
                    }
                });
            } else if (input.rechargeCommission.isActivate) {
                if (input.rechargeCommission.rate === 0) {
                    SwalUtil.ok({
                        msg: '충전 커미션 %를 입력하여 주십시오.',
                        icon: 'error'
                    });
                    return;
                }
                props.onUpdateUserCommissionPermission({
                    input: {
                        userId: props.userId,
                        otp: input.otp,
                        hasSendCommission: false,
                        rechargeCommissionRate: input.rechargeCommission.rate
                    }
                });
            }
            props.onClose();
        }
    }

    return (
        <form onSubmit={ev => {
            ev.preventDefault();
            handler.onUpdate();
        }}>
            <div className={cx('table')}>
                <div style={{width: '20%'}}>전송 커미션</div>
                <div style={{width: '20%'}}>
                    <Switch
                        color='primary'
                        checked={input.sendCommission.isActivate}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.sendCommission.isActivate = ev.target.checked;
                            copy.rechargeCommission.isActivate = !ev.target.checked;
                            setInput(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>충전 커미션</div>
                <div style={{width: '20%'}}>
                    <Switch
                        color='primary'
                        checked={input.rechargeCommission.isActivate}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.rechargeCommission.isActivate = ev.target.checked;
                            copy.sendCommission.isActivate = !ev.target.checked;
                            setInput(copy);
                        }}/>
                </div>
                <div style={{width: '60%'}}>
                    <NumberFormat
                        required
                        allowNegative={false}
                        decimalScale={0}
                        suffix={' %'}
                        className='form-control form-control-sm text-center'
                        value={new Decimal(input.rechargeCommission.rate).mul(100).toNumber()}
                        onValueChange={values => {
                            const copy = CopyUtil.copyAll(input);
                            copy.rechargeCommission.rate = new Decimal(values.floatValue || 0).mul(0.01).toNumber();
                            setInput(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '20%'}}>OTP</div>
                <div style={{width: '80%'}}>
                    <input
                        required
                        className='form-control form-control-sm text-center'
                        value={input.otp}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.otp = ev.target.value;
                            setInput(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-left' style={{width: '80%', color: 'crimson'}}>
                    * 기존 커미션을 모두 지급하고 커미션 변경을 진행하여 주십시오.
                </div>
                <div className='text-right'
                     style={{width: '20%'}}>
                    <button
                        type='submit'
                        className='btn btn-sm btn-success'>적용
                    </button>
                </div>
            </div>
        </form>
    )
}

import React, {useEffect} from 'react';
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationChangeSolvedChatArgs, MutationCreateChatArgs, Query} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {Title} from "../../shares/title/Title";
import {navigate} from "hookrouter";
import {ChatDefaultList} from "./list/ChatDefaultList";
import {ChatDefaultContent} from "./content/ChatDefaultContent";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizCol12} from '../../shares/bizCol12/BizCol12';

export const ChatDefault = (props: {
    chatTitlePage: number,
    userId?: number,
}) => {
    const {data, refetch} = useQuery<Query, {
        chatTitlePage: number,
    }>(gqlData, {
        variables: {
            chatTitlePage: props.chatTitlePage
        },
        context: {
            loadingView: false,
        },
        pollInterval: 5000,
    });

    const [getChatContent, resChatContent] = useLazyQuery<Query, {
        userId: number,
        page: number,
    }>(gqlChatContent, {
        context: {
            loadingView: false,
        },
        pollInterval: 5000
    });

    const [mutCreateChat] = useMutation<Mutation, MutationCreateChatArgs>(gqlCreateChat);
    const [mutChangeSolvedChat] = useMutation<Mutation, MutationChangeSolvedChatArgs>(gqlChangeSolvedChat);

    useEffect(() => {
        if (props.userId) {
            getChatContent({
                variables: {
                    userId: props.userId,
                    page: 0
                }
            })
        }
    }, [props.userId]);

    const handler = {
        onChangeChatTitlePage: (page: number) => {
            navigate(`/chat/${page}`)
        },
        onLoadChatContent: (userId: number) => {
            navigate(`/chat/${props.chatTitlePage}/${userId}`);
        },
        onChangeChatContentPage: (page: number) => {
            if (!props.userId) {
                return;
            }

            if (resChatContent.refetch) {
                resChatContent.refetch({
                    userId: props.userId,
                    page,
                }).catch(ApolloCatch({}));
            }
        },
        onCreateChat: (input: MutationCreateChatArgs) => {
            mutCreateChat({variables: input})
                .then(() => {
                    if (props.userId && resChatContent.refetch) {
                        resChatContent.refetch({
                            userId: props.userId,
                            page: 0
                        }).catch(ApolloCatch({}));
                    }
                })
                .catch(ApolloCatch({}));
        },
        onChangeSolvedChat: (args: MutationChangeSolvedChatArgs) => {
            SwalUtil.yn({
                msg: '해결 완료 처리 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutChangeSolvedChat({
                        variables: args
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '처리되었습니다.',
                            icon: 'success'
                        });
                        getChatContent({
                            variables: {
                                userId: args.userId,
                                page: 0,
                            }
                        })
                        return refetch({
                            chatTitlePage: props.chatTitlePage
                        });
                    }).catch(ApolloCatch({}));
                }
            })
        }
    };


    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>문의 목록</Title>
                    <ChatDefaultList
                        value={data}
                        {...handler}/>

                </BizCol12>

                <BizCol12>
                    {resChatContent.data &&
                    <FadeIn>
                        <ChatDefaultContent
                            userId={props.userId!}
                            value={resChatContent.data}
                            {...handler}/>
                    </FadeIn>
                    }

                    {!resChatContent.data &&
                    <FadeIn>
                        <div style={{textAlign: "center"}}>
                            문의 목록에서 문의를 선택해주세요.
                        </div>
                    </FadeIn>
                    }
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

const gqlData = gql`
    query DATA($chatTitlePage: Int!) {
        chatList(page: $chatTitlePage, size: 20) {
            page
            size
            total
            list {
                userId
                userEmail
                notify
                isSolved
                createdAt
            }
        }
    }
`;

const gqlChatContent = gql`
    query chatContent($userId: Int!, $page: Int!) {
        chatContent(userId: $userId, page: $page, size: 20) {
            page
            size
            total
            list {
                id
                content
                writer
                userEmail
                adminEmail
                createdAt
            }
        }
        chatTitle(userId: $userId) {
            userId
            userEmail
            notify
            isSolved
            createdAt
        }
    }
`;

const gqlCreateChat = gql`
    mutation createChat($input: CreateChat!) {
        createChat(input: $input)
    }
`;

const gqlChangeSolvedChat = gql`
    mutation changeSovledChat($userId: Int!) {
        changeSolvedChat(userId: $userId)
    }
`;
